import './App.css';
import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
  // Link
} from "react-router-dom";
import Home from './components/Home';
import SummonerDetail from './components/SummonerDetail';
import Header from './components/Header';
import Ladder from './components/Ladder';
import LadderDesktop from './components/LadderDesktop';
import Contact from './components/Contact';
import Footer from './components/Footer';
// import 'bootstrap/dist/css/bootstrap.min.css';


function App() {

  const navigate = useNavigate()


  const [summonerSearchFormData, setSummonerSearchFormData] = useState({
    gameName: "",
    tagLine: "",
  })

  const [formErrors, setFormErrors] = useState([])
  const [summonerDetailError, setSummonerDetailError] = useState(false)


  const [platform, setPlatform] = useState('');
  const [region, setRegion] = useState('');
  const [displayRegion, setDisplayRegion] = useState('')
  // displayRegion is the colloquial way players refer to 'region', it is actually platform without the integer
  const [searchTrigger, setSearchTrigger] = useState(false)

  useEffect(() => { // If these values are previously saved to local storage, get & save to state
    const storedPlatform = localStorage.getItem('platform')
    const storedRegion = localStorage.getItem('region')
    const storedDisplayRegion = localStorage.getItem('displayRegion')

    if (storedPlatform && storedRegion && storedDisplayRegion) {
      setPlatform(localStorage.getItem('platform'))
      setRegion(localStorage.getItem('region'))
      setDisplayRegion(localStorage.getItem('displayRegion'))
    }
  }, [])

  function handleSummonerNameEntry(event) {
    // setSummonerSearchFormData({...summonerSearchFormData,
    // [event.target.name]: (event.target.value).trim()})
    const input = event.target.value.trim();
    const [gameName, tagLine = ''] = input.split('#'); // Split input string by the #
    setSummonerSearchFormData({
      gameName: gameName.trim(),
      tagLine: tagLine.trim(),
    })
  }

  function validateSearch() {
    let collectedErrors = []
    if (!summonerSearchFormData?.gameName && !summonerSearchFormData?.tagLine) {
      collectedErrors.push('Enter a Summoner Name and #TagLine')
      return collectedErrors
    }
    if (!summonerSearchFormData?.tagLine) {
      collectedErrors.push('Must include a TagLine (ex. "NA1")')
    }
    if (!summonerSearchFormData?.gameName) {
      collectedErrors.push('Must include a Summoner Name')
    }

    return collectedErrors
  }


  function globallyUpdateDisplayedRegion(currentPlatform) {
    const selectedPlatform = currentPlatform
    const mapPlatormToRegion = {
      'na1': 'americas',
      'br1': 'americas',
      'euw1': 'europe',
      'kr': 'asia'
    }
    const newRegion = mapPlatormToRegion[selectedPlatform]

    setPlatform(selectedPlatform)
    setRegion(newRegion)
    setDisplayRegion(selectedPlatform.replace(/[0-9]+$/, '')) // Remove trailing zeros

    localStorage.setItem('platform', selectedPlatform);
    localStorage.setItem('region', newRegion);
    localStorage.setItem('displayRegion', selectedPlatform.replace(/[0-9]+$/, ''));
  }

  function handlePlatformSelection(event) {
    const selectedPlatform = event.target.value
    globallyUpdateDisplayedRegion(selectedPlatform)
  }

  function submitAndSearchSummoner(event) {
    event.preventDefault()

    const potentialErrors = validateSearch()
    const invalid = potentialErrors.length // > 0 (which is truthy) if invalid

    if (!invalid) {
      event.target.reset()
      setFormErrors([])
      setSearchTrigger((prev) => !prev)
      navigate(`/summoners/${displayRegion}/${summonerSearchFormData.gameName}-${summonerSearchFormData.tagLine}`)
      setSummonerSearchFormData({
        gameName: "",
        tagLine: "",
      })
    } else {
      setFormErrors(potentialErrors)
    }

  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);  // Set initial state based on width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener to track window resizing
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      <Header formErrors={formErrors} setFormErrors={setFormErrors} displayRegion={displayRegion} handleSummonerNameEntry={handleSummonerNameEntry} handlePlatformSelection={handlePlatformSelection} submitAndSearchSummoner={submitAndSearchSummoner} summonerSearchFormData={summonerSearchFormData} />
      <Routes>
        <Route path="/" exact
          element={<Home formErrors={formErrors} setFormErrors={setFormErrors} handleSummonerNameEntry={handleSummonerNameEntry} handlePlatformSelection={handlePlatformSelection} submitAndSearchSummoner={submitAndSearchSummoner} platform={platform} region={region} summonerSearchFormData={summonerSearchFormData} />} />
        <Route path="/contact"
          element={<Contact />} />
        <Route path="/summoners/:displayRegion/:displayNameZipped"
          element={<SummonerDetail searchTrigger={searchTrigger} region={region} platform={platform} globallyUpdateDisplayedRegion={globallyUpdateDisplayedRegion} error={summonerDetailError} setError={setSummonerDetailError} summonerSearchFormData={summonerSearchFormData} />} />
        <Route path="/ladder/:displayRegion/:ladderType/:pageNumber"
          element={
            <>
              {isMobile ? (
                <Ladder globallyUpdateDisplayedRegion={globallyUpdateDisplayedRegion} />
              ) : (
                <LadderDesktop globallyUpdateDisplayedRegion={globallyUpdateDisplayedRegion} />
              )}
            </>
          } />
      </Routes>
      <Footer />
    </>
  )
}

export default App;


// Working on:
// -TODO: make sure new components/models have inserts in all necessary places in view function (i.e. with leaderboards also--champion mastery, preferred role, champstats).
// -NA1_5063302284 - PROBLEM: how do I back fill games? need a fetch & force update option?
// -THIS MIGHT BE FIXED new problem - if I get 429d I need to prevent external traffic not itnernal traffic
// -problem - if update fails all retrieved data is rolled back, not just whatever would break atomicity
// - update home page to match header functionality, maybe put it in it's own component