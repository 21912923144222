function formatArenaPlacement(number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}

function formatSkillOrderPositions(number) {
    number = number + 1
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}

function checkIfChampionIsKaynTransformation(championName, championTransform) {
    if (championName === "Kayn") {
        if (championTransform === 1) {
            return "Rhaast";
        } else if (championTransform === 2) {
            return "ShadowAssassin";
        }
    }
    return championName; // Return the original name if no conditions are met (which can also mean game ended with untransformed Kayn)
}

export {formatArenaPlacement, formatSkillOrderPositions, checkIfChampionIsKaynTransformation}