import React from 'react'

const LadderChampionIconWithFallback = ({ src, alt, format }) => {

    function handleError(e) {
        // e.target.src = process.env.PUBLIC_URL + `/assets/champion/missing_champion.png`
        e.target.src = process.env.PUBLIC_URL + `/assets/missing_asset/missing_profile_icon.png`
    }

    return (
        <img
            // className='rounded-full h-full w-full object-cover border border-zinc-600'
            className={format}
            src={src}
            alt={alt}
            onError={(e) => handleError(e)}
        />
    )
}


export default LadderChampionIconWithFallback