import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Loading from './Loading';
import LadderError from '../errors/LadderError';
import { RiArrowLeftDoubleFill, RiArrowRightDoubleFill } from "react-icons/ri";



const LadderDesktop = ({ globallyUpdateDisplayedRegion }) => {
    const apiEndpointPrefix = process.env.REACT_APP_API_ENDPOINT_PREFIX
    const params = useParams()
    const location = useLocation()

    const [rankedLadderDetails, setRankedLadderDetails] = useState([]);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        getLeaderboard();
        // }, [displayRegion, location.pathname]);
    }, [location.pathname]);


    // const countdownKey = 'ladderCountdownEndTime'; // Key for localStorage
    // const [countdown, setCountdown] = useState(null);

    // // Function to clear countdown data
    // const clearCountdown = () => {
    //     localStorage.removeItem(countdownKey);
    //     setCountdown(null); // Reset countdown state
    // };

    // // Function to start countdown, only invoked when API sends a 206 or 207 (which means it got a 429)
    // const startCountdown = (duration) => {
    //     const endTime = Date.now() + duration * 1000; // Calculate end time based on retry_after
    //     localStorage.setItem(countdownKey, endTime); // Store end time in local storage
    //     setCountdown(duration); // Set initial countdown state
    // };

    // // Check if there's a stored countdown when the component mounts (including page refresh)
    // useEffect(() => {
    //     const storedEndTime = localStorage.getItem(countdownKey);
    //     if (storedEndTime) {
    //         const remainingTime = Math.floor((parseInt(storedEndTime, 10) - Date.now()) / 1000);
    //         if (remainingTime > 0) {
    //             setCountdown(remainingTime); // Set the countdown if time remains
    //         } else {
    //             clearCountdown(); // Clear expired countdown
    //         }
    //     }
    // }, []);

    // // Countdown effect - if there is a countdown, tick it down in state every 1 second
    // useEffect(() => {
    //     if (countdown !== null) {
    //         const countdownInterval = setInterval(() => {
    //             setCountdown(prev => {
    //                 const newValue = prev - 1;
    //                 if (newValue <= 0) {
    //                     clearInterval(countdownInterval); // Clear interval when countdown reaches 0
    //                     clearCountdown(); // Clear local storage and reset countdown
    //                     return null; // Countdown has ended
    //                 }
    //                 return newValue;
    //             });
    //         }, 1000);

    //         return () => clearInterval(countdownInterval); // Clear interval on unmount
    //     }
    // }, [countdown]);

    async function getLeaderboard() {

        // Config to abort get request on timeout (45s), without this even if I redirect via the loading component the GET request will still process in this component & re-render
        const controller = new AbortController()
        const signal = controller.signal;
        const timeout = setTimeout(() => {
            controller.abort()
        }, 120000)

        try {
            const displayedRegion = params.displayRegion
            const mapDisplayedToRegionToVerboseRegion = {
                'na': 'americas',
                'br': 'americas',
                'lan': 'americas',
                'las': 'americas',
                'euw': 'europe',
                'eun': 'europe',
                'tr': 'europe',
                'ru': 'europe',
                // 'me': 'europe', disabled, unknown shift currently
                'kr': 'asia',
                'jp': 'asia',
                'oc': 'asia', // technically SEA but not an option for the endpoint(s) that will be hit
                'ph': 'asia', // technically SEA but not an option for the endpoint(s) that will be hit
                'sg': 'asia', // technically SEA but not an option for the endpoint(s) that will be hit
                'th': 'asia', // technically SEA but not an option for the endpoint(s) that will be hit
                'tw': 'asia', // technically SEA but not an option for the endpoint(s) that will be hit
                'vn': 'asia', // technically SEA but not an option for the endpoint(s) that will be hit
            }
            let newRegion = mapDisplayedToRegionToVerboseRegion[displayedRegion]

            const mapDisplayedRegionToPlatform = {
                'na': 'na1',
                'br': 'br1',
                'lan': 'la1',
                'las': 'la2',
                'euw': 'euw1',
                'eun': 'eun1',
                'tr': 'tr1',
                'ru': 'ru',
                'kr': 'kr',
                // 'me': 'me1', disabled, unknown shift currently
                'jp': 'jp1',
                'oc': 'oc1',
                'ph': 'ph2',
                'sg': 'sg2',
                'th': 'th2',
                'tw': 'tw2',
                'vn': 'vn2',
            }
            const newPlatform = mapDisplayedRegionToPlatform[displayedRegion]
            // let response = await axios.get(`http://127.0.0.1:8000/api/ladder/?&platform=${newPlatform}&region=${newRegion}&page=${params.pageNumber}`);  // dev (npm start command)

            // let url = `http://10.0.0.114:8000/api/ladder/?&platform=${newPlatform}&region=${newRegion}&ladderType=${params.ladderType}&page=${params.pageNumber}` // DEVELOPMENT VARIABLE - EXTERNAL TRAFFIC/MOBILE FRIENDLY npm start (dev) MOBILE/EXTERNAL
            // let url = `http://127.0.0.1:8000/api/ladder/?&platform=${newPlatform}&region=${newRegion}&page=${params.pageNumber}` // DEVELOPMENT VARIABLE - INTERNAL TRAFFIC ONLY npm start (dev) MOBILE/EXTERNAL
            // let url = `http://127.0.0.1/api/ladder/?&platform=${newPlatform}&region=${newRegion}&page=${params.pageNumber}`;  // STAGING VARIABLE - prod port :8000 is factored out, NGINX will route from default port 80 to appropriate server (local docker)
            // let url = `https://winrates.gg/api/ladder/?&platform=${newPlatform}&region=${newRegion}&page=${params.pageNumber}`;  // PRODUCTION VARIABLE - prod port :8000 is factored out, NGINX will route from default port 80 to appropriate server

            let url = apiEndpointPrefix + `/ladder/?&platform=${newPlatform}&region=${newRegion}&ladderType=${params.ladderType}&page=${params.pageNumber}`

            let response = await axios.get(url, { signal })
            clearTimeout(timeout)
            // console.log(response.data);
            // localStorage.setItem('platform', newPlatform);
            // localStorage.setItem('region', newRegion);
            // localStorage.setItem('displayRegion', displayedRegion) 
            setRankedLadderDetails(response.data)
            globallyUpdateDisplayedRegion(newPlatform)
            setLoading(false)
            setError(false)
            // if (response.status === 206 || response.status === 207) {
            //     const retryTime = response.data.retry_after; // Extract retry_after from the response
            //     startCountdown(retryTime); // Start countdown with retry_after
            // }
        } catch (error) {
            clearTimeout(timeout)
            setLoading(false)
            if (axios.isCancel(error)) {
                // console.log(error)
                setError({ "message": "Request took excessively long. Please try again momentarily", status_code: 408 })
            } else if (error.response) {
                // console.log(error.response.data);
                setError({ "message": error.response.data, status_code: error.response.status })
            } else {
                // console.log(error.message);
                setError({ "message": "Unexpected error occurred. Please try again later.", status_code: error.code || "UNKNOWN" });
            }
        }
    }

    function setLadderRegion(displayRegion) {
        setLoading(true)
        navigate(`/ladder/${displayRegion}/${params.ladderType}/1`)
    }

    function setLadderType(ladderType) {
        setLoading(true)
        navigate(`/ladder/${params.displayRegion}/${ladderType}/1`)
    }

    function setFirstPage() {
        setLoading(true)
        navigate(`/ladder/${params.displayRegion}/${params.ladderType}/1`)
    }

    function setLastPage() {
        setLoading(true)
        navigate(`/ladder/${params.displayRegion}/${params.ladderType}/25`)
    }

    function setPrevPage() {
        setLoading(true)
        navigate(`/ladder/${params.displayRegion}/${params.ladderType}/${parseInt(params.pageNumber) - 1}`)
    }

    function setNextPage() {
        setLoading(true)
        navigate(`/ladder/${params.displayRegion}/${params.ladderType}/${parseInt(params.pageNumber) + 1}`)
    }

    function setXPage(pageNumber) {
        setLoading(true)
        navigate(`/ladder/${params.displayRegion}/${params.ladderType}/${pageNumber}`)
    }


    // Calculate and display time since last update
    function formatLastUpdateTime(timestamp) {
        const currentTime = new Date();
        const targetTime = new Date(timestamp);
        if (!timestamp || (isNaN(targetTime.getTime()))) return 'Unknown.';

        const difference = currentTime - targetTime;

        // Convert milliseconds to seconds
        const seconds = Math.floor(difference / 1000);

        // Define time units in seconds
        const minute = 60;
        const hour = minute * 60;
        const day = hour * 24;
        const month = day * 30; // Approximate
        const year = day * 365; // Approximate

        if (seconds < minute) {
            return `A Moment Ago`;
        } else if (seconds < hour) {
            const minutes = Math.floor(seconds / minute);
            return `${minutes} Minute${minutes !== 1 ? 's' : ''} Ago`;
        } else if (seconds < day) {
            const hours = Math.floor(seconds / hour);
            return `${hours} Hour${hours !== 1 ? 's' : ''} Ago`;
        } else if (seconds < month) {
            const days = Math.floor(seconds / day);
            return `${days} Day${days !== 1 ? 's' : ''} Ago`;
        } else if (seconds < year) {
            const months = Math.floor(seconds / month);
            return `${months} Month${months !== 1 ? 's' : ''} Ago`;
        } else {
            const years = Math.floor(seconds / year);
            return `${years} Year${years !== 1 ? 's' : ''} Ago`;
        }
    }

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <LadderError error={error} />
    }


    return (
        <div className="my-3 max-w-screen-lg mx-auto">
            <div className='flex px-1 py-2 text-zinc-200 text-sm font-bold gap-2 w-full mx-auto'>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'na' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("na")}>NA</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'euw' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("euw")}>EUW</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'eun' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("eun")}>EUN</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'lan' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("lan")}>LAN</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'las' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("las")}>LAS</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'br' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("br")}>BR</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'kr' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("kr")}>KR</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'jp' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("jp")}>JP</button>
                {/* <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'me' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("me")}>ME</button> */}
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'oc' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("oc")}>OC</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'tr' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("tr")}>TR</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'ru' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("ru")}>RU</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'ph' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("ph")}>PH</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'sg' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("sg")}>SG</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'th' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("th")}>TH</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'tw' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("tw")}>TW</button>
                <button className={`w-full py-1 text-zinc-300 text-sm text-center font-bold ${params.displayRegion.toLowerCase() === 'vn' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderRegion("vn")}>VN</button>
            </div>
            <div className=' flex justify-around'>
                <div className='flex flex-col px-1 py-2 text-zinc-200 text-sm font-bold gap-2'>
                    <div className="flex text-zinc-200 text-sm font-bold mx-auto">
                        <div className="flex-none min-w-[5rem] rounded-tl-lg">LADDER TYPE</div>
                    </div>
                    <button className={`w-full px-2 py-1 text-zinc-300 text-sm text-center font-bold ${params.ladderType === 'solo' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderType("solo")}>SOLO/DUO</button>
                    <button className={`w-full px-2 py-1 text-zinc-300 text-sm text-center font-bold ${params.ladderType === 'flex' ? 'bg-zinc-700' : 'bg-zinc-900'} border border-zinc-600 rounded hover:bg-zinc-700`} onClick={() => setLadderType("flex")}>FLEX RANK</button>
                    <div className='italic font-light text-zinc-400 flex flex-col justify-center items-center'>
                        <div>Last Updated:</div>
                        <div>{formatLastUpdateTime(rankedLadderDetails?.updated_at)}</div>
                    </div>
                </div>
                <div className='px-1 pb-2 text-white'>
                    <div className="overflow-x-auto">
                        <div className="min-w-full flex flex-col">
                            {/* Table Headers */}
                            <div className="flex text-zinc-200 text-sm font-bold mx-auto">
                                <div className="flex-none min-w-[5rem] py-2 rounded-tl-lg pl-3">RANK</div>
                                <div className="flex-none min-w-[12rem] py-2 pl-1">SUMMONER NAME</div>
                                <div className="flex-none min-w-[8rem] py-2 -ml-5">TIER</div>
                                <div className="flex-none min-w-[10rem] py-2">CHAMPIONS</div>
                                <div className="flex-none min-w-[10rem] py-2">WINRATE</div>
                                <div className="flex-none min-w-[5rem] py-2 rounded-tr-lg">LEVEL</div>
                            </div>
                            <div className='pb-4 rounded-b-lg w-fit rounded mx-auto'>
                                {/* Table Rows */}
                                {rankedLadderDetails?.ladder && rankedLadderDetails?.ladder?.map((player, index) => {
                                    let rank = player?.metadata?.calculated_rank
                                    let wins = player?.metadata?.wins || 0;
                                    let losses = player?.metadata?.losses || 0;
                                    let winrate = Math.round((wins / (wins + losses)) * 100);
                                    let topChampions = player?.champion_mastery?.slice(0, 3);
                                    let tier = player?.metadata?.tier === "GRANDMASTER" ? "GM" : player?.metadata?.tier?.slice(0, 1)
                                    let rankedIcon = ""
                                    let rankStyle = ""
                                    if (tier === "C") {
                                        rankedIcon = "Rank=Challenger"
                                        rankStyle = "bg-amber-300 text-sky-500"
                                    } else if (tier === "GM") {
                                        rankedIcon = "Rank=Grandmaster"
                                        rankStyle = "bg-rose-900 text-yellow-500 text-xs"
                                    } else if (tier === "M") {
                                        rankStyle = "bg-purple-700 text-fuchsia-400"
                                        rankedIcon = "Rank=Master"
                                    } else if (tier === "D") {
                                        rankedIcon = "Rank=Diamond"
                                        rankStyle = "bg-indigo-500 text-sky-200"
                                    } else if (tier === "E") {
                                        rankedIcon = "Rank=Emerald"
                                        rankStyle = "bg-emerald-600 text-emerald-200"
                                    } else if (tier === "P") {
                                        rankedIcon = "Rank=Platinum"
                                        rankStyle = "bg-cyan-500 text-sky-900"
                                    } else if (tier === "G") {
                                        rankedIcon = "Rank=Gold"
                                        rankStyle = "bg-orange-300 text-yellow-800"
                                    } else if (tier === "S") {
                                        rankedIcon = "Rank=Silver"
                                        rankStyle = "bg-slate-300 text-slate-600"
                                    } else if (tier === "B") {
                                        rankedIcon = "Rank=Bronze"
                                        rankStyle = "bg-yellow-700 text-yellow-500"
                                    } else if (tier === "I") {
                                        rankedIcon = "Rank=Iron"
                                        rankStyle = "bg-neutral-500 text-stone-800"
                                    } else {
                                        rankedIcon = "Rank=Unranked"
                                        rankStyle = "bg-black text-white"
                                    }

                                    return (
                                        <div
                                            key={index}
                                            className={`flex text-zinc-200 text-sm font-semibold items-center rounded mb-1 
                                        ${rank === 1 ? 'bg-gradient-to-r from-zinc-700 via-cyan-600 to-zinc-800 outline outline-2 outline-cyan-700 mb-[6px]' : 'bg-zinc-775'}`}
                                        >
                                            <div className="flex-none min-w-[5rem] text-base p-2 px-3">{rank}.</div>
                                            <Link className="max-w-[176px] truncate flex-none min-w-[11rem] p-2 -ml-2 text-[13px]" to={`/summoners/${params.displayRegion}/${player?.gameName}-${player?.tagLine}`}>
                                                {player?.gameName + '#' + player?.tagLine}
                                            </Link>
                                            {/* <div className="flex-none min-w-[7rem] p-2 -ml-3"><span><span className={`bg-y px-1 py-[.125rem] rounded-md font-semibold ${rankStyle}`}>{tier}</span> {player?.metadata?.leaguePoints} LP</span></div> */}
                                            <div className="flex min-w-[8rem] p-2 -ml-3 items-center">
                                                <img className="mr-2" width="28" hiehgt="28" src={process.env.PUBLIC_URL + `/assets/ranked_icons/${rankedIcon}.png`} />
                                                <div>{player?.metadata?.leaguePoints} LP</div>
                                            </div>
                                            <div className="flex-none min-w-[10rem] p-2 flex">
                                                {topChampions?.map((champ, i) => (
                                                    <div key={i} className='h-8 w-8 mr-2 flex justify-center items-center rounded-full outline outline-2 outline-zinc-600 relative overflow-hidden'>
                                                        <img className="object-cover h-full w-full -my-2 transform scale-[1.15]" key={i} src={`https://d6n8t0712uu0b.cloudfront.net/champion_icons/${champ?.nameId}.png`} alt="Champion" />
                                                    </div>
                                                    // <img className="rounded-full border-2 border-zinc-600 w-7 h-7 mx-1 -my-2" key={i} src={`https://d6n8t0712uu0b.cloudfront.net/champion_icons/${champ?.nameId}.png`} alt="Champion"/>
                                                ))}
                                            </div>
                                            <div className="flex-none min-w-[10rem] p-2 flex items-center space-x-2">
                                                {/* Win-Loss Bar */}
                                                <div className="flex w-full bg-zinc-700 rounded-sm overflow-hidden">
                                                    <div
                                                        className="bg-cyan-700 text-white text-center text-sm font-semibold"
                                                        style={{ width: `${(wins / (wins + losses)) * 100}%` }}
                                                    >
                                                        {wins}W
                                                    </div>
                                                    <div
                                                        className="bg-rose-800 text-white text-center text-sm font-semibold"
                                                        style={{ width: `${(losses / (wins + losses)) * 100}%` }}
                                                    >
                                                        {losses}L
                                                    </div>
                                                </div>
                                                {/* Winrate Percentage */}
                                                <div className="text-sm font-semibold w-[69px]">{winrate}%</div>
                                            </div>
                                            <div className="flex-none min-w-[5rem] p-2">{player?.metadata?.summonerLevel}</div>
                                        </div>
                                        );
                                    })
                                }
                                {!rankedLadderDetails?.ladder && <div className='flex italic text-zinc-400 justify-center items-center h-96'>Batch job for this page has not run yet. Come back later.</div>}
                            </div>
                        </div>
                    </div>
                    <div className='mb-1 flex justify-between gap-3 max-w-[768px] mx-auto'>
                        {/* If page is 1 do not show the prev button, and only show X (say 10) numbers of pages */}
                        {/*Go to 1st page */}
                        {parseInt(params?.pageNumber) !== 1 && <button className="w-full px-2 py-1 text-zinc-300 text-sm text-center font-bold bg-zinc-900 border border-zinc-600 rounded hover:bg-zinc-700 flex justify-center items-center" onClick={() => setFirstPage()}><RiArrowLeftDoubleFill className='' color='rgb(212 212 216)' /></button>}
                        {parseInt(params?.pageNumber) !== 1 && <button className="w-full px-2 py-1 text-zinc-300 text-sm text-center font-bold bg-zinc-900 border border-zinc-600 rounded hover:bg-zinc-700" onClick={() => setPrevPage()}>{parseInt(params.pageNumber) - 1}</button>}
                        <div className="w-full px-2 py-1 text-zinc-300 text-sm text-center font-bold bg-zinc-700 border border-zinc-600 rounded">{params.pageNumber}</div>
                        {parseInt(params?.pageNumber) !== 25 && <button className="w-full px-2 py-1 text-zinc-300 text-sm text-center font-bold bg-zinc-900 border border-zinc-600 rounded hover:bg-zinc-700" onClick={() => setNextPage()}>{parseInt(params.pageNumber) + 1}</button>}
                        {(parseInt(params?.pageNumber) !== 25 && parseInt(params.pageNumber) + 2 < 25) && <button className="w-full px-2 py-1 text-zinc-300 text-sm text-center font-bold bg-zinc-900 border border-zinc-600 rounded hover:bg-zinc-700" onClick={() => setXPage(parseInt(params.pageNumber) + 2)}>{parseInt(params.pageNumber) + 2}</button>}
                        {(parseInt(params?.pageNumber) !== 25 && parseInt(params.pageNumber) + 3 < 25) && <button className="w-full px-2 py-1 text-zinc-300 text-sm text-center font-bold bg-zinc-900 border border-zinc-600 rounded hover:bg-zinc-700" onClick={() => setXPage(parseInt(params.pageNumber) + 3)}>{parseInt(params.pageNumber) + 3}</button>}
                        {parseInt(params?.pageNumber) !== 25 && <button className="w-full px-2 py-1 text-zinc-300 text-sm text-center font-bold bg-zinc-900 border border-zinc-600 rounded hover:bg-zinc-700 flex justify-center items-center" onClick={() => setLastPage()}><RiArrowRightDoubleFill color='rgb(212 212 216)' /></button>}
                        {/*^ go to last page */}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LadderDesktop;