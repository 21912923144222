import { React, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import MatchDetails from './MatchDetails'
import { Container, Row, Col } from 'react-bootstrap'
import RuneIconWithFallback from '../utilities/RuneIcon'
import SummonerSpellIconWithFallback from '../utilities/SummonerSpellIcon'
import ItemIconsWithFallBack from '../utilities/ItemIcons'
import AugmentIconWithFallback from '../utilities/AugmentIcon'
import { RiSwordFill } from "react-icons/ri";
import { BsFillShieldFill } from "react-icons/bs";
import { formatArenaPlacement } from '../helper_functions/utilities'
import ParticipantChampIconWithFallback from '../utilities/ParticipantChampIcon'
import MatchDetailsDesktop from './MatchDetailsDesktop'
import { FaFistRaised } from "react-icons/fa";
import { IoEye } from "react-icons/io5";
import { PiInfinityBold } from "react-icons/pi";
import { checkIfChampionIsKaynTransformation } from '../helper_functions/utilities'

const MatchHistoryDesktop = ({ orderedChampions, matchHistory, setSummonerData, summonerData, colorToggle}) => {

    const params = useParams()
    const displayRegion = params.displayRegion
    
    const  colorMap = {
        0: {
            "victory": "bg-gradient-to-r from-cyan-700 to-zinc-800 border border-cyan-900",
            "defeat": "bg-gradient-to-r from-rose-900 to-zinc-800 border border-rose-950"
        },
        1: { "victory": "bg-cyan-700 border border-cyan-900", "defeat": "bg-rose-900 border border-rose-950" },
        2: { "victory": "bg-zinc-775", "defeat": "bg-zinc-775" },
        3: { "victory": "bg-zinc-775 border border-cyan-900", "defeat": "bg-zinc-775 border border-rose-950" },
        4: { "victory": "bg-zinc-900 border-4 border-cyan-800", "defeat": "bg-zinc-900 border-4 border-rose-900" }
    }

    function navAndSearchParticipant() {
        setSummonerData({})
    }



    // NOTE: The purpose of this function is to move all "real"/valid items to the first 5 slots in the array as players can freely put items 0-5 in any slot in game
    function renderItemIcons(individualStats, matchRecord) {
        const items = [0, 0, 0, 0, 0, 0, 0]; // There are always items 0-5 + 6 for ward slot
        let nonZeroCount = 0;
        for (let i = 0; i <= 6; i++) {
            const key = 'item' + i; // Key in individualStats i.e. item0, item1, item2 etc.
            const value = individualStats[key] // The actual item built i.e. 9002
            if (value !== undefined && value !== 0) { //If there is a real item ID in the JSON
                if (i === 6) {
                    items[6] = value; // Set the last element in the items array to the last element in the individualStats list of items
                } else {
                    items[nonZeroCount++] = value // Replace the existing placeholder 0 with the real itemId value
                }
            }
        }
        return (
            <div className='flex gap-0.5'>
                <ItemIconsWithFallBack
                    individualStats={individualStats}
                    className='rounded-md col-start-1 row-start-1'
                    width="26"
                    height="26"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[0]}.png`}
                />
                <ItemIconsWithFallBack
                    individualStats={individualStats}
                    className='rounded-md col-start-2 row-start-1'
                    width="26"
                    height="26"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[1]}.png`}
                />
                <ItemIconsWithFallBack
                    individualStats={individualStats}
                    className='rounded-md col-start-3 row-start-1'
                    width="26"
                    height="26"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[2]}.png`}
                />
                <ItemIconsWithFallBack
                    individualStats={individualStats}
                    className='rounded-md col-start-1 row-start-2'
                    width="26"
                    height="26"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[3]}.png`}
                />
                <ItemIconsWithFallBack
                    individualStats={individualStats}
                    className='rounded-md col-start-2 row-start-2'
                    width="26"
                    height="26"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[4]}.png`}
                />
                <ItemIconsWithFallBack
                    individualStats={individualStats}
                    className='rounded-md col-start-3 row-start-2'
                    width="26"
                    height="26"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[5]}.png`}
                />
                <ItemIconsWithFallBack
                    individualStats={individualStats}
                    className="rounded-md col-start-4 row-span-2"
                    width="26"
                    height="26"
                    alt="item icon"
                    src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${items?.[6]}.png`}
                />
            </div>
        )
    }



    function renderChampionIcon(individualStats, matchRecord) {
        const championName = checkIfChampionIsKaynTransformation(individualStats?.championName, individualStats?.championTransform) 
        return (
            <div className="h-14 w-14 -mt-1 mr-0.5" style={{ position: 'relative', display: 'inline-block' }}>
                <div className={`relative w-[50px] h-[50px] rounded-full overflow-hidden border-2 ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "border-gray-700" : individualStats?.win ? "border-cyan-900" : "border-rose-950"}`}>
                    <img className="object-cover w-full h-full scale-[1.3]" alt="champion icon" src={`https://d6n8t0712uu0b.cloudfront.net/champion_icons/${championName}.png`} />
                </div>
                <div className={`rounded-full ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700" : individualStats?.win ? "bg-cyan-900" : "bg-rose-950"} h-[22px] w-[22px] flex justify-center items-center font-semibold`} style={{ position: 'absolute', bottom: '0', right: '0', fontSize: '12px' }}>
                    <div className='text-xs -ml-0.5 mt-0.5'>{individualStats?.champLevel}</div>
                </div>
            </div>
        )
    }

    function renderChampionIconParticipant(individualStats, matchRecord) {
        const championName = checkIfChampionIsKaynTransformation(individualStats?.championName, individualStats?.championTransform) 
        return (
            // <div className="h-6 w-6" style={{ position: 'relative', display: 'inline-block' }}>
            <div className="h-[20px] w-[32px] overflow-hidden rounded-full" >
                {/* <img className="rounded-full" alt="champion icon" src={`https://d6n8t0712uu0b.cloudfront.net/champion_icons/${individualStats?.championName}.png`} /> */}
                <ParticipantChampIconWithFallback alt="champion icon" src={`https://d6n8t0712uu0b.cloudfront.net/champion_icons/${championName}.png`}/>
            </div>
        )
    }
    


    function renderSummonerSpells(individualStats) {
        return (
            <div className='flex gap-0.5 mb-0.5'>
                <SummonerSpellIconWithFallback individualStats={individualStats} width="22" height="22" alt="summoner spell icon" src={`https://d6n8t0712uu0b.cloudfront.net/summoner_spell_icons/${individualStats?.summoner1Id}.png`} />
                <SummonerSpellIconWithFallback individualStats={individualStats} width="22" height="22" alt="summoner spell icon" src={`https://d6n8t0712uu0b.cloudfront.net/summoner_spell_icons/${individualStats?.summoner2Id}.png`} />
            </div>
        )
    }

    function renderRuneIcons(individualStats, matchRecord) {
        return (
            <div className='flex gap-0.5'>
                <RuneIconWithFallback matchRecord={matchRecord} individualStats={individualStats} width="22" height="22" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/${individualStats?.perks?.styles?.[0]?.selections?.[0]?.perk}.png`} />
                <RuneIconWithFallback matchRecord={matchRecord} individualStats={individualStats} width="22" height="22" format="p-[2px]" alt="secondary tree" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${individualStats?.perks?.styles?.[1]?.style}.png`} />
            </div>
        )
    }

    function renderAugmentIcons(individualStats) {

        return (
            <div>
                <div className='flex gap-0.5 mb-[2px]'>
                    <AugmentIconWithFallback width="19" height="19" alt="augment icon" src={`https://d6n8t0712uu0b.cloudfront.net/augment_icons/${individualStats?.playerAugment1}.png`} />
                    <AugmentIconWithFallback width="19" height="19" alt="augment icon" src={`https://d6n8t0712uu0b.cloudfront.net/augment_icons/${individualStats?.playerAugment2}.png`} />
                </div>
                <div className='flex gap-0.5'>
                    <AugmentIconWithFallback width="19" height="19" alt="augment icon" src={`https://d6n8t0712uu0b.cloudfront.net/augment_icons/${individualStats?.playerAugment3}.png`} />
                    <AugmentIconWithFallback width="19" height="19" alt="augment icon" src={`https://d6n8t0712uu0b.cloudfront.net/augment_icons/${individualStats?.playerAugment4}.png`} />
                </div>
            </div>
        )
    }


    function renderHighestStreak(individualStats) {
        let streakText = ""
        let streakStyle = ""

        if (individualStats?.pentaKills) {
            streakText = "PENTA KILL"
            streakStyle = "bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 text-white shadow-lg"
        } else if (individualStats?.quadraKills) {
            streakText = "QUADRA KILL"
            streakStyle = "bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white shadow-md"
        } else if (individualStats?.tripleKills) {
            streakText = "TRIPLE KILL"
            streakStyle = "bg-gradient-to-r from-yellow-300 via-yellow-400 to-green-300 text-yellow-900 shadow"
            // } else if (individualStats?.doubleKills) {
            //     streakText = "DOUBLE KILL"
            //     streakStyle = "bg-gradient-to-r from-yellow-200 to-yellow-300 text-yellow-900  shadow-sm"
        } else {
            return null
        }

        return (
            <div className={`mt-1 text-xxs font-extrabold rounded-full px-1 py-1 text-center ${streakStyle}`}>
                {streakText}
            </div>
        );
    }

    function calculateELOBadgeStylingDesktop(averageRank) {
        let rankStyle = "";
        let rankShort = "";

        if (averageRank?.includes("Challenger")) {
            rankStyle = "bg-yellow-400 text-sky-700"; // Challenger icon is typically gold with dark text
            rankShort = "C";
        } else if (averageRank?.includes("Grand")) {
            rankStyle = "bg-red-800 text-zinc-300"; // Grandmaster icon is red with yellowish text
            rankShort = "GM";
        } else if (averageRank?.includes("Master")) {
            rankStyle = "bg-purple-600 text-white"; // Master icon is purple with white text
            rankShort = "M";
        } else if (averageRank?.includes("Diamond")) {
            rankStyle = "bg-blue-600 text-white"; // Diamond icon is blue with white text
            rankShort = "D";
        } else if (averageRank?.includes("Emerald")) {
            rankStyle = "bg-green-600 text-white"; // Emerald icon is green with white text
            rankShort = "E";
        } else if (averageRank?.includes("Platinum")) {
            rankStyle = "bg-sky-500 text-white"; // Platinum icon is sky blue with white text
            rankShort = "P";
        } else if (averageRank?.includes("Gold")) {
            rankStyle = "bg-yellow-600 text-yellow-200"; // Gold icon is gold with dark brown text
            rankShort = "G";
        } else if (averageRank?.includes("Silver")) {
            rankStyle = "bg-slate-400 text-gray-800"; // Silver icon is gray with darker gray text
            rankShort = "S";
        } else if (averageRank?.includes("Bronze")) {
            rankStyle = "bg-orange-800 text-yellow-600"; // Bronze icon is orange with dark yellow text
            rankShort = "B";
        } else if (averageRank?.includes("Iron")) {
            rankStyle = "bg-gray-600 text-gray-200"; // Iron icon is dark gray with light gray text
            rankShort = "I";
        } else {
            rankStyle = "bg-neutral-400 text-white"; // Neutral fallback
            rankShort = "UR";
        }

        // Extract the number from the averageRank string (e.g., "4" from "SILVER 4")
        const rankNumber = averageRank?.split(' ')[1] || '';

        return (
            <div className={`font-light italic`}>
                - {averageRank}
            </div>
        );
    }


    function calculateCs(individualStats, matchRecord) {
        let match = matchRecord?.metadata
        let totalCs = individualStats?.neutralMinionsKilled + individualStats?.totalMinionsKilled
        let gameLengthMinutes = match?.info?.gameDuration / 60
        let csPerMin = totalCs / gameLengthMinutes

        return (
            <div className='whitespace-nowrap overflow-hidden text-ellipsis'>
                {matchRecord?.queueId !== 1700 && <div className='fw-bold whitespace-nowrap overflow-hidden text-ellipsis'>{totalCs} ({csPerMin.toFixed(1)} CSM)</div>}
                {/* <span className='fw-bold'>Gold:</span><span> {Intl.NumberFormat().format(individualStats?.goldEarned)} ({individualStats?.challenges?.goldPerMinute.toFixed(1)})</span> */}
                <div className='fw-bold'>{Intl.NumberFormat().format(individualStats?.goldEarned)} ({individualStats?.challenges?.goldPerMinute.toFixed(0)} GPM)</div>
            </div>
        )
    }

    function calculateParticipantCsAndGold(individualStats, match) {
        let totalCs = individualStats?.neutralMinionsKilled + individualStats?.totalMinionsKilled
        let gameLengthMinutes = match?.info?.gameDuration / 60
        let csPerMin = totalCs / gameLengthMinutes

        return (
            <>
                <>CS: {totalCs} ({csPerMin.toFixed(1)})</>
                <>Gold: {individualStats?.goldEarned} ({individualStats?.challenges?.goldPerMinute.toFixed(1)})</>
            </>
        )
    }

    function calculateGameTimes(matchRecord) {
        let match = matchRecord?.metadata
        let gameEndTime = match?.info?.gameEndTimestamp
        let now = Date.now()
        let unixTimeHoursAgo = (now - gameEndTime) / 1000 / 60 / 60
        let daysAgo = 0
        let minutesAgo = 0
        let hoursAgo = 0

        if (unixTimeHoursAgo < 1) {
            minutesAgo = Math.round(unixTimeHoursAgo * 60)
        } else if (unixTimeHoursAgo > 24) {
            daysAgo = Math.floor(unixTimeHoursAgo / 24)
        } else if (unixTimeHoursAgo >= 1 && unixTimeHoursAgo < 24) {
            hoursAgo = Math.round(unixTimeHoursAgo)
            minutesAgo = Math.round((unixTimeHoursAgo % 24) * 60)
        }

        let duration = new Date(match?.info?.gameDuration * 1000)
        let durationString = duration.toUTCString()
        let formattedDuration = durationString.slice(-11, -4)
        let individualDurations = formattedDuration.split(":")
        let gameHours = parseInt(individualDurations[0])
        let gameMinutes = parseInt(individualDurations[1])
        let gameSeconds = parseInt(individualDurations[2])

        return (
            <div className='text-secondary font-normal'>
                {gameHours ? <span>{gameHours}h {gameMinutes}m {gameSeconds}s</span> : <span>{gameMinutes}m {gameSeconds}s</span>}
            </div>
        )
    }

    function calculateTimeAgo(matchRecord) {
        let match = matchRecord?.metadata
        let gameEndTime = match?.info?.gameEndTimestamp
        let now = Date.now()
        let unixTimeHoursAgo = (now - gameEndTime) / 1000 / 60 / 60
        let daysAgo = 0
        let minutesAgo = 0
        let hoursAgo = 0

        if (unixTimeHoursAgo < 1) {
            minutesAgo = Math.round(unixTimeHoursAgo * 60)
        } else if (unixTimeHoursAgo > 24) {
            daysAgo = Math.floor(unixTimeHoursAgo / 24)
        } else if (unixTimeHoursAgo >= 1 && unixTimeHoursAgo < 24) {
            hoursAgo = Math.round(unixTimeHoursAgo)
            minutesAgo = Math.round((unixTimeHoursAgo % 24) * 60)
        }

        let duration = new Date(match?.info?.gameDuration * 1000)
        let durationString = duration.toUTCString()
        let formattedDuration = durationString.slice(-11, -4)
        let individualDurations = formattedDuration.split(":")
        let gameHours = parseInt(individualDurations[0])
        let gameMinutes = parseInt(individualDurations[1])
        let gameSeconds = parseInt(individualDurations[2])

        return (
            <div className='text-secondary text-white'>
                {daysAgo ? <span>{daysAgo} day(s) ago</span> : null}
                {(!daysAgo && hoursAgo) ? <span>{hoursAgo} hour(s) ago</span> : null}
                {(!daysAgo && hoursAgo < 1 && minutesAgo) ? <span>{minutesAgo} minute(s) ago</span> : null}
            </div>
        )
    }

    function renderParticipantNamesChampions(matchRecord) {
        let match = matchRecord?.metadata

        let blueSide = []
        let redSide = []
        let topFourArenaTeams = []

        if (match?.info?.queueId !== 1700) { // If NOT Arena Game Mode, split teams by red & blue
            blueSide = match?.info?.participants?.filter((participant) => {
                return parseInt(participant.teamId) === 100
            })
            redSide = match?.info?.participants?.filter((participant) => {
                return parseInt(participant.teamId) === 200
            })
        } else if (match?.info?.queueId === 1700) { // If Arena Game Mode, isolate top 4 teams
            topFourArenaTeams = match?.info?.participants?.filter((participant) => {
                return (parseInt(participant?.placement) <= 4)
            })
            topFourArenaTeams.sort((a, b) => a.placement - b.placement);
        }

        if (match?.info?.queueId !== 1700) {
            return (
                <div className='flex text-[13px] text-zinc-300 font-light'>
                    <div className='mr-2 w-24'>
                        {blueSide?.map((participant, index) => {
                            return (
                                <div key={index} className={`h-[18px] flex items-center gap-2 mb-1 rounded ${participant.puuid === summonerData.puuid ? (matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || participant?.gameEndedInEarlySurrender) ? "bg-gray-600" : participant?.win ? "bg-cyan-800xxx" : "bg-rose-925xxx" : null}`}>
                                    {renderChampionIconParticipant(participant, matchRecord)}
                                    <Link className={`block w-32 truncate underline underline-offset-1 ${participant.puuid === summonerData.puuid ? "font-bold no-underline" : null}`} to={`/summoners/${displayRegion}/${participant.riotIdGameName}-${participant.riotIdTagline}`} onClick={navAndSearchParticipant}>{participant.riotIdGameName}</Link>
                                </div>
                            )
                        })}
                    </div>
                    <div className='w-24'>
                        {redSide?.map((participant, index) => {
                            return (
                                <div key={index} className={`h-[18px] flex items-center gap-2 mb-1 rounded ${participant.puuid === summonerData.puuid ? (matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || participant?.gameEndedInEarlySurrender) ? "bg-gray-600" : participant?.win ? "bg-cyan-800xxx" : "bg-rose-925xxx" : null}`}>
                                    {renderChampionIconParticipant(participant, matchRecord)}
                                    <Link className={`block w-32 truncate underline underline-offset-1 ${participant.puuid === summonerData.puuid ? "font-bold no-underline opacity-100" : null}`} to={`/summoners/${displayRegion}/${participant.riotIdGameName}-${participant.riotIdTagline}`} onClick={navAndSearchParticipant}>{participant.riotIdGameName}</Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        } else if (match?.info?.queueId === 1700) {
            return (
                <div>
                    <h3>Top Four Teams:</h3>
                    {topFourArenaTeams.map((participant, index) => {
                        return (
                            <div key={index}>
                                <>{participant?.placement}</>
                                {renderChampionIconParticipant(participant, matchRecord)}
                                {participant.puuid === summonerData.puuid ? <Link to={`/summoners/${displayRegion}/${participant.riotIdGameName}/${participant.riotIdTagline}`} onClick={navAndSearchParticipant}>{participant.riotIdGameName + " #" + participant.riotIdTagline}<br></br></Link> :
                                    <Link to={`/summoners/${displayRegion}/${participant.riotIdGameName}/${participant.riotIdTagline}`} onClick={navAndSearchParticipant}>{participant.riotIdGameName + " #" + participant.riotIdTagline}<br></br></Link>}
                            </div>
                        )
                    })}
                </div>
            )
        }
    }


    function calculateKda(individualStats) {
        let kda = (individualStats?.challenges?.perfectGame && individualStats?.challenges?.kda != 0) ? <PiInfinityBold size={16} strokeWidth={4} /> : (individualStats?.challenges?.kda)?.toFixed(1)
        return (
            <div className='flex gap-1'>
                {/* <span className='fw-bold'>KDA:</span><span> ({kda}) {individualStats?.kills}/{individualStats?.deaths}/{individualStats?.assists} ({kp}%)</span> */}
                {kda} KDA
            </div>
        )
    }

    function calculateKp(individualStats, matchRecord) {
        let kp = String(individualStats?.challenges?.perfectGame) === "1"
            ? "100"
            : (individualStats?.challenges?.killParticipation)?.toFixed(2).substring(2);

        const kills = individualStats?.kills || 0;
        const deaths = individualStats?.deaths || 0;
        const assists = individualStats?.assists || 0;

        return (
            <div>
                {kills} / {deaths} / {assists}
                {(kills > 0 || deaths > 0 || assists > 0) && matchRecord?.queueId !== 1700 && ` (${kp}%)`}
            </div>
        );
    }


    function renderGameModeRole(matchRecord, individualStats) {
        let match = matchRecord?.metadata
        let gameType = ""
        if (parseInt(match?.info?.queueId) === 420) {
            gameType = "Solo Ranked"
        } else if (parseInt(match?.info?.queueId) === 400) {
            gameType = "Normal"
        } else if (parseInt(match?.info?.queueId) === 490) {
            gameType = "Quick Play"
        } else if (parseInt(match?.info?.queueId) === 450) {
            gameType = "ARAM"
        } else if (parseInt(match?.info?.queueId) === 1300) {
            gameType = "Nexus Blitz"
        } else if (parseInt(match?.info?.queueId) === 1700) {
            gameType = "Arena"
        } else if (parseInt(match?.info?.queueId) === 440) {
            gameType = "Flex"
        } else if (parseInt(match?.info?.queueId) === 700) {
            gameType = "Clash"
        } else if (parseInt(match?.info?.queueId) === 900) {
            gameType = "ARUF"
        } else if (parseInt(match?.info?.queueId) === 1900) {
            gameType = "Pick URF"
        } else if (parseInt(match?.info?.queueId) === 1020) {
            gameType = "One For All"
        } else if (parseInt(match?.info?.queueId) === 470) {
            gameType = "(N) Twisted Treeline"
        } else if (parseInt(match?.info?.queueId) === 720) {
            gameType = "ARAM Clash"
        } else if (parseInt(match?.info?.queueId) === 1400) {
            gameType = "Ult Spellbook"
        } else {
            gameType = "Unknown Mode"
        }

        return (
            <>
                <div className="fw-bold fst-italic">{gameType}</div>
                {/* <span className="fw-bold fst-italic">{individualStats?.teamPosition ? <>{individualStats?.teamPosition === "UTILITY" ? "SUPPORT" : individualStats?.teamPosition}</> :null }</span> */}
            </>
        )
    }


    function renderPersonalDamageChartDekstop(matchRecord, individualStats) {
        let participants = matchRecord?.metadata?.info?.participants
        let maxDamage = 0
        let maxTanked = 0
        participants?.forEach(player => {
            maxDamage = Math.max(maxDamage, player?.totalDamageDealtToChampions)
            maxTanked = Math.max(maxTanked, player?.totalDamageTaken)
        })
        let personalDamageDealt = individualStats?.totalDamageDealtToChampions
        let personalDamageTaken = individualStats?.totalDamageTaken

        let formattedDamageDealt = (personalDamageDealt / 1000).toFixed(1);
        if (formattedDamageDealt.endsWith('.0')) {
            formattedDamageDealt = formattedDamageDealt.slice(0, -2);
        }

        let formattedDamageTaken = (personalDamageTaken / 1000).toFixed(1);
        if (formattedDamageTaken.endsWith('.0')) {
            formattedDamageTaken = formattedDamageDealt.slice(0, -2);
        }

        let damageDealtPercentage = personalDamageDealt === maxDamage ? 100 : Math.round((personalDamageDealt / maxDamage) * 100)
        let damageTankedPercentage = personalDamageTaken === maxTanked ? 100 : Math.round((personalDamageTaken / maxTanked) * 100)


        return (
            <div className='text-xs flex flex-col my-1 gap-1 h-12 mb-1 w-[120px]'>
                <div className='flex-1'>
                    <div className=' flex gap-1'>
                        <div>
                            {/* <RiSwordFill className='h-full' /> */}
                            <img className='h-5 w-5 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-sword-icon.svg`} />
                        </div>
                        <div className={`w-full border ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-500 border-gray-700" : individualStats?.win ? "bg-cyan-600 border-cyan-800" : "bg-rose-800 border-rose-925"}`}>
                            <div
                                className={`h-5 ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700" : individualStats?.win ? "bg-cyan-800" : "bg-rose-925"}`}
                                style={{ width: `${damageDealtPercentage}%` }}
                            >
                                <div className='ml-1 flex items-center h-5 font-bold whitespace-nowrap'>
                                    {formattedDamageDealt + " k"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex-1'>
                    <div className=' flex gap-1'>
                        <div>
                            {/* <BsFillShieldFill className='h-full' /> */}
                            <img className='h-5 w-5 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-stat-switcher-shield.svg`} />
                        </div>
                        <div className={`w-full border ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-500 border-gray-700" : individualStats?.win ? "bg-cyan-600 border-cyan-800" : "bg-rose-800 border-rose-925"}`}>
                            <div
                                className={`h-5 ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700" : individualStats?.win ? "bg-cyan-800" : "bg-rose-925"}`}
                                // style={{ width: `${Math.round((personalDamageTaken / maxDamage) * 100)}%` }}
                                style={{ width: `${damageTankedPercentage}%` }}

                            >
                                <div className='ml-1 flex items-center h-5 font-bold whitespace-nowrap'> {/* Below is needed because Damage Taken in JSON can be undefined if 0 damage is taken, unlike Damage dealt*/}
                                    {(formattedDamageTaken ? formattedDamageTaken : 0) + " k"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function renderMatchHistory() {
        return matchHistory?.map((matchRecord, index) => {
            // let match = matchRecord?.metadata
            let individualStats = matchRecord?.metadata?.info?.participants?.filter((player) => {
                // return player.riotIdGameName?.toLowerCase() === (params.gameName).toLowerCase() && player.riotIdTagline?.toLowerCase() === params.tagLine.toLowerCase()
                return player?.puuid === summonerData?.puuid && summonerData?.puuid
            })[0]
            // Get a dump of champion name & name Ids, store them as an environment variable & use that as a dict/obj and look up the name from the nameId
            return (
                // <div key={index} className={`${matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" ? "bg-zinc-600 border-gray-800" : individualStats?.win ? "bg-cyan-700xxx bg-zinc-775xxx border border-cyan-900" : "bg-rose-900xxx bg-zinc-775xxx border border-rose-950"} mb-2 rounded-lg`}>
                <div key={index} className={`${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-600 border-gray-800" : individualStats?.win ? colorMap[colorToggle]["victory"] : colorMap[colorToggle]["defeat"]} mb-2 rounded-lg overflow-x-auto`}>
                    {/* Champion / Rune / Augment */}
                    {/* <div className='flex items-start pl-2 pt-2 pb-1 text-xs min-w-[760px]'> */}
                    <div className='flex items-start pl-2 pt-2 pb-1 text-xs'>
                        <div className='flex flex-col w-[126px] mr-4'>
                            <div className='flex'>
                                <div >
                                    {renderChampionIcon(individualStats, matchRecord)}
                                </div>
                                <div className='flex-shrink-0'>
                                    {renderSummonerSpells(individualStats)}
                                    {matchRecord?.queueId !== 1700 ? renderRuneIcons(individualStats, matchRecord) : null}
                                    {matchRecord?.queueId === 1700 ? renderAugmentIcons(individualStats, matchRecord) : null}
                                </div>
                            </div>
                            <div className='w-full'>
                                {renderHighestStreak(individualStats)}
                            </div>
                        </div>
                        <div className='w-full flex'>
                            {/* Game Results & Details*/}
                            <div className='flex w-[120px] flex-shrink-0 mr-4 truncate'>
                                <div className='flex flex-col'>
                                    <div className='flex gap-1'>
                                        <div className='font-bold text-sm -mt-0.5'>{(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender ) ? "REMAKE" : individualStats?.win ? "VICTORY" : "DEFEAT"}</div>
                                        <div>{calculateGameTimes(matchRecord)}</div>
                                    </div>
                                    <div className='flex gap-1 w-[148px] tracking-tighter'>
                                        <div className='font-semibold text-[11px] -mr-0.5'>{renderGameModeRole(matchRecord, individualStats)}</div>
                                        <div className='text-[11px]'>{calculateELOBadgeStylingDesktop(matchRecord?.metadata?.averageElo)}</div>
                                    </div>
                                    <div className='flex flex-col'>
                                        {renderPersonalDamageChartDekstop(matchRecord, individualStats)}
                                    </div>
                                </div>
                            </div>
                            <div className='flex w-full'>
                                {/* Stats & Items */}
                                <div className='flex flex-col gap-0.5 mr-4 min-w-40 text-zinc-300 font-[500]'>
                                    <div className='mb-0.5'>
                                        {renderItemIcons(individualStats, matchRecord)}
                                    </div>
                                    <div className='flex gap-1 w-[170px]'>
                                        <img className='h-4 w-4 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-kda-icon.svg`} />
                                        {calculateKp(individualStats, matchRecord)}
                                        {calculateKda(individualStats)}
                                    </div>
                                    <div className='flex gap-1 h-4'>
                                        <img className='h-4 w-4 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/minions-slain.svg`} />
                                        {calculateCs(individualStats, matchRecord)}
                                    </div>
                                    <div className='flex gap-1 h-4'>
                                        <img className='h-4 w-4 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-coins-icon.svg`}/>
                                        <div className='fw-bold'>{Intl.NumberFormat().format(individualStats?.goldEarned)} ({individualStats?.challenges?.goldPerMinute.toFixed(0)} GPM)</div>
                                    </div>
                                    <div className='flex gap-1'>
                                        <IoEye className='h-4 w-4'/>{individualStats?.visionScore}{" (VISION)"} 
                                    </div>
                                </div>
                                {/* Participants */}
                                <div className='tracking-tigh mr-1'>
                                    {renderParticipantNamesChampions(matchRecord)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <MatchDetailsDesktop key={matchRecord?.metadata?.matchId} orderedChampions={orderedChampions} summonerData={summonerData} matchRecord={matchRecord} displayRegion={displayRegion} navAndSearchParticipant={navAndSearchParticipant} calculateKda={calculateKda} calculateTimeAgo={calculateTimeAgo} individualStats={individualStats} formatArenaPlacement={formatArenaPlacement} />
                </div>
            )
        })
    }

    // {/* <div>Vision Score: {individualStats?.visionScore}</div>
    //                 <div>Control Wards Placed: {individualStats?.challenges?.controlWardsPlaced}</div>
    //                 <div>{individualStats?.wardsPlaced} / {individualStats?.wardsKilled}</div> */}
    // {/* {renderParticipantNamesChampions(matchRecord)} */ }


    return (
        <div className='rounded-lg text-white w-full'>
            {matchHistory?.length === 0 ?
                <div className='flex justify-center items-center pb-4'>
                    <div>No games played. Try Updating.</div>
                </div>
                :
                renderMatchHistory()
            }
        </div>
    )
}

export default MatchHistoryDesktop