import React from 'react'

const ParticipantChampIconDesktopWithFallback = ({ alt, src, individualStats }) => {

    function handleError(e) {
        // e.target.src = process.env.PUBLIC_URL + `/assets/profile_icons/0.png`
        e.target.src = process.env.PUBLIC_URL + `/assets/missing_asset/missing_icon.png`
    }
    return (
        <img
            className={`rounded-full h-full w-full transform scale-[1.3] object-cover }`}
            src={src}
            alt={alt}
            onError={(e) => handleError(e)}
        />
    )
}
export default ParticipantChampIconDesktopWithFallback
