import React from 'react'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import SummonerSpellIconWithFallback from '../utilities/SummonerSpellIcon';
import RuneIconWithFallback from '../utilities/RuneIcon';
import { RiSwordFill } from "react-icons/ri";
import { BsFillShieldFill } from "react-icons/bs";
import ItemIconsWithFallBack from '../utilities/ItemIcons';
import AugmentIconWithFallback from '../utilities/AugmentIcon';
import BuildAndTimeline from './BuildAndTimeline';
import ParticipantChampIconWithFallback from '../utilities/ParticipantChampIcon';
import ParticipantItemIconsDesktopWithFallBack from '../utilities/ParticipantItemIconsDesktopWithFallBack';
import ParticipantChampIconDesktopWithFallback from '../utilities/ParticipantChampionIconDesktop';
import { IoEye } from "react-icons/io5";
import { checkIfChampionIsKaynTransformation } from '../helper_functions/utilities';
import BannedChampionsDesktop from './BannedChampionsDesktop'; 

const MatchDetailsDesktop = ({ orderedChampions, individualStats, matchRecord, displayRegion, navAndSearchParticipant, calculateKda, summonerData, calculateTimeAgo, formatArenaPlacement }) => {
    // # rename to match record
    const [showDetails, setShowDetails] = useState(false)
    const [detailType, setDetailType] = useState("default")
    const params = useParams()
    const match = matchRecord?.metadata

    const highlightedPlayer = summonerData?.puuid


    function displayMatchDetails() {
        setShowDetails((previousState) => !previousState)
    }

    function renderParticipantChampionIcon(individualStats, matchRecord) {
        const championName = checkIfChampionIsKaynTransformation(individualStats?.championName, individualStats?.championTransform) 
        return (
            <div className='relative'>
                <div className={`h-[36px] w-[36px]  overflow-hidden rounded-full -mt-0.5 border ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "border-gray-700" : individualStats?.win ? "border-cyan-900" : "border-rose-950"}`} >
                    <ParticipantChampIconDesktopWithFallback individualStats={individualStats} alt="champion icon" src={`https://d6n8t0712uu0b.cloudfront.net/champion_icons/${championName}.png`} />
                </div>
                <div className={`${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700" : individualStats?.win ? "bg-cyan-900" : "bg-rose-950"} w-[20px] h-[20px] rounded-full flex items-center justify-center absolute bottom-[-6px] right-[-2px] font-semibold pt-0.5`}>
                    {individualStats?.champLevel}
                </div>
            </div>
        )
    }


    function renderSummonerSpellsDesktop(individualStats) {
        return (
            <div className='flex-col mb-0.5'>
                <div className='mb-0.5'>
                    <SummonerSpellIconWithFallback width="18" height="18" alt="summoner spell icon" src={process.env.PUBLIC_URL + `/assets/summoner_spell_icons/${individualStats?.summoner1Id}.png`} />
                </div>
                <div>
                    <SummonerSpellIconWithFallback width="18" height="18" alt="summoner spell icon" src={process.env.PUBLIC_URL + `/assets/summoner_spell_icons/${individualStats?.summoner2Id}.png`} />
                </div>
            </div>
        )
    }

    function renderAugmentIconsDesktop(individualStats) {

        return (
            <div>
                <div className='flex gap-0.5 mb-[2px]'>
                    <AugmentIconWithFallback width="16" height="16" alt="augment icon" src={`https://d6n8t0712uu0b.cloudfront.net/augment_icons/${individualStats?.playerAugment1}.png`} />
                    <AugmentIconWithFallback width="16" height="16" alt="augment icon" src={`https://d6n8t0712uu0b.cloudfront.net/augment_icons/${individualStats?.playerAugment2}.png`} />
                </div>
                <div className='flex gap-0.5'>
                    <AugmentIconWithFallback width="16" height="16" alt="augment icon" src={`https://d6n8t0712uu0b.cloudfront.net/augment_icons/${individualStats?.playerAugment3}.png`} />
                    <AugmentIconWithFallback width="16" height="16" alt="augment icon" src={`https://d6n8t0712uu0b.cloudfront.net/augment_icons/${individualStats?.playerAugment4}.png`} />
                </div>
            </div>
        )
    }

    function calculateParticipantKp(individualStats, matchRecord) {
        let kp = String(individualStats?.challenges?.perfectGame) === "1" ? "100" : (individualStats?.challenges?.killParticipation)?.toFixed(2).substring(2)
        if (individualStats?.challenges?.kda == 0) {
            kp = "0"
        }

        return (
            <div>
                {/* <span className='fw-bold'>KDA:</span><span> ({kda}) {individualStats?.kills}/{individualStats?.deaths}/{individualStats?.assists} {individualStats?.kills || individualStats?.deaths || individualStats?.assists ? `(${kp}%)` : ''}</span> */}
                <div className='whitespace-nowrap overflow-hidden text-ellipsis'>
                    {individualStats?.kills} / {individualStats?.deaths} / {individualStats?.assists}
                    {(matchRecord?.queueId !== 1700 && ` (${kp}%)`)}
                </div>
            </div>
        )
    }

    function renderParticipantItemIcons(individualStats, matchRecord) {
        const items = [0, 0, 0, 0, 0, 0, 0]; // There are always items 0-5 + 6 for ward slot
        let nonZeroCount = 0;
        for (let i = 0; i <= 6; i++) {
            const key = 'item' + i;
            const value = individualStats[key];
            if (value !== undefined && value !== 0) {
                if (i === 6) {
                    items[6] = value;
                } else {
                    items[nonZeroCount++] = value;
                }
            }
        }
        return (
            <div className='flex gap-1 h-[22px]'>
                {items.map((item, index) => (
                    <ParticipantItemIconsDesktopWithFallBack
                        key={index}
                        individualStats={individualStats}
                        matchRecord={matchRecord}
                        className={"h-full w-auto rounded"}
                        // src={process.env.PUBLIC_URL + `/assets/item_icons/${item}.png`}
                        src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${item}.png`}
                    />
                ))}
            </div>
        )
    }

    function renderParticipantHighestStreak(individualStats) {
        let streakText = ""
        let streakStyle = ""

        if (individualStats?.pentaKills) {
            streakText = "PENTA KILL"
            streakStyle = "bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 text-white shadow-lg"
        } else if (individualStats?.quadraKills) {
            streakText = "QUADRA KILL"
            streakStyle = "bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white shadow-md"
        } else if (individualStats?.tripleKills) {
            streakText = "TRIPLE KILL"
            streakStyle = "bg-gradient-to-r from-yellow-300 via-yellow-400 to-green-300 text-yellow-900 shadow"
            // } else if (individualStats?.doubleKills) {
            //     streakText = "DOUBLE KILL"
            //     streakStyle = "bg-gradient-to-r from-yellow-200 to-yellow-300 text-yellow-900  shadow-sm"
        } else {
            return null
        }

        return (
            <div className={`text-[8px] font-extrabold rounded-md px-[3px] py-0.5 text-center ${streakStyle}`}>
                {streakText}
            </div>
        );
    }

    function calculateParticipantCsAndGold(participant, match) {
        let totalCs = participant?.neutralMinionsKilled + participant?.totalMinionsKilled
        let gameLengthMinutes = match?.info?.gameDuration / 60
        let csPerMin = totalCs / gameLengthMinutes

        return (
            // <>
            //     <plaintext>CS: {totalCs} ({csPerMin.toFixed(1)})</plaintext>
            //     <plaintext>Gold: {participant?.goldEarned} ({participant?.challenges?.goldPerMinute.toFixed(1)})</plaintext>
            // </>
            <div>
                <div className='flex gap-0.5'>
                    <img className='h-4 w-4 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/minions-slain.svg`} />
                    <div>{totalCs} ({csPerMin.toFixed(1)} CSM)</div>
                </div>
                <div className='flex whitespace-nowrap overflow-hidden text-ellipsis'>
                    <img className='h-4 w-4 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-coins-icon.svg`} />
                    {`${(participant?.goldEarned / 1000).toFixed(1)}k`} ({participant?.challenges?.goldPerMinute.toFixed(1)} GPM)
                </div>
            </div>
        )
    }

    function getRankString(individualStats) {
        const tier = individualStats?.summonerElo?.tier?.toLowerCase()

        if (tier === "unranked") {
            return "Unranked"
        }

        // Capitalize the first letter of the tier
        return tier?.charAt(0).toUpperCase() + tier?.slice(1) + " " + individualStats?.summonerElo?.rank
    }

    function calculateParticipantELOBadgeStyling(badge) {
        let rankStyle = "";

        if (badge?.includes("C")) {
            rankStyle = "bg-yellow-400 text-sky-700"; // Challenger icon is typically gold with dark text
        } else if (badge?.includes("GM")) {
            rankStyle = "bg-red-800 text-zinc-300"; // Grandmaster icon is red with yellowish text
        } else if (badge?.includes("M")) {
            rankStyle = "bg-purple-600 text-white"; // Master icon is purple with white text
        } else if (badge?.includes("D")) {
            rankStyle = "bg-blue-600 text-white"; // Diamond icon is blue with white text
        } else if (badge?.includes("E")) {
            rankStyle = "bg-green-600 text-white"; // Emerald icon is green with white text
        } else if (badge?.includes("P")) {
            rankStyle = "bg-sky-500 text-white"; // Platinum icon is sky blue with white text
        } else if (badge?.includes("G")) {
            rankStyle = "bg-yellow-600 text-yellow-200"; // Gold icon is gold with dark brown text
        } else if (badge?.includes("S")) {
            rankStyle = "bg-slate-400 text-gray-800"; // Silver icon is gray with darker gray text
        } else if (badge?.includes("B")) {
            rankStyle = "bg-orange-800 text-yellow-600"; // Bronze icon is orange with dark yellow text
        } else if (badge?.includes("I")) {
            rankStyle = "bg-gray-600 text-gray-200"; // Iron icon is dark gray with light gray text
        } else {
            rankStyle = "bg-neutral-400 text-white"; // Neutral fallback
        }

        return (
            <div className={`text-xs font-semibold ${rankStyle} rounded-full px-[6px]`}>
                {badge}
            </div>
        )
    }

    function renderParticipantSummonerSpells(participant) {
        return (
            <div className='flex gap-0.5 mb-0.5'>
                <SummonerSpellIconWithFallback width="16" height="16" alt="summoner spell icon" src={`https://d6n8t0712uu0b.cloudfront.net/summoner_spell_icons/${participant?.summoner1Id}.png`} />
                <SummonerSpellIconWithFallback width="16" height="16" alt="summoner spell icon" src={`https://d6n8t0712uu0b.cloudfront.net/summoner_spell_icons/${participant?.summoner2Id}.png`} />
            </div>
        )
    }

    function renderParticipantRuneIcons(participant) {
        return (
            <div className='flex gap-0.5'>
                <RuneIconWithFallback individualStats={participant} width="16" height="16" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/${participant?.perks?.styles?.[0]?.selections?.[0]?.perk}.png`} />
                <RuneIconWithFallback individualStats={participant} width="16" height="16" format="p-[2px]" alt="secondary tree" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${participant?.perks?.styles?.[1]?.style}.png`} />
            </div>
        )
    }

    function renderParticipantDamage(matchRecord, individualStats) {
        let participants = matchRecord?.metadata?.info?.participants
        let maxDamage = 0
        let maxTanked = 0
        participants?.forEach(player => {
            maxDamage = Math.max(maxDamage, player?.totalDamageDealtToChampions)
            maxTanked = Math.max(maxTanked, player?.totalDamageTaken)
        })
        let personalDamageDealt = individualStats?.totalDamageDealtToChampions
        let personalDamageTaken = individualStats?.totalDamageTaken

        // let formattedDamageDealt = (personalDamageDealt / 1000).toFixed(1);
        // if (formattedDamageDealt.endsWith('.0')) {
        //     formattedDamageDealt = formattedDamageDealt.slice(0, -2);
        // }

        // let formattedDamageTaken = (personalDamageTaken / 1000).toFixed(1);
        // if (formattedDamageTaken.endsWith('.0')) {
        //     formattedDamageTaken = formattedDamageDealt.slice(0, -2);
        // }

        let formattedDamageDealt = (personalDamageDealt / 1000).toFixed(1) + 'k';
        let formattedDamageTaken = (personalDamageTaken / 1000).toFixed(1) + 'k';

        let damageDealtPercentage = personalDamageDealt === maxDamage ? 100 : Math.round((personalDamageDealt / maxDamage) * 100)
        let damageTankedPercentage = personalDamageTaken === maxTanked ? 100 : Math.round((personalDamageTaken / maxTanked) * 100)

        return (
            <div className='text-xs flex flex-col gap-1 mb-1  w-20'>
                <div className='flex-1'>
                    <div className=' flex gap-1'>
                        <div>
                            {/* <RiSwordFill className='h-full' /> */}
                            <img className='h-4 w-5 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-sword-icon.svg`} />
                        </div>
                        <div className={`w-full border ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-500 border-gray-700" : individualStats?.win ? "bg-cyan-600 border-cyan-900" : "bg-rose-800 border-rose-950"}`}>
                            <div
                                className={`h-4 ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700" : individualStats?.win ? "bg-cyan-900" : "bg-rose-950"}`}
                                style={{ width: `${damageDealtPercentage}%` }}
                            >
                                <div className='ml-1 flex items-center h-4'>
                                    {formattedDamageDealt}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex-1'>
                    <div className=' flex gap-1'>
                        <div>
                            {/* <BsFillShieldFill className='h-full' /> */}
                            <img className='h-4 w-5 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-stat-switcher-shield.svg`} />
                        </div>
                        <div className={`w-full border ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-500 border-gray-700" : individualStats?.win ? "bg-cyan-600 border-cyan-900" : "bg-rose-800 border-rose-950"}`}>
                            <div
                                className={`h-4 ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700" : individualStats?.win ? "bg-cyan-900" : "bg-rose-950"}`}
                                // style={{ width: `${Math.round((personalDamageTaken / maxDamage) * 100)}%` }}
                                style={{ width: `${damageTankedPercentage}%` }}

                            >
                                <div className='ml-1 flex items-center h-4'> {/* Below is needed because Damage Taken in JSON can be undefined if 0 damage is taken, unlike Damage dealt*/}
                                    {formattedDamageTaken ? formattedDamageTaken : 0}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function renderArenaTeamNames(subTeamId) {
        const teamMap = {
            1: "Poro",
            2: "Minion",
            3: "Scuttle",
            4: "Krug",
            5: "Raptor",
            6: "Sentinel",
            7: "Wolf",
            8: "Gromp"
        }

        const teamName = teamMap[subTeamId]

        return teamName
    }

    // assumed 5v5 Game Mode(DESKTOP / ORIGINAL)
    // function renderParticipantsDetail(match) {
    //     let blueSide = []
    //     let redSide = []
    //     let arenaTeams = []

    //     if (match?.info?.queueId !== 1700) {
    //         blueSide = match?.info?.participants?.filter((participant) => {
    //             return parseInt(participant.teamId) === 100
    //         })
    //         redSide = match?.info?.participants?.filter((participant) => {
    //             return parseInt(participant.teamId) === 200
    //         })
    //     } else if (match?.info?.queueId === 1700) {
    //         arenaTeams = match?.info?.participants
    //         arenaTeams.sort((a, b) => a.placement - b.placement);
    //     }

    //     // Non-Arena Format
    //     if (match?.info?.queueId !== 1700) {
    //         return (
    //             <div className='mx-2 border border-pink-400'>
    //                 <div className='flex gap-2'>
    //                     <button className={`px-2 py-1 ${showBuildPath === false ? "bg-cyan-900" : "bg-zinc-600"} whitespace-nowrap font-semibold text-xs rounded hover:bg-cyan-900`} onClick={() => setShowBuildPath(false)}>DETAILS</button>
    //                     <button className={`px-2 py-1 ${showBuildPath === true ? "bg-cyan-900" : "bg-zinc-600"} whitespace-nowrap font-semibold text-xs rounded hover:bg-cyan-900`} onClick={() => setShowBuildPath(true)}>BUILD</button>
    //                 </div>
    //                 {showBuildPath === false ?
    //                     <div>
    //                         <h3>Blue Team:</h3>
    //                         {blueSide?.map((participant, index) => {
    //                             return (
    //                                 <div key={index}>
    //                                     {renderChampionIcon(participant, "25", "25", true)}
    //                                     <Link className='linkStyle' to={`/summoners/${params.region}/${params.platform}/${participant.riotIdGameName}/${participant.riotIdTagline}`} onClick={navAndSearchParticipant} >{participant.riotIdGameName + " #" + participant.riotIdTagline}<br></br></Link>
    //                                     <>{renderParticipantItemIcons(participant)}</>
    //                                     {calculateKda(participant)}
    //                                     {calculateParticipantCsAndGold(participant, match)}
    //                                     <plaintext>Damage: {participant?.totalDamageDealtToChampions}</plaintext>
    //                                     <plaintext>Damage Taken: {participant?.totalDamageTaken}</plaintext>
    //                                     <plaintext>Control Wards Placed: {participant?.challenges?.controlWardsPlaced}</plaintext>
    //                                     <plaintext>{participant?.wardsPlaced} / {participant?.wardsKilled}</plaintext>
    //                                 </div>
    //                             )
    //                         })}
    //                         <h3>Red Team:</h3>
    //                         {redSide?.map((participant, index) => {
    //                             return (
    //                                 <div key={index}>
    //                                     {renderChampionIcon(participant, "25", "25")}
    //                                     <Link to={`/summoners/${params.region}/${params.platform}/${participant.riotIdGameName}/${participant.riotIdTagline}`} onClick={navAndSearchParticipant}>{participant.riotIdGameName + " #" + participant.riotIdTagline}<br></br></Link>
    //                                     <>{renderParticipantItemIcons(participant)}</>
    //                                     {calculateKda(participant)}
    //                                     {calculateParticipantCsAndGold(participant, match)}
    //                                     <plaintext>Damage: {participant?.totalDamageDealtToChampions}</plaintext>
    //                                     <plaintext>Damage Taken: {participant?.totalDamageTaken}</plaintext>
    //                                     <plaintext>Control Wards Placed: {participant?.challenges?.controlWardsPlaced}</plaintext>
    //                                     <plaintext>{participant?.wardsPlaced} / {participant?.wardsKilled}</plaintext>
    //                                 </div>
    //                             )
    //                         })}
    //                     </div>
    //                     :
    //                     <div>
    //                         {console.log(match?.info?.participants?.filter((player) => player?.puuid === highlightedPlayer && player?.puuid)?.[0]?.buildPath, "check this")}
    //                         {match?.info?.participants?.filter((player) => {
    //                             return (player?.puuid === highlightedPlayer && player?.puuid)
    //                         })?.[0]?.buildPath?.map((item, index) => {
    //                             return (
    //                                 <div key={index}>
    //                                     <div>{item?.itemId}</div>
    //                                     <div>meta</div>
    //                                 </div>
    //                             )
    //                         })}
    //                     </div>
    //                 }
    //             </div>
    //         )
    //         /// If Game Mode is Arena return different JSX
    //     } else if (match?.info?.queueId === 1700) {
    //         return (
    //             <div>
    //                 <h3>Arena Teams:</h3>
    //                 {arenaTeams.map((participant, index) => {
    //                     return (
    //                         <div key={index}>
    //                             <plaintext>{participant?.placement}</plaintext>
    //                             {renderChampionIcon(participant, "25", "25")}
    //                             <Link to={`/summoners/${params.region}/${params.platform}/${participant.riotIdGameName}/${participant.riotIdTagline}`} onClick={navAndSearchParticipant}>{participant.riotIdGameName + " #" + participant.riotIdTagline}<br></br></Link>
    //                             <>{renderParticipantItemIcons(participant)}</>
    //                             <>A1{participant?.playerAugment1}</>
    //                             <>A2{participant?.playerAugment1}</>
    //                             <>A3{participant?.playerAugment1}</>
    //                             <>A4{participant?.playerAugment1}</>
    //                             {calculateKda(participant)}
    //                             {calculateParticipantCsAndGold(participant, match)}
    //                             <plaintext>Damage: {participant?.totalDamageDealtToChampions}</plaintext>
    //                             <plaintext>Damage Taken: {participant?.totalDamageTaken}</plaintext>
    //                             <plaintext>Control Wards Placed: {participant?.challenges?.controlWardsPlaced}</plaintext>
    //                             <plaintext>{participant?.wardsPlaced} / {participant?.wardsKilled}</plaintext>
    //                         </div>
    //                     )
    //                 })}
    //             </div>
    //         )
    //     } else {
    //         return null
    //     }
    // }



    function renderParticipantsDetailDesktop(match) {
        let blueSide = []
        let redSide = []
        let arenaTeams = []

        if (match?.info?.queueId !== 1700) {
            blueSide = match?.info?.participants?.filter((participant) => {
                return parseInt(participant.teamId) === 100
            })
            redSide = match?.info?.participants?.filter((participant) => {
                return parseInt(participant.teamId) === 200
            })
        } else if (match?.info?.queueId === 1700) {
            arenaTeams = match?.info?.participants
            arenaTeams.sort((a, b) => a.placement - b.placement);
        }

        let blueSideKills = match?.info?.teams?.[0]?.objectives?.champion?.kills
        let blueSideDeaths = 0
        let blueSideAssists = 0
        let blueSideTurretKills = match?.info?.teams?.[0]?.objectives?.tower?.kills
        let blueSideBaronKills = match?.info?.teams?.[0]?.objectives?.baron?.kills
        let blueSideDragonKills = match?.info?.teams?.[0]?.objectives?.dragon?.kills
        let blueSideVoidGrubKills = match?.info?.teams?.[0]?.objectives?.horde?.kills
        let blueSideRiftHeraldKills = match?.info?.teams?.[0]?.objectives?.riftHerald?.kills
        let blueSideInhibitorKills = match?.info?.teams?.[0]?.objectives?.inhibitor?.kills

        blueSide?.forEach((player) => {
            blueSideDeaths += player?.deaths
            blueSideAssists += player?.assists
        })

        let redSideKills = match?.info?.teams?.[1]?.objectives?.champion?.kills
        let redSideDeaths = 0
        let redSideAssists = 0
        let redSideTurretKills = match?.info?.teams?.[1]?.objectives?.tower?.kills
        let redSideBaronKills = match?.info?.teams?.[1]?.objectives?.baron?.kills
        let redSideDragonKills = match?.info?.teams?.[1]?.objectives?.dragon?.kills
        let redSideVoidGrubKills = match?.info?.teams?.[1]?.objectives?.horde?.kills
        let redSideRiftHeraldKills = match?.info?.teams?.[1]?.objectives?.riftHerald?.kills
        let redSideInhibitorKills = match?.info?.teams?.[1]?.objectives?.inhibitor?.kills
        redSide?.forEach((player) => {
            redSideDeaths += player?.deaths
            redSideAssists += player?.assists
        })


        // Non-Arena Format
        if (match?.info?.queueId !== 1700) {
            return (
                <div className='mx-2 min-w-[600px]'>
                    <div className={`flex gap-1 mb-2 mt-3`}>
                        <button className={`px-2 py-1 ${detailType === "default" ? ((match.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700 border border-gray-700" : individualStats?.win ? "bg-cyan-950" : "bg-rose-950") : (match.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "border border-gray-700" : individualStats?.win ? "border border-cyan-975" : "border border-rose-950"} whitespace-nowrap font-semibold text-xs rounded ${((match.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "hover:bg-gray-700" : individualStats?.win ? "hover:bg-cyan-950" : "hover:bg-rose-950")}`} onClick={() => setDetailType("default")}>DETAILS</button>
                        <button className={`px-2 py-1 ${detailType === "build" ? ((match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700 border border-gray-700" : individualStats?.win ? "bg-cyan-950" : "bg-rose-950") : (match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "border border-gray-700" : individualStats?.win ? "border border-cyan-975" : "border border-rose-950"} whitespace-nowrap font-semibold text-xs rounded ${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "hover:bg-gray-700" : individualStats?.win ? "hover:bg-cyan-950" : "hover:bg-rose-950"}`} onClick={() => setDetailType("build")}>BUILD</button>
                        <button className={`px-2 py-1 ${detailType === "bans" ? ((match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700 border border-gray-700" : individualStats?.win ? "bg-cyan-950" : "bg-rose-950") : (match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "border border-gray-700" : individualStats?.win ? "border border-cyan-975" : "border border-rose-950"} whitespace-nowrap font-semibold text-xs rounded ${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "hover:bg-gray-700" : individualStats?.win ? "hover:bg-cyan-950" : "hover:bg-rose-950"}`} onClick={() => setDetailType("bans")}>BANS</button>
                    </div>
                    {detailType === "default" && (
                        <div className='mt-1'>
                            {/* <h3>Blue Team:</h3> */}
                            {/* Header */}
                            <div className={`border-t border-l border-r ${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-800 border-gray-900" : blueSide?.[0]?.win ? "bg-cyan-950 bg-cyan-800xxx border-cyan-975" : "bg-rose-975xxx bg-rose-950 border-rose-975"} flex p-1 rounded-t-lg font-semibold text-sm`}>
                            {/* <div className={`border-t border-l border-r ${match?.info?.endOfGameResult === "Abort_Unexpected" ? "bg-gradient-to-r from-gray-950 to gray-800 border-gray-950" : true ? "bg-cyan-950 border-zinc-950" : "bg-rose-975xxx bg-rose-950 border-rose-975"} flex p-1 rounded-t-lg font-semibold text-sm`}> */}
                                <div>{(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender ) ? "REMAKE" : blueSide?.[0]?.win ? "VICTORY" : "DEFEAT"}</div>
                                <div className='w-full flex justify-around font-normal'>
                                    <div className='flex items-center gap-0.5'>
                                        <img className='h-4 w-4 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-kda-icon.svg`} />
                                        <div>{blueSideKills} / {blueSideDeaths} / {blueSideAssists}</div>
                                    </div>
                                    <div className='flex justify-center items-center gap-0.5'>
                                        <div>{blueSideTurretKills}</div>
                                        <img className="brightness-0 invert h-4 w-4" alt="tower icon" src={process.env.PUBLIC_URL + "/assets/minimap_asset_icons/icon_ui_tower_minimap.png"} />
                                    </div>
                                    <div className='flex justify-center items-center gap-0.5'>
                                        <div>{blueSideDragonKills}</div>
                                        <img className="brightness-0 invert h-4 w-4" alt="dragon icon" src={process.env.PUBLIC_URL + "/assets/minimap_asset_icons/dragon_minimap_icon.png"} />
                                    </div>
                                    <div className='flex justify-center items-center gap-0.5'>
                                        <div>{blueSideVoidGrubKills}</div>
                                        <img className="brightness-0 invert h-4 w-4" alt="dragon icon" src={process.env.PUBLIC_URL + "/assets/minimap_asset_icons/horde.png"} />
                                    </div>
                                    <div className='flex justify-center items-center gap-0.5'>
                                        <div>{blueSideRiftHeraldKills}</div>
                                        <img className="brightness-0 invert h-4 w-4" alt="dragon icon" src={process.env.PUBLIC_URL + "/assets/minimap_asset_icons/sru_riftherald_minimap_icon.png"} />
                                    </div>
                                    <div className='flex justify-center items-center gap-0.5'>
                                        <div>{blueSideBaronKills}</div>
                                        <img className="brightness-0 invert h-4 w-4" alt="dragon icon" src={process.env.PUBLIC_URL + "/assets/minimap_asset_icons/baron_minimap_icon.png"} />
                                    </div>
                                </div>
                            </div>
                            {blueSide?.map((participant, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`pt-2 ${(match?.info?.endOfGameResult === "Abort_Unexpected" || participant?.gameEndedInEarlySurrender) ? "bg-gray-600 border-l border-r border-t border-gray-900" : blueSide?.[0]?.win
                                            ? (summonerData?.gameName?.toLowerCase() === participant?.riotIdGameName?.toLowerCase() ? "border-l border-r border-t border-cyan-975 bg-cyan-700" : "bg-cyan-800 border-t border-l border-r border-cyan-975")
                                            : (summonerData?.gameName?.toLowerCase() === participant?.riotIdGameName?.toLowerCase() ? "border-l border-r border-t border-rose-975 bg-rose-900" : "bg-rose-925 border-t border-l border-r border-rose-975")
                                            }`}
                                    >
                                        <div className='mx-1 flex justify-between text-xs'>
                                            <div className='flex gap-1'>
                                                <div className=''>
                                                    {renderParticipantChampionIcon(participant, matchRecord)}
                                                </div>
                                                <div>
                                                    {renderParticipantSummonerSpells(participant)}
                                                    {renderParticipantRuneIcons(participant, matchRecord)}
                                                </div>
                                                <div className='flex flex-col w-20'>
                                                    <Link className='truncate font-semibold' to={`/summoners/${displayRegion}/${participant.riotIdGameName}-${participant.riotIdTagline}`} onClick={navAndSearchParticipant} >{participant.riotIdGameName + "#" + participant.riotIdTagline}</Link>
                                                    <div className="italic">{getRankString(participant)}</div>
                                                </div>
                                            </div>
                                            <div className='flex flex-col w-[55px] truncate font-semibold'>
                                                <div>Lvl. {participant?.summonerLevel}</div>
                                                <div>{renderParticipantHighestStreak(participant)}</div>
                                            </div>
                                            <div>
                                                <div className='flex flex-col w-[190px]truncate'>
                                                    <div className='mb-0.5'>
                                                        {renderParticipantItemIcons(participant, matchRecord)}
                                                    </div>
                                                    <div className='flex items-center gap-1'>
                                                        <img className='h-3 w-3 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-kda-icon.svg`} />
                                                        {calculateParticipantKp(participant, matchRecord)}
                                                        {calculateKda(participant, matchRecord)}
                                                        <IoEye className='h-3 w-3' />{participant?.visionScore} 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-[120px] truncate'>
                                                {calculateParticipantCsAndGold(participant, match)}
                                            </div>
                                            <div>
                                                {renderParticipantDamage(matchRecord, participant)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {/* <h3>Red Team:</h3> */}
                            {/* Header */}
                            <div className={`border-t border-l border-r ${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-800 border-gray-900" : redSide?.[0]?.win ? "bg-cyan-975xxx bg-cyan-900 border-cyan-975" : "bg-rose-975xxx bg-rose-950 border-rose-975"} flex p-1 font-semibold text-sm`}>
                                <div>{(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "REMAKE" : redSide?.[0]?.win ? "VICTORY" : "DEFEAT"}</div>
                                <div className='w-full flex justify-around font-normal'>
                                    <div className='flex items-center gap-0.5'>
                                        <img className='h-4 w-4 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-kda-icon.svg`} />
                                        <div>{redSideKills} / {redSideDeaths} / {redSideAssists}</div>
                                    </div>
                                    <div className='flex justify-center items-center gap-0.5'>
                                        <div>{redSideTurretKills}</div>
                                        <img className="brightness-0 invert h-4 w-4" alt="tower icon" src={process.env.PUBLIC_URL + "/assets/minimap_asset_icons/icon_ui_tower_minimap.png"} />
                                    </div>
                                    <div className='flex justify-center items-center gap-0.5'>
                                        <div>{redSideDragonKills}</div>
                                        <img className="brightness-0 invert h-4 w-4" alt="dragon icon" src={process.env.PUBLIC_URL + "/assets/minimap_asset_icons/dragon_minimap_icon.png"} />
                                    </div>
                                    <div className='flex justify-center items-center gap-0.5'>
                                        <div>{redSideVoidGrubKills}</div>
                                        <img className="brightness-0 invert h-4 w-4" alt="dragon icon" src={process.env.PUBLIC_URL + "/assets/minimap_asset_icons/horde.png"} />
                                    </div>
                                    <div className='flex justify-center items-center gap-0.5'>
                                        <div>{redSideRiftHeraldKills}</div>
                                        <img className="brightness-0 invert h-4 w-4" alt="dragon icon" src={process.env.PUBLIC_URL + "/assets/minimap_asset_icons/sru_riftherald_minimap_icon.png"} />
                                    </div>
                                    <div className='flex justify-center items-center gap-0.5'>
                                        <div>{redSideBaronKills}</div>
                                        <img className="brightness-0 invert h-4 w-4" alt="dragon icon" src={process.env.PUBLIC_URL + "/assets/minimap_asset_icons/baron_minimap_icon.png"} />
                                    </div>
                                </div>
                            </div>
                            {/* <div className={`px-1 bg-zinc-700 border-l border-r ${match?.info?.endOfGameResult === "Abort_Unexpected" ? "border-gray-950" : redSide?.[0]?.win ? "border-cyan-975" : "border-rose-975"}`}>
                                <div>Summoner</div>
                            </div> */}
                            {redSide?.map((participant, index) => {
                                return (
                                    // <div key={index} className={`pt-1 ${index % 2 === 0 ? ' bg-cyan-775' : 'bg-cyan-900'}`}>
                                    // <div key={index} className={`pt-1 ${blueSide?.[0]?.win ? "bg-cyan-900" : "bg-rose-900"} ${((summonerData?.gameName)?.toLowerCase() === participant?.riotIdGameName && "text-pink-500")} ${index == 0 && "rounded-t-lg "}`}>
                                    <div
                                        key={index}
                                        className={`pt-2 ${(match?.info?.endOfGameResult === "Abort_Unexpected" || participant?.gameEndedInEarlySurrender) ? "bg-gray-600 border-l border-r border-t border-gray-900" : redSide?.[0]?.win
                                            ? (summonerData?.gameName?.toLowerCase() === participant?.riotIdGameName?.toLowerCase() ? "border-l border-r border-t border-cyan-975 bg-cyan-700" : "bg-cyan-800 border-t border-l border-r border-cyan-975")
                                            : (summonerData?.gameName?.toLowerCase() === participant?.riotIdGameName?.toLowerCase() ? "border-l border-r border-t border-rose-975 bg-rose-900" : "bg-rose-925 border-t border-l border-r border-rose-975")
                                            } ${index == 4 && "rounded-b-lg pb-1 border-b"}`}
                                    >
                                        <div className='mx-1 flex justify-between text-xs'>
                                            <div className='flex gap-1'>
                                                <div className=''>
                                                    {renderParticipantChampionIcon(participant, matchRecord)}
                                                </div>
                                                <div>
                                                    {renderParticipantSummonerSpells(participant)}
                                                    {renderParticipantRuneIcons(participant)}
                                                </div>
                                                <div className='flex flex-col w-20'>
                                                    <Link className='truncate font-semibold' to={`/summoners/${displayRegion}/${participant.riotIdGameName}-${participant.riotIdTagline}`} onClick={navAndSearchParticipant} >{participant.riotIdGameName + "#" + participant.riotIdTagline}</Link>
                                                    <div className="italic">{getRankString(participant)}</div>
                                                </div>
                                            </div>
                                            <div className='flex w-[55px] truncate font-semibold'>
                                                <div>Lvl. {participant?.summonerLevel}</div>
                                            </div>
                                            <div>
                                                <div className='flex flex-col w-[190px]truncate'>
                                                    <div className='mb-0.5'>
                                                        {renderParticipantItemIcons(participant, matchRecord)}
                                                    </div>
                                                    <div className='flex items-center gap-1'>
                                                        <img className='h-3 w-3 filter brightness-0 invert' src={process.env.PUBLIC_URL + `/assets/scoreboard_icons/scoreboard-kda-icon.svg`} />
                                                        {calculateParticipantKp(participant, matchRecord)}
                                                        {calculateKda(participant, matchRecord)}
                                                        <IoEye className='h-3 w-3' />{participant?.visionScore}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-[120px] truncate'>
                                                {calculateParticipantCsAndGold(participant, match)}
                                            </div>
                                            <div>
                                                {renderParticipantDamage(matchRecord, participant)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        )
                    }
                    {detailType === "build" && (
                        <BuildAndTimeline highlightedPlayer={highlightedPlayer} individualStats={individualStats} match={matchRecord?.metadata} />
                        )
                    }
                    {detailType === "bans" && (
                        <BannedChampionsDesktop orderedChampions={orderedChampions} match={matchRecord?.metadata} individualStats={individualStats} />
                    )
                    }
                    <div className='h-2'></div>
                </div>
            )
            /// If Game Mode is Arena return different JSX
        } else if (match?.info?.queueId === 1700) {
            return (
                <div className='mx-2'>
                    {/* <h3>Arena Teams:</h3> Grouped by pairs in order of placemet*/}
                    {arenaTeams.map((participant, index) => {
                        return (
                            // <div key={index}>
                            //     <plaintext>{participant?.placement}</plaintext>
                            //     {renderChampionIcon(participant, "25", "25")}
                            //     <Link to={`/summoners/${params.region}/${params.platform}/${participant.riotIdGameName}/${participant.riotIdTagline}`} onClick={navAndSearchParticipant}>{participant.riotIdGameName + " #" + participant.riotIdTagline}<br></br></Link>
                            //     <>{renderParticipantItemIcons(participant)}</>
                            //     <>A1{participant?.playerAugment1}</>
                            //     <>A2{participant?.playerAugment1}</>
                            //     <>A3{participant?.playerAugment1}</>
                            //     <>A4{participant?.playerAugment1}</>
                            //     {calculateKda(participant)}
                            //     {calculateParticipantCsAndGold(participant, match)}
                            //     <plaintext>Damage: {participant?.totalDamageDealtToChampions}</plaintext>
                            //     <plaintext>Damage Taken: {participant?.totalDamageTaken}</plaintext>
                            //     <plaintext>Control Wards Placed: {participant?.challenges?.controlWardsPlaced}</plaintext>
                            //     <plaintext>{participant?.wardsPlaced} / {participant?.wardsKilled}</plaintext>
                            // </div>
                            <div
                                key={index}
                                className={`${participant?.placement <= 4
                                    ? (summonerData?.gameName?.toLowerCase() === participant?.riotIdGameName?.toLowerCase() ? "bg-cyan-950 rounded-lg outline outline-[2px] outline-cyan-950" : "bg-cyan-800 border-l border-r border-t border-cyan-975")
                                    : (summonerData?.gameName?.toLowerCase() === participant?.riotIdGameName?.toLowerCase() ? "bg-rose-950 rounded-lg outline outline-[2px] outline-rose-950" : "bg-rose-900 border-l border-r border-t border-rose-975")
                                    } ${index == 0 && "rounded-t-lg"} ${index == 15 && "rounded-b-lg border-b"}`}
                            >
                                {index % 2 === 0 && (
                                    <div className={`flex gap-3 items-center text-sm font-semibold px-2 ${participant?.placement <= 4 ? "bg-cyan-975" : "bg-rose-975"} ${index == 0 && "rounded-t-lg"} italic text-zinc-400 py-0.5`}>
                                        <div>{participant?.placement !== 0 && formatArenaPlacement(participant?.placement)}</div>
                                        <div className='text-xs'>Team {renderArenaTeamNames(participant?.playerSubteamId)}</div>
                                        <img alt="arena team icon" height="16" width="16" src={process.env.PUBLIC_URL + `/assets/arena_team_icons/Team${renderArenaTeamNames(participant?.playerSubteamId)}.png`} />
                                    </div>
                                )}
                                <div className='mx-1 flex justify-between mb-1 pt-1'>
                                    <div className='flex items-center gap-1 text-sm whitespace-nowrap overflow-hidden'>
                                        <div>{calculateParticipantELOBadgeStyling(participant?.summonerElo?.badge)}</div>
                                        <Link className='font-bold whitespace-nowrap overflow-hidden text-ellipsis' to={`/summoners/${displayRegion}/${participant.riotIdGameName}-${participant.riotIdTagline}`} onClick={navAndSearchParticipant} >{participant.riotIdGameName + " #" + participant.riotIdTagline}</Link>
                                    </div>
                                    <div className='flex items-center min-w-[145px]'>{renderParticipantItemIcons(participant)}</div>
                                </div>
                                <div className='mx-1 flex justify-between text-xs pb-1'>
                                    <div className='flex'>
                                        <div className='mr-0.5'>
                                            {renderParticipantChampionIcon(participant, matchRecord)}
                                        </div>
                                        <div className='flex'>
                                            <div className='mr-0.5'>{renderSummonerSpellsDesktop(participant)}</div>
                                            {renderAugmentIconsDesktop(participant)}
                                        </div>
                                    </div>
                                    <div className=''>
                                        {calculateKda(participant)}
                                        {calculateParticipantKp(participant, matchRecord)}
                                    </div>
                                    <div>
                                        {calculateParticipantCsAndGold(participant, match)}
                                    </div>
                                    <div>
                                        {renderParticipantDamage(matchRecord, participant)}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className='h-2'></div>
                </div>
            )
        } else {
            return null
        }
    }


    return (
        <div className=''>
            <div className={`border-t ${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "border-gray-900" : individualStats?.win ? ("border-cyan-900 " + (!showDetails && "bg-cyan-700")) : ("border-rose-950 " + (!showDetails && "bg-rose-900")) } py-1 px-2 flex justify-between rounded-b-md text-xxs text-zinc-300`}>
                <div className={`flex gap-2 ${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender ) ? "text-gray-900" : individualStats?.win ? "text-cyan-900" : "text-rose-950"}`}>
                    <div>
                        {calculateTimeAgo(matchRecord)}
                    </div>
                    <div className=''>
                        {matchRecord?.matchId}
                    </div>
                </div>
                <div className='flex gap-2 h-[15px] whitespace-nowrap overflow-visible pr-1 text-zinc-200 font-normal'>
                    {showDetails ? (
                        <button onClick={displayMatchDetails} className="flex items-center space-x-1">
                            <span>HIDE DETAILS</span>
                            <SlArrowUp className='w-[17px] h-[15px]' />
                        </button>
                    ) : (
                        <button onClick={displayMatchDetails} className="flex items-center space-x-1">
                            <span className=''>SHOW DETAILS</span>
                            <SlArrowDown className='w-[17px] h-[15px]' />
                        </button>
                    )}
                </div>
            </div>
            {showDetails ? renderParticipantsDetailDesktop(match) : null}
        </div>
    )
}

export default MatchDetailsDesktop

