import React from 'react'
import { FaMagnifyingGlass } from 'react-icons'
import ChampionsMasteredIconWithFallBack from '../utilities/ChampionMasteryIcon'

const ChampionMastery = ({ championMastery }) => {



    return (
        <div className="bg-zinc-775 text-zinc-400 text-xxs text-center rounded-lg px-0 py-4">
            <h2 className="mb-3 italic font-bold text-xs">CHAMPION MASTERY</h2>
            <div className='flex mt-2 w-full justify-around'>
                {championMastery?.length === 0 && <div className='text-sm font-normal'>No Informaiton. Try Updating.</div>}
                {championMastery?.map((mastery, index) => {
                    return (
                        <div key={index} className='flex flex-col items-center flex-1 text-white'>
                            <div className='rounded-lg mb-1 relative'>
                                {/* <img className='rounded-lg' width="40" height="40" src={process.env.PUBLIC_URL + `/assets/champion/${mastery?.nameId}.png`} /> */}
                                {/* <img className='rounded-lg' width="40" height="40" src={`https://d6n8t0712uu0b.cloudfront.net/champion_icons/${mastery?.nameId}.png`} /> */}
                                <div className='h-10 w-10 rounded-full border border-zinc-600 relative overflow-hidden'>
                                    <ChampionsMasteredIconWithFallBack src={`https://d6n8t0712uu0b.cloudfront.net/champion_icons/${mastery?.nameId}.png`} alt="mastered champion" />
                                </div>
                                <div className="bg-zinc-775 text-[11px] font-bold px-1 rounded-md border border-zinc-600 absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
                                    {mastery?.level}
                                </div>
                            </div>
                            <img width="40" height="40" src={process.env.PUBLIC_URL + `/assets/mastery_icons/masterycrest_level_${mastery?.level < 4 ? "0" : (mastery?.level >= 10 ? "10" : mastery?.level)}_art_mini.png`} />
                            <div className='font-bold truncate w-12'>{mastery?.name}</div>
                            <div className='text-xs tracking-tight'>{Intl.NumberFormat().format(mastery?.points)}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ChampionMastery