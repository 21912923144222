import React from 'react'

const RuneIconWithFallback = ({ src, alt, width, height, format, individualStats, matchRecord }) => {

    function handleError(e) {
        // e.target.src = process.env.PUBLIC_URL + `/assets/profile_icons/0.png`
        e.target.src = process.env.PUBLIC_URL + `/assets/missing_asset/missing_icon.png`
    }

    return (
        <img
            className={`rounded-md ${format} ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700" : individualStats?.win ? "bg-cyan-900" : "bg-rose-950"}`}
            width={width}
            height={height}
            src={src}
            alt={alt}
            onError={(e) => handleError(e)}
        />
    )
}

export default RuneIconWithFallback