import React, { useState } from "react";
import { NavLink, useParams, Link, useNavigate, useLocation, useMatch } from "react-router-dom";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { Navigate } from "react-router-dom";
import { TbBeta } from "react-icons/tb";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { ImCross } from "react-icons/im";


const Header = ({ formErrors, setFormErrors, displayRegion, handleSummonerNameEntry, handlePlatformSelection, submitAndSearchSummoner, summonerSearchFormData }) => {
    const hideSearchBar = useLocation().pathname === "/" ? true : false

    return (
        <div className="bg-zinc-950 py-4 px-4 border-b border-zinc-800">
            {/* <div className="flex justify-between max-w-screen-xl mx-auto"> */}
            <div className="flex justify-between max-w-screen-lg mx-auto">
                <NavLink to="/">
                    <div className="flex flex-col my-0 leading-none">
                        <span className="text-cyan-600 font-[1000] left-italic">WR</span>
                        <div className="whitespace-nowrap text-white font-[1000]">
                            .GG<TbBeta className="inline text-xs" />
                        </div>
                    </div>
                </NavLink>
                <div className="flex gap-2 items-center text-sm">
                    <NavLink className="text-white no-underline font-semibold" to="/">
                        HOME
                    </NavLink>
                    <NavLink
                        className="text-white no-underline font-semibold"
                        to={`/ladder/${displayRegion || "na"}/solo/1`}
                    >
                        LEADERBOARDS
                    </NavLink>
                    <NavLink
                        className="text-white no-underline font-semibold"
                        to={`/contact`}
                    >
                        CONTACT
                    </NavLink>
                </div>
            </div>
            {hideSearchBar ? null : (
                // <div className="mt-3 max-w-screen-xl mx-auto">
                <div className="mt-3 max-w-screen-lg mx-auto">

                    <form onSubmit={(e) => submitAndSearchSummoner(e)}>
                        <div className="flex flex-nowrap h-8 w-full overflow-x-auto bg-zinc-900 rounded-md border border-zinc-600 focus-within:outline-none focus-within:border-none focus-within:ring-1 focus-within:ring-cyan-600 hover:border-cyan-600">
                            <label htmlFor="platform"></label>
                            {localStorage.getItem("platform") &&
                                localStorage.getItem("region") &&
                                localStorage.getItem("displayRegion") ? (
                                <div className="flex items-center">
                                    <select
                                        className="appearance-none h-full bg-zinc-900 rounded-l-md text-zinc-400 font-normal text-center shrink select-centered"
                                        value={localStorage.getItem("platform")}
                                        onChange={(e) => handlePlatformSelection(e)}
                                        name="platform"
                                    >
                                        <option value={null} disabled>
                                            Region
                                        </option>
                                        <option value="na1">NA</option>
                                        <option value="euw1">EUW</option>
                                        <option value="br1">BR</option>
                                        <option value="eun1">EUNE</option>
                                        <option value="kr">KR</option>
                                        <option value="oc1">OCE</option>
                                        <option value="la1">LAN</option>
                                        <option value="la2">LAS</option>
                                        <option value="ru">RU</option>
                                        <option value="tr1">TR</option>
                                        {/* <option value="me1">ME</option> */}
                                        <option value="jp1">JP</option>
                                        <option value="ph2">PH</option>
                                        <option value="sg2">SG</option>
                                        <option value="th2">TH</option>
                                        <option value="tw2">TW</option>
                                        <option value="vn2">VN</option>
                                    </select>
                                    <div className="text-zinc-400">
                                        <RiArrowUpSLine className="-mb-2" />
                                        <RiArrowDownSLine />
                                    </div>
                                </div>
                            ) : (
                                <div className="flex items-center">

                                    <select
                                        className="appearance-none h-full bg-zinc-900 rounded-l-md text-zinc-400 font-normal text-center shrink select-centered"
                                        onChange={(e) => handlePlatformSelection(e)}
                                        name="platform"
                                    >
                                        <option className="text-xs" value={null}>&nbsp;Select Region&nbsp;</option>
                                        <option value="na1">NA</option>
                                        <option value="euw1">EUW</option>
                                        <option value="br1">BR</option>
                                        <option value="eun1">EUNE</option>
                                        <option value="kr">KR</option>
                                        <option value="oc1">OCE</option>
                                        <option value="la1">LAN</option>
                                        <option value="la2">LAS</option>
                                        <option value="ru">RU</option>
                                        <option value="tr1">TR</option>
                                        {/* <option value="me1">ME</option> */}
                                        <option value="jp1">JP</option>
                                        <option value="ph2">PH</option>
                                        <option value="sg2">SG</option>
                                        <option value="th2">TH</option>
                                        <option value="tw2">TW</option>
                                        <option value="vn2">VN</option>
                                    </select>
                                    <div className="text-zinc-400">
                                        <RiArrowUpSLine className="-mb-2" />
                                        <RiArrowDownSLine />
                                    </div>
                                </div>
                            )}
                            <label htmlFor="gameName"></label>
                            <div className="relative flex items-center">
                                <input
                                    autoComplete='off'
                                    autoCorrect='off'
                                    spellCheck='false'
                                    className="appearance-none text-zinc-400 bg-zinc-900 ml-1 placeholder:text-zinc-400  text-sm basis-20 h-full grow-[5] shrink focus-within:outline-none pl-2"
                                    onChange={handleSummonerNameEntry}
                                    onInput={handleSummonerNameEntry}
                                    placeholder="Summoner Name +"
                                    name="gameName"
                                    type="text"
                                ></input>
                                {(summonerSearchFormData?.gameName == "" && summonerSearchFormData?.tagLine == "" ) && <span className="absolute left-[138px] text-zinc-400 bg-zinc-800 text-xs px-1 py-0.5 border border-zinc-600 rounded-md">
                                    #TagLine
                                </span>}
                            </div>
                            <div className="flex justify-end w-full">
                                <button className="flex items-center h-full" type="submit">
                                    <FaMagnifyingGlass className="text-zinc-400 h-4 w-4 mx-2" />
                                </button>
                            </div>
                        </div>
                    </form>
                    {formErrors?.length > 0 &&
                        <div className='mt-3 bg-rose-200 flex items-center px-3 gap-2 border border-rose-600 text-rose-900 rounded-md p-1'>
                            <div onClick={() => setFormErrors([])}><ImCross /></div>
                            {formErrors?.map((error) => {
                                return <div>{error}</div>
                            })}
                        </div>
                    }
                </div>
            )}
        </div>
    )
}

export default Header;





// # May also be happening after searching for someone that doesn't exist then searching for someone valid


// # APPEARS THAT SUMMONER NAME IS SOMETIMES MISSING FROM SEARCH ? PROBABLY BECAUSE IF WE USE THE SAME NAME DIFF TAGLINE
// # STATE MAY NOT CHANGE FOR THE NAME ???? IDK INVESTIGATE
// # REMOVE FROM CLIENT AND MIDDLEWARE

// # APPEARS THAT SUMMONER NAME IS SOMETIMES MISSING FROM SEARCH ? PROBABLY BECAUSE IF WE USE THE SAME NAME DIFF TAGLINE
// # STATE MAY NOT CHANGE FOR THE NAME ???? IDK INVESTIGATE
// # REMOVE FROM CLIENT AND MIDDLEWARE

// # APPEARS THAT SUMMONER NAME IS SOMETIMES MISSING FROM SEARCH ? PROBABLY BECAUSE IF WE USE THE SAME NAME DIFF TAGLINE
// # STATE MAY NOT CHANGE FOR THE NAME ???? IDK INVESTIGATE
// # REMOVE FROM CLIENT AND MIDDLEWARE



// BUG IDENTIFIED: click on a player name and load their page, then click on your own name from the drop down options & hit search
// state will not update if I just select from the browser's suggested dropdown, examine how to fix this
// other bug to fix is the gap in match history (but this will likely be fixed by just using a larger number in the view function for the match history cut off)
// implement crawler