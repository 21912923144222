import { React, useEffect, useState } from 'react'
import { FaAngleRight } from "react-icons/fa";
import { TreeIconWithFallback, RuneIconWithFallback } from '../utilities/BuildRunePageIcons';
import ChampionAbilityIconWithFallBack from '../utilities/ChampionAbilityIcons';
import axios from 'axios'
import { formatSkillOrderPositions } from '../helper_functions/utilities';
import BuildItemIconsWithFallBack from '../utilities/BuildItemIcons';

const BuildAndTimeline = ({ highlightedPlayer, individualStats, match }) => {

    const [primaryRow1, setPrimaryRow1] = useState([]);
    const [primaryRow2, setPrimaryRow2] = useState([]);
    const [primaryRow3, setPrimaryRow3] = useState([]);
    const [primaryRow4, setPrimaryRow4] = useState([]);
    const [secondaryRow2, setSecondaryRow2] = useState([]);
    const [secondaryRow3, setSecondaryRow3] = useState([]);
    const [secondaryRow4, setSecondaryRow4] = useState([]);
    const [statModRow1, setStatModRow1] = useState([])
    const [statModRow2, setStatModRow2] = useState([])
    const [statModRow3, setStatModRow3] = useState([])


    useEffect(() => {
        async function getRuneRows() {
            // try {
            const primaryStyle = individualStats?.perks?.styles?.[0]?.style;
            const secondaryStyle = individualStats?.perks?.styles?.[1]?.style;

            // Fetch file names for primary runes
            const primaryRow1Names = await axios.get(`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${primaryStyle}/slot_1/metadata.json`);
            const primaryRow2Names = await axios.get(`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${primaryStyle}/slot_2/metadata.json`);
            const primaryRow3Names = await axios.get(`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${primaryStyle}/slot_3/metadata.json`);
            const primaryRow4Names = await axios.get(`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${primaryStyle}/slot_4/metadata.json`);

            // Fetch file names for secondary runes (no Row 1 for secondary, Row 1 are keystones)
            const secondaryRow2Names = await axios.get(`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${secondaryStyle}/slot_2/metadata.json`);
            const secondaryRow3Names = await axios.get(`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${secondaryStyle}/slot_3/metadata.json`);
            const secondaryRow4Names = await axios.get(`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${secondaryStyle}/slot_4/metadata.json`);

            // Fetch file names for stat mods
            const statModRow1Names = await axios.get(`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/stat_mods/slot_1/metadata.json`);
            const statModRow2Names = await axios.get(`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/stat_mods/slot_2/metadata.json`);
            const statModRow3Names = await axios.get(`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/stat_mods/slot_3/metadata.json`);


            // Update each state variable individually
            setPrimaryRow1(primaryRow1Names.data);
            setPrimaryRow2(primaryRow2Names.data);
            setPrimaryRow3(primaryRow3Names.data);
            setPrimaryRow4(primaryRow4Names.data);
            setSecondaryRow2(secondaryRow2Names.data);
            setSecondaryRow3(secondaryRow3Names.data);
            setSecondaryRow4(secondaryRow4Names.data);
            setStatModRow1(statModRow1Names.data);
            setStatModRow2(statModRow2Names.data);
            setStatModRow3(statModRow3Names.data);

            // } catch (error){
            //     console.error('Error fetching rune rows:', error);

            // }

        }
        getRuneRows();
    }, [])

    const personalBuildPath = individualStats?.buildPath

    const timecutOffs = [
        60000,  // 1 minute
        360000, // 6 minutes
        720000, // 12 minutes
        960000, // 16 minutes
        1200000, // 20 minutes
        1500000, // 25 minutes
        1800000, // 30 minutes
        2100000, // 35 minutes
        2400000, // 40 minutes
        2700000,  // 45 minutes
        3000000, // 50 minutes
        9999999, // rest of game all in milliseconds
    ];

    // Temporary structure to collect items
    const tempGroups = {};

    // Initialize tempGroups with empty arrays for each cutoff
    timecutOffs.forEach(cutoff => {
        tempGroups[cutoff] = [];
    });

    // Loop through each object in the array
    personalBuildPath?.forEach(item => {
        // Find the appropriate cutoff for the current timestamp
        for (let i = 0; i < timecutOffs.length; i++) {
            if (item.timestamp <= timecutOffs[i]) {
                tempGroups[timecutOffs[i]].push(item);
                break; // Once added to a group, break out of the loop
            }
        }
    });

    // Create groupedBuild by filtering out empty arrays
    const groupedBuild = Object.values(tempGroups).filter(group => group.length > 0);

    function roundMillisecondsToNearestMinute(milliseconds) {
        const millisecondsPerMinute = 60000; // 60,000 milliseconds in a minute

        // Calculate the number of minutes and round up
        const roundedMinutes = Math.ceil(milliseconds / millisecondsPerMinute);

        // Format the result as "N min"
        return `${roundedMinutes} min`;
    }

    return ( // INSTEAD OF THIS, MAYBE JUST INCLUDE THE FILE NAMES AS METADATA ON AWS FOR EACH TREE THEN REQUEST THAT AND RENDER BASED ON THAT
        <div>
            <div className={`rounded-md mt-1 p-2 pt-3 flex flex-wrap justify-evenly ${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-900" : individualStats?.win ? "bg-cyan-950" : "bg-rose-950"}`}>
                <div className='flex w-full justify-around mb-4'>
                    <div>
                        <div className='flex flex-col items-center justify-center mb-2'>
                            <div className='text-zinc-300 italic font-bold text-xs'>Primary</div>
                            {/* <TreeIconWithFallback width="22" height="22" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${individualStats?.perks?.styles?.[0]?.style}.png`} /> */}
                        </div>
                        <div className='flex flex-col items-center justify-center'>
                            <div className='flex gap-1 mb-1'>
                                {primaryRow1?.map((name, index) => {
                                    // console.log("xxx", name?.replace(".png","") == individualStats?.perks?.styles?.[0]?.selections?.[0]?.perk)
                                    return <RuneIconWithFallback key={name+toString(index)} format={`${parseInt(name?.replace(".png", "")) === parseInt(individualStats?.perks?.styles?.[0]?.selections?.[0]?.perk) ? "" : "grayscale"}`} width="22" height="22" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${individualStats?.perks?.styles?.[0]?.style}/slot_1/${name}`} />
                                })}
                            </div>
                            <div className='flex gap-1 mb-1'>
                                {primaryRow2?.map((name, index) => {
                                    return <RuneIconWithFallback key={name + toString(index)} format={`${parseInt(name?.replace(".png", "")) === parseInt(individualStats?.perks?.styles?.[0]?.selections?.[1]?.perk) ? "" : "grayscale"}`} width="22" height="22" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${individualStats?.perks?.styles?.[0]?.style}/slot_2/${name}`} />
                                })}
                            </div>
                            <div className='flex  gap-1 mb-1'>
                                {primaryRow3?.map((name, index) => {
                                    return <RuneIconWithFallback key={name + toString(index)} format={`${parseInt(name?.replace(".png", "")) === parseInt(individualStats?.perks?.styles?.[0]?.selections?.[2]?.perk) ? "" : "grayscale"}`} width="22" height="22" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${individualStats?.perks?.styles?.[0]?.style}/slot_3/${name}`} />
                                })}
                            </div>
                            <div className='flex  gap-1'>
                                {primaryRow4?.map((name, index) => {
                                    return <RuneIconWithFallback key={name + toString(index)} format={`${parseInt(name?.replace(".png", "")) === parseInt(individualStats?.perks?.styles?.[0]?.selections?.[3]?.perk) ? "" : "grayscale"}`} width="22" height="22" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${individualStats?.perks?.styles?.[0]?.style}/slot_4/${name}`} />
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={`w-[1px] ${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-800" : individualStats?.win ? "bg-cyan-900" : "bg-rose-925"} my-2 rounded-full`}></div>
                    <div>
                        <div className='flex flex-col items-center justify-center mb-2'>
                            <div className='text-zinc-300 italic font-bold text-xs'>Secondary</div>
                            {/* <TreeIconWithFallback width="22" height="22" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${individualStats?.perks?.styles?.[1]?.style}.png`} /> */}
                        </div>
                        <div className='flex flex-col items-center justify-center'>
                            <div className='flex gap-1 mb-1'>
                                {secondaryRow2?.map((name, index) => {
                                    return <RuneIconWithFallback
                                        key={name + toString(index)} format={`${(parseInt(name?.replace(".png", "")) === parseInt(individualStats?.perks?.styles?.[1]?.selections?.[0]?.perk) || parseInt(name?.replace(".png", "")) === parseInt(individualStats?.perks?.styles?.[1]?.selections?.[1]?.perk)) ? "" : "grayscale"}`}
                                        width="22" height="22" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${individualStats?.perks?.styles?.[1]?.style}/slot_2/${name}`}
                                    />
                                })}
                            </div>
                            <div className='flex gap-1 mb-1'>
                                {secondaryRow3?.map((name, index) => {
                                    return <RuneIconWithFallback
                                        key={name + toString(index)} format={`${(parseInt(name?.replace(".png", "")) === parseInt(individualStats?.perks?.styles?.[1]?.selections?.[0]?.perk) || parseInt(name?.replace(".png", "")) === parseInt(individualStats?.perks?.styles?.[1]?.selections?.[1]?.perk)) ? "" : "grayscale"}`}
                                        width="22" height="22" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${individualStats?.perks?.styles?.[1]?.style}/slot_3/${name}`}
                                    />
                                })}
                            </div>
                            <div className='flex gap-1'>
                                {secondaryRow4?.map((name, index) => {
                                    return <RuneIconWithFallback
                                        key={name + toString(index)} format={`${(parseInt(name?.replace(".png", "")) === parseInt(individualStats?.perks?.styles?.[1]?.selections?.[0]?.perk) || parseInt(name?.replace(".png", "")) === parseInt(individualStats?.perks?.styles?.[1]?.selections?.[1]?.perk)) ? "" : "grayscale"}`}
                                        width="22" height="22" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/trees/${individualStats?.perks?.styles?.[1]?.style}/slot_4/${name}`}
                                    />
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={`w-[1px] ${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-800" : match?.info?.endOfGameResult === "Abort_Unexpected" ? "bg-gray-800" : individualStats?.win ? "bg-cyan-900" : "bg-rose-925"} my-2 rounded-full`}></div>
                    <div>
                        <div className='flex flex-col items-center justify-center'>
                            <div className='flex flex-col items-center justify-center mb-2'>
                                <div className='text-zinc-300 italic font-bold text-xs'>Stat Mods</div>
                            </div>
                            <div className='flex gap-1 mb-1'>
                                {statModRow1?.map((name) => {
                                    return <RuneIconWithFallback
                                        format={`${parseInt(name?.replace(".png", "")) === parseInt(individualStats?.perks?.statPerks?.offense) ? "" : "grayscale"}`}
                                        width="22" height="22" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/stat_mods/slot_1/${name}`}
                                    />
                                })}
                            </div>
                            <div className='flex gap-1 mb-1'>
                                {statModRow2?.map((name) => {
                                    return <RuneIconWithFallback
                                        format={`${parseInt(name?.replace(".png", "")) === parseInt(individualStats?.perks?.statPerks?.flex) ? "" : "grayscale"}`}
                                        width="22" height="22" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/stat_mods/slot_2/${name}`}
                                    />
                                })}
                            </div>
                            <div className='flex gap-1'>
                                {statModRow3?.map((name) => {
                                    return <RuneIconWithFallback
                                        format={`${parseInt(name?.replace(".png", "")) === parseInt(individualStats?.perks?.statPerks?.defense) ? "" : "grayscale"}`}
                                        width="22" height="22" alt="major rune icon" src={`https://d6n8t0712uu0b.cloudfront.net/major_and_minor_rune_icons/stat_mods/slot_3/${name}`}
                                    />
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-800" : individualStats?.win ? "bg-cyan-900" : "bg-rose-925"} w-full h-[1px] mb-2 mx-2 rounded-full`}></div>
                <div className='w-full flex justify-center mb-2'>
                    <div className='text-zinc-300 italic font-bold text-sm'>Item Build Path</div>
                </div>
                {groupedBuild?.map((group, index) => {
                    // Use a conditional to determine if the group has any items
                    return (
                        <div key={index} className='mb-2'>
                            <div className='flex'>
                                <div className='bg-zinc-800 flex justify-center gap-1 px-1 py-1 rounded-lg'>
                                    {group.map((item, itemIndex) => (
                                        <div key={itemIndex} className='flex'>
                                            <BuildItemIconsWithFallBack className='flex rounded-md' height="22" width="22" src={`https://d6n8t0712uu0b.cloudfront.net/item_icons/${item?.itemId}.png`} />
                                        </div>
                                    ))}
                                </div>
                                <div className='flex items-center ml-2'>
                                    {index !== (groupedBuild.length - 1) && <FaAngleRight />}
                                </div>
                            </div>
                            <div className='flex font-semibold text-zinc-300 italic justify-center text-xs'>{roundMillisecondsToNearestMinute(group?.slice(-1)?.[0]?.timestamp)}</div>
                        </div>
                    );
                })}
                <div className='w-full mt-1'>
                    <div className={`${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-800" : individualStats?.win ? "bg-cyan-900" : "bg-rose-925"} w-full h-[1px] mb-2 mx-2 rounded-full`}></div>
                    <div className='flex justify-center'>
                        <div className='text-zinc-300 italic font-bold text-sm mb-3'>Skill Order</div>
                    </div>
                    <div className='flex justify-around mx-4 mb-2'>
                        {individualStats?.skillOrder?.slice(0, 4).map((ability, index) => {
                            return (
                                <div key={index}>
                                    <ChampionAbilityIconWithFallBack width="40" height="40" alt="champion ability icon" src={`https://d6n8t0712uu0b.cloudfront.net/champion_spell_icons/${individualStats?.championId}/${ability?.skillSlot}.png`} />
                                    <div className='flex justify-center text-sm text-zinc-300 italic font-semibold'>
                                        {formatSkillOrderPositions(index)}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='bg-zinc-800 text-zinc-300 italic text-xs font-bold rounded-t-lg p-1'>Skill / Level</div>
                    <div className={`grid grid-rows-4 gap-0.5 text-xs ${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-700" : individualStats?.win ? "bg-cyan-900" : "bg-rose-925"} p-1 rounded-b-lg`}>
                        {/* Row 1: Q skills */}
                        <div className="grid grid-cols-[auto_repeat(18,_1fr)] gap-0.5">
                            <div className="relative">
                                <ChampionAbilityIconWithFallBack format={`rounded-none border ${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "border-gray-900" : individualStats?.win ? "border-cyan-950" : "border-rose-950"}`} width="20" height="20" alt="champion ability icon" src={`https://d6n8t0712uu0b.cloudfront.net/champion_spell_icons/${individualStats?.championId}/q.png`} />
                                <div className="absolute bottom-0 right-0 bg-zinc-800 text-white text-xs px-0.5">
                                    Q
                                </div>
                            </div>
                            {Array.from({ length: 18 }).map((_, index) => {
                                const skill = individualStats?.skillOrder?.[index];
                                return (
                                    <div key={index} className={`flex justify-center items-center font-semibold border ${match?.info?.endOfGameResult === "Abort_Unexpected" ? "border-gray-900" : individualStats?.win ? "border-cyan-950" : "border-rose-950"} h-full ${skill?.skillSlot === 'q' ? "bg-zinc-800" : null}`}>
                                        {skill?.skillSlot === 'q' ? index + 1 : ""}
                                    </div>
                                );
                            })}
                        </div>

                        {/* Row 2: W skills */}
                        <div className="grid grid-cols-[auto_repeat(18,_1fr)] gap-0.5">
                            <div className="relative">
                                <ChampionAbilityIconWithFallBack format={`rounded-none border ${match?.info?.endOfGameResult === "Abort_Unexpected" ? "border-gray-900" : individualStats?.win ? "border-cyan-950" : "border-rose-950"}`} width="20" height="20" alt="champion ability icon" src={`https://d6n8t0712uu0b.cloudfront.net/champion_spell_icons/${individualStats?.championId}/w.png`} />
                                <div className="absolute bottom-0 right-0 bg-zinc-800 text-white text-xs px-0.5">
                                    W
                                </div>
                            </div>
                            {Array.from({ length: 18 }).map((_, index) => {
                                const skill = individualStats?.skillOrder?.[index];
                                return (
                                    <div key={index} className={`flex justify-center items-center font-semibold border ${match?.info?.endOfGameResult === "Abort_Unexpected" ? "border-gray-900" : individualStats?.win ? "border-cyan-950" : "border-rose-950"} h-full ${skill?.skillSlot === 'w' ? "bg-zinc-800" : null}`}>
                                        {skill?.skillSlot === 'w' ? index + 1 : ""}
                                    </div>
                                );
                            })}
                        </div>

                        {/* Row 3: E skills */}
                        <div className="grid grid-cols-[auto_repeat(18,_1fr)] gap-0.5">
                            <div className="relative">
                                <ChampionAbilityIconWithFallBack format={`rounded-none border ${match?.info?.endOfGameResult === "Abort_Unexpected" ? "border-gray-900" : individualStats?.win ? "border-cyan-950" : "border-rose-950"}`} width="20" height="20" alt="champion ability icon" src={`https://d6n8t0712uu0b.cloudfront.net/champion_spell_icons/${individualStats?.championId}/e.png`} />
                                <div className="absolute bottom-0 right-0 bg-zinc-800 text-white text-xs px-0.5">
                                    E
                                </div>
                            </div>
                            {Array.from({ length: 18 }).map((_, index) => {
                                const skill = individualStats?.skillOrder?.[index];
                                return (
                                    <div key={index} className={`flex justify-center items-center font-semibold border ${match?.info?.endOfGameResult === "Abort_Unexpected" ? "border-gray-900" : individualStats?.win ? "border-cyan-950" : "border-rose-950"} h-full ${skill?.skillSlot === 'e' ? "bg-zinc-800" : null}`}>
                                        {skill?.skillSlot === 'e' ? index + 1 : ""}
                                    </div>
                                );
                            })}
                        </div>

                        {/* Row 4: R skills */}
                        <div className="grid grid-cols-[auto_repeat(18,_1fr)] gap-0.5">
                            {/* <div className="w-5 h-5 border border-rose-950">R</div> */}
                            <div className="relative">
                                <ChampionAbilityIconWithFallBack format={`rounded-none border ${match?.info?.endOfGameResult === "Abort_Unexpected" ? "border-gray-900" : individualStats?.win ? "border-cyan-950" : "border-rose-950"}`} width="20" height="20" alt="champion ability icon" src={`https://d6n8t0712uu0b.cloudfront.net/champion_spell_icons/${individualStats?.championId}/r.png`} />
                                <div className="absolute bottom-0 right-0 bg-zinc-800 text-white text-xs px-0.5">
                                    R
                                </div>
                            </div>
                            {Array.from({ length: 18 }).map((_, index) => {
                                const skill = individualStats?.skillOrder?.[index];
                                return (
                                    <div key={index} className={`flex justify-center items-center font-semibold border ${match?.info?.endOfGameResult === "Abort_Unexpected" ? "border-gray-900" : individualStats?.win ? "border-cyan-950" : "border-rose-950"} h-full ${skill?.skillSlot === 'r' ? "bg-zinc-800" : null}`}>
                                        {skill?.skillSlot === 'r' ? index + 1 : ""}
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BuildAndTimeline