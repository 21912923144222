import React from 'react'
import { useParams } from 'react-router-dom';
import { VscError } from "react-icons/vsc";


const SummonerDetailError = ({ error, countdown }) => {
    const params = useParams()

    const displayName = params.displayNameZipped
    const [gameName, tagLine] = displayName.split("-")

    const errorCode = String(error?.status_code);
    let errorDetails = null;

    if (errorCode === "404") {
        errorDetails = (
            <div>Summoner "{gameName}#{tagLine}" does not exist in Riot databases.</div>
            //{/* <div>{error?.status?.message}</div> */}
        )
    } else if (errorCode[0] === "5") {
        errorDetails = (
            <div>There was an issue seaching for your summoner. Please try again later.</div>
        )
    } else if (errorCode === "408") { // Server config-based time out (too long), not a rate limited error
        errorDetails = (
            <div>Your request timed out (took unexpectedly long). Please try again momentarily.</div>
        )
    } else if (errorCode === "429") { // 429 Rate limited received
        errorDetails = (
            <div>{error?.message?.status?.message}</div>
        )
    } else if (errorCode === "428") { // 428 Summoner profile did not exist and WR.GG  is currently rate limited by riot so no requests send
        errorDetails = (
            <div>Summoner profile did not exist yet and Riot is blocking traffic currently.</div>
        )
    } else {
        errorDetails = (
            <div>Unexpected error. Please try again later.</div>
        )
    }

    return (
        <div className='w-[80%] mx-auto text-white mb-[80%]'>
            <div className='flex justify-center items-center'>
                <VscError className='text-rose-800 h-20 w-20 mt-[20%]' />
            </div>
            <div className='font-bold text-4xl text-rose-800 flex justify-center items-center mt-[5%]'>
                Error: {errorCode}
            </div>
            <div className='font-semibold flex-col text-center justify-center items-center mt-[10%]'>
                {errorDetails}
                {countdown && <div>Retry in {countdown}s...</div>}
            </div>
        </div>

    )
}


export default SummonerDetailError