import React from "react";
import {Slab, BlinkBlur, OrbitProgress} from "react-loading-indicators"

const Loading = () => {
	return (
		<div className="flex justify-center items-center my-5 mx-5 h-[80vh]">
			{/* <Slab color={["#ffffff",  "#0891b2"]} size="large" text="FETCHING DATA..." textColor="" /> */}
			{/* <BlinkBlur color={["#0891b2"]} size="large" text="FETCHING DATA..." textColor="" /> */}
			<OrbitProgress dense variant="disc" color={["#0891b2"]} size="large" text="" textColor="" />
		</div>
	);
};

export default Loading;


// import React from "react";
// import ReactLoadng from "react-loading";

// const Loading = () => {
// 	return (
// 		<div className="flex justify-center items-center my-5 mx-5 h-[80vh]">
// 			<ReactLoadng
// 				type={"spin"}
// 				color={"#149cac"}
// 				height={"35%"}
// 				width={"23%"}
// 			/>
// 		</div>
// 	);
// };

// export default Loading;

