import React from 'react'

const BuildItemIconsWithFallBack = ({ className, alt, src, width, height, individualStats, matchRecord }) => {


    function handleError(e) {
        // e.target.src = process.env.PUBLIC_URL + `/assets/profile_icons/0.png`
        e.target.src = process.env.PUBLIC_URL + `/assets/missing_asset/missing_icon.png`
    }
    return (
        <img
            className={className}
            width={width}
            height={height}
            src={src}
            alt={alt}
            onError={(e) => handleError(e)}
        />
    )
}

export default BuildItemIconsWithFallBack