import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();
    const [message, setMessage] = useState({ text: '', type: '' });

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            process.env.REACT_APP_EJS_SERVICE_ID,
            process.env.REACT_APP_EJS_TEMPLATE_ID,
            form.current,{
                publicKey: process.env.REACT_APP_EJS_PUBLIC_KEY
            }
        ).then(
            () => {
                setMessage({ text: 'Thank you. Your message was sent successfully!', type: 'success' });
                form.current.reset()
            },
            (error) => {
                setMessage({ text: 'Failed to send message. Please try again later.', type: 'error', details: error });
            }
        );
    };

    const closePopup = () => {
        setMessage({ text: '', type: '' });
    };

    return (
        <div className='text-zinc-400 relativ max-w-screen-lg mx-auto'>
            {/* On fail or on success pop up -- note EmailJS form has built in validations*/}
            {message.text && (
                <div
                    className={`absolute top-1/3 left-1/2 transform -translate-x-1/2 p-4 z-50 text-center rounded-md 
                    ${message.type === 'success' ? 'border-2 border-cyan-700 bg-cyan-200 text-cyan-800' : 'border-2 border-rose-900 bg-rose-200 text-rose-800'}`}
                >
                    <div className="flex justify-between items-center">
                        <span>{message.text}</span>
                        <button onClick={closePopup} className="ml-4 text-lg font-bold focus:outline-none">
                            &times;
                        </button>
                    </div>
                </div>
            )}

            <div className={'mt-3 mx-3 rounded-lg bg-zinc-xxx'}>
                <form className="py-14" ref={form} onSubmit={sendEmail}>
                    <div className='flex justify-center items-center text-sm px-6 w-full'>
                        <div className='text-center'>
                            WINRATES.GG is currently in an open beta.
                            <br />
                            Have a suggestion, noticed a bug, a joke to tell? Send a message. If reporting a bug please try to be detailed.
                        </div>
                    </div>
                    <div className='flex justify-center items-center mt-2'>
                        <label>Email:</label>
                    </div>
                    <div className='flex justify-center items-center mt-2'>
                        <input
                            type="email"
                            name="user_email"
                            className="bg-zinc-900 rounded-sm border border-zinc-600 focus-within:outline-none focus-within:border-none focus-within:ring-1 focus-within:ring-cyan-600 hover:border-cyan-600"
                        />
                    </div>
                    <div className='flex justify-center items-center mt-2'>
                        <label>Message:</label>
                    </div>
                    <div className='flex justify-center items-center mt-2'>
                        <textarea
                            name="message"
                            className='bg-zinc-900 rounded-sm border border-zinc-600 focus-within:outline-none focus-within:border-none focus-within:ring-1 focus-within:ring-cyan-600 hover:border-cyan-600 w-3/4'
                        />
                    </div>
                    <div className='flex justify-center items-center mt-4'>
                        <div className='bg-zinc-900 border border-zinc-600 whitespace-nowrap font-bold text-sm rounded hover:bg-zinc-700 py-1 px-2'>
                            <input type="submit" value="Send" />
                        </div>
                    </div>
                </form>
            </div>

            <div className='mx-3 rounded-lg bg-zinc-xxx mb-3 pt-3'>
                <div className='flex flex-col justify-center items-center text-sm mb-3'>
                    <div>WR.GG is a one man show.</div>
                    <div>Appreciate the work?</div>
                    <div>Consider leaving a tip (via PayPal)</div>
                    <div>to help cover hosting costs.</div>
                </div>
                <div className='flex justify-center items-center'>
                    <div className='bg-yellow-500 px-3 rounded-full text-blue-800 mb-4'>
                        <a
                            href="https://www.paypal.com/donate/?hosted_button_id=W2DWJWBRVBEV8"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex h-7"
                        >
                            <div className='whitespace-nowrap flex justify-center items-center font-semibold text-sm mr-0.5'>
                                Donate with
                            </div>
                            <img
                                className='flex justify-center items-center w-auto h-auto px-1 pt-[6px] pb-1'
                                src={process.env.PUBLIC_URL + '/assets/paypal_logo.png'}
                                alt="PayPal"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;




// import React, { useRef } from 'react'
// import emailjs from '@emailjs/browser';

// const Contact = () => {

//     const form = useRef()

//     const sendEmail = (e) => {
//         e.preventDefault()

//         emailjs.sendForm(process.env.REACT_APP_EJS_SERVICE_ID, process.env.REACT_APP_EJS_TEMPLATE_ID, form.current, {
//             publicKey: process.env.REACT_APP_EJS_PUBLIC_KEY,
//         }).then(
//             () => {
//                 console.log("SUCCESS!")
//             },
//             (error) => {
//                 console.log('FAILED...', error)
//                 console.log(process.env.REACT_APP_EJS_SERVICE_ID)
//                 console.log(process.env.REACT_APP_EJS_TEMPLATE_ID)
//                 console.log(process.env.REACT_APP_EJS_PUBLIC_KEY)
//             }
//         )
//     }

//     return (
//         <div className='text-zinc-400'>
//             <div className='mt-3 mx-3 rounded-lg bg-zinc-xxx'>
//                 <form className="py-14" ref={form} onSubmit={sendEmail}>
//                     <div className='flex justify-center items-center text-sm px-6 w-full'>
//                         <div className='text-center'>WINRATES.GG is currently in an open beta.<br/> Have a suggestion, noticed a bug, a joke to tell? Send a message. If reporting a bug please try to be detailed.</div>
//                     </div>
//                     <div className='flex justify-center items-center mt-2'>
//                         <label>Email</label>
//                     </div>
//                     <div className='flex justify-center items-center mt-2'>
//                         <input type="email" name="user_email" className="bg-zinc-900 rounded-md border border-zinc-600 focus-within:outline-none focus-within:border-none focus-within:ring-1 focus-within:ring-cyan-600 hover:border-cyan-600" />
//                     </div>
//                     <div className='flex justify-center items-center mt-2 '>
//                         <label>Message</label>
//                     </div>
//                     <div className='flex justify-center items-center mt-2'>
//                         <textarea name="message" className='bg-zinc-900 rounded-md border border-zinc-600 focus-within:outline-none focus-within:border-none focus-within:ring-1 focus-within:ring-cyan-600 hover:border-cyan-600' />
//                     </div>
//                     <div className='flex justify-center items-center mt-2'>
//                         <div className='bg-zinc-900 border border-zinc-600 whitespace-nowrap font-bold text-sm rounded hover:bg-zinc-700 py-1 px-2'>
//                             <input type="submit" value="Send"/>
//                         </div>
//                     </div>
//                 </form>
//             </div>
//             <div className='mt-3 mx-3 rounded-lg bg-zinc-xxx mb-3 pt-3'>
//                 <div className='flex flex-col justify-center items-center text-xs mb-3'>
//                     <div>WR.GG is a one man show.</div>
//                     <div>Appreciate the work?</div>
//                     <div>Consider leaving a tip (via PayPal)</div>
//                     <div>to help cover hosting costs.</div>
//                 </div>
//                 <div className='flex justify-center items-center'>
//                     <div className='bg-yellow-500 px-3 rounded-full text-blue-800 mb-4'>
//                         <a
//                             href="https://www.paypal.com/donate/?hosted_button_id=W2DWJWBRVBEV8"
//                             target="_blank"
//                             rel="noopener noreferrer"
//                             className="flex h-7"
//                         >
//                             <div className='whitespace-nowrap flex justify-center items-center font-semibold text-sm mr-0.5'>Donate with</div><img className='flex justify-center items-center w-auto h-auto px-1 pt-[6px] pb-1' src={process.env.PUBLIC_URL + '/assets/paypal_logo.png'} />
//                         </a>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Contact