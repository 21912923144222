import React from 'react'
import BannedChampionIconWithFallBack from '../utilities/BannedChampionIcon'

const BannedChampionsDesktop = ({ orderedChampions, match, individualStats }) => {

    const rawBans = match?.info?.teams
    const firstBans = rawBans?.find((team) => team?.bans)?.bans
    const lastIndex = rawBans?.findLastIndex((team) => team?.bans)
    const lastBans = rawBans[lastIndex]?.bans



    return (
        <div>
            {firstBans?.length != 0 ?
                <div className={`text-xs font-semibold rounded-md mt-1 p-2 pt-3 flex justify-between px-2 ${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-900" : individualStats?.win ? "bg-cyan-950" : "bg-rose-950"}`}>
                    <div className='flex items-center justify-center gap-2'>
                        <div>BLUE SIDE:</div>
                        {firstBans?.map((bannedChampion) => {
                            let championNameId = orderedChampions.find(champ => champ.championId === bannedChampion?.championId)?.nameId
                            return <BannedChampionIconWithFallBack key={bannedChampion?.championId} alt="banned champion" src={`https://d6n8t0712uu0b.cloudfront.net/champion_icons/${championNameId}.png`} />
                        })}
                    </div>
                    <div className='font-normal'>BANS</div>
                    <div className='flex items-center justify-center gap-2'>
                        <div>RED SIDE:</div>
                        {lastBans?.map((bannedChampion) => {
                            let championNameId = orderedChampions.find(champ => champ.championId === bannedChampion?.championId)?.nameId
                            return <BannedChampionIconWithFallBack key={bannedChampion?.championId} alt="banned champion" src={`https://d6n8t0712uu0b.cloudfront.net/champion_icons/${championNameId}.png`} />
                        })}
                    </div>
                </div>
                :
                <div className={`flex items-center rounded justify-center text-xs py-2 ${(match?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-900" : individualStats?.win ? "bg-cyan-950" : "bg-rose-950"}`}>
                    Game Mode Does Not Ban Champions.
                </div>
            }
        </div>
    )
}

export default BannedChampionsDesktop