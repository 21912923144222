import React from 'react'

const ProfileIconWithFallback = ({src, alt}) => {

    function handleError(e){
        // e.target.src = process.env.PUBLIC_URL + `/assets/profile_icons/0.png`
        e.target.src = process.env.PUBLIC_URL + `/assets/missing_asset/missing_profile_icon.png`
    }

    return (
        <img
            // className="rounded outline outline-2 outline-zinc-600 w-full"
            className="rounded border border-zinc-600 w-full"
            src={src}
            alt={alt}
            onError={(e)=>handleError(e)}
        />
    )
}

export default ProfileIconWithFallback