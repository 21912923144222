import React from "react";

const PreferredRole = ({ preferredRole }) => {
    // const [allRoles, setAllRoles] = useState(0)

    const allRoles = preferredRole?.top + preferredRole?.jungle + preferredRole?.middle + preferredRole?.bottom + preferredRole?.support;


    // // Necessary because 100% breaks the formatting
    // const topPercent = (preferredRole?.top / allRoles) * 100 == 100 ? 99 : (preferredRole?.top / allRoles) * 100
    // const junglePercent = (preferredRole?.jungle / allRoles) * 100 == 99 ? 99 : (preferredRole?.jungle / allRoles) * 100
    // const middlePercent = (preferredRole?.middle / allRoles) * 100 == 99 ? 99 : (preferredRole?.middle / allRoles) * 100
    // const bottomPercent = (preferredRole?.bottom / allRoles) * 100 == 99 ? 99 : (preferredRole?.bottom / allRoles) * 100
    // const supportPercent = (preferredRole?.support / allRoles) * 100 == 99 ? 99 : (preferredRole?.support / allRoles) * 100

    // Necessary because 100% breaks the formatting
    const topPercent = (preferredRole?.top / allRoles) * 100 
    const junglePercent = (preferredRole?.jungle / allRoles) * 100 
    const middlePercent = (preferredRole?.middle / allRoles) * 100 
    const bottomPercent = (preferredRole?.bottom / allRoles) * 100 
    const supportPercent = (preferredRole?.support / allRoles) * 100 

    // Where 12 is the height of the div (h-12 in tailwind)
    const topBarHeight = "h-" + `${Math.round(12 * topPercent / 100)}`
    const jungleBarHeight = "h-" + `${Math.round(12 * junglePercent / 100)}`
    const middleBarHeight = "h-" + `${Math.round(12 * middlePercent / 100)}`
    const bottomBarHeight = "h-" + `${Math.round(12 * bottomPercent / 100)}`
    const supportBarHeight = "h-" + `${Math.round(12 * supportPercent / 100)}` 



    return (
        <div className="bg-zinc-775 text-zinc-400 italic text-xs text-center font-bold rounded-lg px-8 py-4 mb-3 mt-3">
            <h2 className="mb-3">PREFERRED POSITION</h2>

            <div className="mt-2 w-full flex justify-around">
                <div className="w-5 h-full flex flex-col items-center gap-y-2">
                    <div className="w-5 h-12 bg-zinc-900 flex flex-col justify-end border border-zinc-600">
                        <div className={`w-full ${topBarHeight} bg-zinc-600`}></div>
                    </div>
                    <img

                        alt="summoner spell icon"
                        src={process.env.PUBLIC_URL + `/assets/role_icons/position-top-light.svg`}
                    />
                </div>
                <div className="w-5 h-full flex flex-col items-center gap-y-2">
                    <div className="w-5 h-12 bg-zinc-900 flex flex-col justify-end border border-zinc-600">
                        <div className={`w-full ${jungleBarHeight} bg-zinc-600`}></div>
                    </div>
                    <img

                        alt="summoner spell icon"
                        src={process.env.PUBLIC_URL + `/assets/role_icons/position-jungle-light.svg`}
                    />
                </div>
                <div className="w-5 h-full flex flex-col items-center gap-y-2">
                    <div className="w-5 h-12 bg-zinc-900 flex flex-col justify-end border border-zinc-600">
                        <div className={`w-full ${middleBarHeight} bg-zinc-600`}></div>
                    </div>
                    <img

                        alt="summoner spell icon"
                        src={process.env.PUBLIC_URL + `/assets/role_icons/position-middle-light.svg`}
                    />
                </div>
                <div className="w-5 6-full flex flex-col items-center gap-y-2">
                    <div className="w-5 h-12 bg-zinc-900 flex flex-col justify-end border border-zinc-600">
                        <div className={`w-full ${bottomBarHeight} bg-zinc-600`}></div>
                    </div>
                    <img
                        alt="summoner spell icon"
                        src={process.env.PUBLIC_URL + `/assets/role_icons/position-bottom-light.svg`}
                    />
                </div>
                <div className="w-5 h-full flex flex-col items-center gap-y-2">
                    <div className="w-5 h-12 bg-zinc-900 flex flex-col justify-end border border-zinc-600">
                        <div className={`w-full ${supportBarHeight} bg-zinc-600`}></div>
                    </div>
                    <img
                        alt="summoner spell icon"
                        src={process.env.PUBLIC_URL + `/assets/role_icons/position-utility-light.svg`}
                    />
                </div>
            </div>
        </div>
    );
};

export default PreferredRole;


