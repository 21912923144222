import React from 'react'

const ParticipantItemIconsWithFallBack = ({ className, alt, src, match, individualStats, matchRecord }) => {


    if (src.includes("/0.png")) { // Empty, not missing
        // src = process.env.PUBLIC_URL + `/assets/empty_icons/0.png`
        return (<div className={`w-[19px] h-[19px] rounded-md ${(matchRecord?.metadata?.info?.endOfGameResult === "Abort_Unexpected" || individualStats?.gameEndedInEarlySurrender) ? "bg-gray-800" : individualStats?.win ? "bg-cyan-900" : "bg-rose-925"}`}></div>)
    }

    function handleError(e) {
        // e.target.src = process.env.PUBLIC_URL + `/assets/profile_icons/0.png`
        e.target.src = process.env.PUBLIC_URL + `/assets/missing_asset/missing_icon.png`
    }
    return (
        <img
            className={className}
            src={src}
            alt={alt}
            onError={(e) => handleError(e)}
        />
    )
}

export default ParticipantItemIconsWithFallBack

