import { React, useEffect, useState } from 'react'
import { FaMagnifyingGlass } from "react-icons/fa6";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { ImCross } from "react-icons/im";
import { TbBeta } from "react-icons/tb";


const Home = ({ formErrors, setFormErrors, handleSummonerNameEntry, handlePlatformSelection, submitAndSearchSummoner, summonerSearchFormData }) => {


    return (
        <div className='my-10 rounded-lg px-4 pt-2 pb-2 flex flex-col items-center h-[70vh] mb-[250px] w-full'>
            <div className='text-center mb-5 mt-9 mx-9 flex flex-col flex-[.3] justify-center items-center text-9xl rounded-lg bg-zinc-775 leading-none h-60 w-60'>
                <span className="text-cyan-600 font-[1000] left-italic -mb-1">WR</span>
                <span className="text-white font-[1000] -mt-4">.GG</span>
            </div>
            <div className="mt-3 min-w-[300px] max-w-[768px] w-full">
                <div className='text-xs font-semibold text-gray-200 py-1 px-2 rounded-bl-lg'> SELECT A REGION:</div>
                <form onSubmit={(e) => submitAndSearchSummoner(e)}>
                    <div className="flex flex-nowrap h-8 w-full overflow-x-auto bg-zinc-900 rounded-md border border-zinc-600 focus-within:outline-none focus-within:border-none focus-within:ring-1 focus-within:ring-cyan-600 hover:border-cyan-600">
                        <label htmlFor="platform"></label>
                        {localStorage.getItem("platform") &&
                            localStorage.getItem("region") &&
                            localStorage.getItem("displayRegion") ? (
                            <div className="flex items-center">
                                <select
                                    className="appearance-none h-full bg-zinc-900 rounded-l-md text-zinc-400 font-normal text-center shrink select-centered"
                                    value={localStorage.getItem("platform")}
                                    onChange={(e) => handlePlatformSelection(e)}
                                    name="platform"
                                >
                                    <option value={null} disabled>
                                        Region
                                    </option>
                                    <option value="na1">NA</option>
                                    <option value="euw1">EUW</option>
                                    <option value="br1">BR</option>
                                    <option value="eun1">EUNE</option>
                                    <option value="kr">KR</option>
                                    <option value="oc1">OCE</option>
                                    <option value="la1">LAN</option>
                                    <option value="la2">LAS</option>
                                    <option value="ru">RU</option>
                                    <option value="tr1">TR</option>
                                    {/* <option value="me1">ME</option> */}
                                    <option value="jp1">JP</option>
                                    <option value="ph2">PH</option>
                                    <option value="sg2">SG</option>
                                    <option value="th2">TH</option>
                                    <option value="tw2">TW</option>
                                    <option value="vn2">VN</option>
                                </select>
                                <div className="text-zinc-400">
                                    <RiArrowUpSLine className="-mb-2" />
                                    <RiArrowDownSLine />
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center">
                                <select
                                    className="appearance-none h-full bg-zinc-900 rounded-l-md text-zinc-400 font-normal text-center shrink select-centered"
                                    onChange={(e) => handlePlatformSelection(e)}
                                    name="platform"
                                >
                                    <option className="text-xs" value={null}>&nbsp;Select Region&nbsp;</option>
                                    <option value="na1">NA</option>
                                    <option value="euw1">EUW</option>
                                    <option value="br1">BR</option>
                                    <option value="eun1">EUNE</option>
                                    <option value="kr">KR</option>
                                    <option value="oc1">OCE</option>
                                    <option value="la1">LAN</option>
                                    <option value="la2">LAS</option>
                                    <option value="ru">RU</option>
                                    <option value="tr1">TR</option>
                                    {/* <option value="me1">ME</option> */}
                                    <option value="jp1">JP</option>
                                    <option value="ph2">PH</option>
                                    <option value="sg2">SG</option>
                                    <option value="th2">TH</option>
                                    <option value="tw2">TW</option>
                                    <option value="vn2">VN</option>
                                </select>
                                <div className="text-zinc-400">
                                    <RiArrowUpSLine className="-mb-2" />
                                    <RiArrowDownSLine />
                                </div>
                            </div>
                        )}
                        <label htmlFor="gameName"></label>
                        <div className="relative flex items-center">
                            <input
                                autoComplete='off'
                                autoCorrect='off'
                                spellCheck='false'
                                className="bg-zinc-900 ml-1 text-zinc-400 placeholder:text-zinc-400  text-sm basis-20 h-full grow-[5] shrink focus-within:outline-none "
                                onChange={handleSummonerNameEntry}
                                onInput={handleSummonerNameEntry}
                                placeholder=" Summoner Name +"
                                name="gameName"
                                type="text"
                            ></input>
                            {(summonerSearchFormData?.gameName == "" && summonerSearchFormData?.tagLine == "") && <span className="absolute left-[138px] text-zinc-400 bg-zinc-800 text-xs px-1 py-0.5 border border-zinc-600 rounded-md">
                                #TagLine
                            </span>}
                            {/* {console.log(summonerSearchFormData)} */}
                        </div>
                        <div className="flex justify-end w-full">
                            <button className="flex items-center h-full" type="submit">
                                <FaMagnifyingGlass className="text-zinc-400 h-4 w-4 mx-2" />
                            </button>
                        </div>
                    </div>
                </form>
                {formErrors?.length > 0 &&
                    <div className='mt-3 bg-rose-200 flex items-center px-3 gap-2 border border-rose-600 text-rose-900 rounded-md p-1'>
                        <div onClick={() => setFormErrors([])}><ImCross /></div>
                       {formErrors?.map((error) => {
                        return <div>{error}</div>
                    })}
                    </div>
                }
                <div className='flex flex-col justify-center items-center text-zinc-400 w-full max-w-[450px] mx-auto mt-10 text-xs px-4'>
                    <div className='text-center flex gap-0'>
                        WINRATES.GG (beta v1.00 ) is a League of Legends © companion app.
                    </div>
                    <div className='text-center mb-3'>
                        Try searching for a summoner profile, ex."Hide on bush#KR1"
                    </div>
                    <div className='text-center text-rose-700'>
                        This application is currently deployed on the internet for the sake of
                        approval & review by Riot Games' 3rd party application policy.
                    </div>
                    <div className='text-center text-rose-700'>
                        The fact that this site exists has not been published anywhere
                        in accordance with pre-production development/personal API key guidelines.
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Home



// return (
//     <div className='bg-zinc-775 mx-4 my-3 rounded-lg px-4 pt-2 pb-2 flex flex-col items-center h-[80vh]'>
//         <div className='text-center mb-5 mt-9 mx-9 flex flex-col flex-[.3] justify-center items-center text-9xl rounded-lg bg-zinc-700 leading-none h-60 w-60'>
//             <span className="text-cyan-600 font-extrabold left-italic -mb-1">WR</span>
//             <span className="text-white font-extrabold -mt-4">.GG</span>
//         </div>
//         <div className='mt-9'>
//             {/* <div className='flex flex-row'> Original */}
//             <form onSubmit={(e) => submitAndSearchSummoner(e)}>
//                 {/* <div className="flex flex-nowrap h-8 w-full overflow-x-auto bg-white rounded-lg"> Original */}
//                 <div className="flex-col h-8 w-full rounded-lg bg-zinc-700">
//                     <div className='mb-3  rounded-lg'>
//                         <label htmlFor="platform"></label>
//                         <p className='italic text-xs font-semibold text-gray-200 py-1 px-2 rounded-bl-lg'> SELECT A REGION:</p>
//                         {localStorage.getItem("platform") &&
//                             localStorage.getItem("region") &&
//                             localStorage.getItem("displayRegion") ? (
//                             <select className="h-8 italic text-gray-500 font-bold text-center bg-slate-300 w-3/12 rounded-bl-lg" value={localStorage.getItem("platform")} onChange={(e) => handlePlatformSelection(e)} name="platform">
//                                 <option value={null} disabled>
//                                     Region
//                                 </option>
//                                 <option value="na1">NA</option>
//                                 <option value="euw1">EUW</option>
//                                 <option value="br1">BR</option>
//                                 <option value="eun1">EUNE</option>
//                                 <option value="kr">KR</option>
//                                 <option value="oc1">OCE</option>
//                                 <option value="jp1">JP</option>
//                             </select>
//                         ) : (
//                             <select className="h-8 italic text-gray-500 font-bold text-center bg-slate-300 w-3/12 rounded-bl-lg" onChange={(e) => handlePlatformSelection(e)} name="platform">
//                                 <option value={null}>Select Region</option>
//                                 <option value="na1">NA</option>
//                                 <option value="euw1">EUW</option>
//                                 <option value="br1">BR</option>
//                                 <option value="eun1">EUNE</option>
//                                 <option value="kr">KR</option>
//                                 <option value="oc1">OCE</option>
//                                 <option value="jp1">JP</option>
//                             </select>
//                         )}
//                         <label htmlFor="gameName"></label>
//                         <input className="h-8 placeholder:italic placeholder:text-gray-500 w-6/12 rounded-none"
//                             onChange={handleSummonerNameEntry}
//                             placeholder=" Summoner Name"
//                             name="gameName"
//                             type="text"
//                         ></input>
//                         <label htmlFor="tagLine"></label>
//                         <input
//                             className="h-8 placeholder:italic placeholder:text-gray-500 w-3/12 rounded-none rounded-br-lg"
//                             onChange={handleSummonerNameEntry}
//                             placeholder=" Tagline"
//                             name="tagLine"
//                             type="text"
//                         ></input>
//                     </div>
//                     <div className='flex justify-center items-center'>
//                         <button className="bg-zinc-950 h-8 rounded-md outline outline-2 outline-zinc-700" type="submit">
//                             <FaMagnifyingGlass className="text-white h-4 w-8 mx-2" />
//                         </button>
//                     </div>
//                 </div>
//             </form>
//         </div>
//     </div>
// )
// }