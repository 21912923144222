import React from 'react'

const Footer = () => {
    return (

        <div className="bg-zinc-950 flex flex-col py-4 px-4 border-b border-zinc-800 h-[40vh] text-white text-xs">
            <div className='max-w-[1000px] mx-auto'>
                <div className='mb-4 flex justify-center items-center'>© 2024 WINRATES.GG (WR.GG) is a League of Legends © companion app and registered trademark.</div>
                <div className='mb-4 flex justify-center items-center'>WINRATES.GG is not endorsed by Riot Games and does not reflect the views or opinions of Riot Games or anyone officially involved in producing or managing Riot Games properties. Riot Games and all associated properties are trademarks or registered trademarks of Riot Games, Inc.</div>
                <div className='flex justify-center items-center'><span>Created under Riot Games' <a className="font-bold text-zinc-500" href="https://www.riotgames.com/en/legal">"Legal Jibber Jabber"</a> policy using assets owned by Riot Games.</span></div>
            </div>
        </div>
    )
}

export default Footer