import React from 'react'

const ChampionsMasteredIconWithFallBack = ({ src, alt }) => {

    function handleError(e) {
        // e.target.src = process.env.PUBLIC_URL + `/assets/champion/missing_champion.png`
        e.target.src = process.env.PUBLIC_URL + `/assets/missing_asset/missing_profile_icon.png`
    }

    return (
        <img
            className='rounded-full h-full w-full transform scale-[1.2]'
            src={src}
            alt={alt}
            onError={(e) => handleError(e)}
        />
    )
}


export default ChampionsMasteredIconWithFallBack