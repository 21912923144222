import React from 'react'
import { useParams, useLocation } from 'react-router-dom'
import axios from 'axios'
import { useEffect, useState } from 'react'
import MatchHistory from './MatchHistory'
import MatchHistoryDesktop from './MatchHistoryDesktop'
import Loading from './Loading'
import SummonerDetailError from '../errors/SummonerDetailError'
import { Container, Row, Col, Button, Card } from 'react-bootstrap'
import PreferredRole from './PreferredRole'
import ChampionMastery from './ChampionMastery'
import PersonalChampionStats from './PersonalChampionStats'
import ProfileIconWithFallback from '../utilities/ProfileIcon'
import { SlArrowDown, SlArrowUp } from "react-icons/sl";

const SummonerDetail = ({ searchTrigger, region, platform, globallyUpdateDisplayedRegion, error, setError }) => {
    const apiEndpointPrefix = process.env.REACT_APP_API_ENDPOINT_PREFIX

    const params = useParams()
    const displayRegion = params.displayRegion
    const displayName = params.displayNameZipped
    const [gameName, tagLine] = displayName.split("-")

    const [summonerData, setSummonerData] = useState({})
    const [orderedChampions, setOrderedChampions] = useState([])
    const [queueFilter, setQueueFilter] = useState(0)
    const [championFilter, setChampionFilter] = useState(0)
    const [displaedRank, setDisplayedRank] = useState("solo")

    const [showFetchButtonForQueueType, setShowFetchButtonForQueueType] = useState(true)
    const [showFetchButtonForAllGames, setShowFetchButtonForAllGames] = useState(true)
    const [loading, setLoading] = useState(true)

    const location = useLocation()

    const [colorToggle, setColorToggle] = useState(() => {
        const savedTheme = localStorage.getItem('colorToggle')
        return savedTheme ? parseInt(savedTheme) : 1; // Default to theme 1 if not set
    });

    useEffect(() => { // If these values are previously saved to local storage, get & save to state
        localStorage.setItem('colorToggle', colorToggle);
    }, [colorToggle])

    function cycleTheme() {
        setColorToggle((prevTheme) => (prevTheme % 4) + 1);
    }

    // On URL change (clicking on  gameName hyperlink) re-fetch summoner data
    useEffect(() => {
        getSummonerData()
        setShowFetchButtonForQueueType(true)
        setShowFetchButtonForAllGames(true)
    }, [location.pathname, searchTrigger])


    const countdownKey = 'countdownEndTime'; // Key for localStorage
    const [countdown, setCountdown] = useState(null);

    // Function to clear countdown data
    const clearCountdown = () => {
        localStorage.removeItem(countdownKey);
        setCountdown(null); // Reset countdown state
    };

    // Function to start countdown, only invoked when API sends a 206 or 207 (which means it got a 429)
    const startCountdown = (duration) => {
        const endTime = Date.now() + duration * 1000; // Calculate end time based on retry_after
        localStorage.setItem(countdownKey, endTime); // Store end time in local storage
        setCountdown(duration); // Set initial countdown state
    };

    // Check if there's a stored countdown when the component mounts (including page refresh)
    useEffect(() => {
        const storedEndTime = localStorage.getItem(countdownKey);
        if (storedEndTime) {
            const remainingTime = Math.floor((parseInt(storedEndTime, 10) - Date.now()) / 1000);
            if (remainingTime > 0) {
                setCountdown(remainingTime); // Set the countdown if time remains
            } else {
                clearCountdown(); // Clear expired countdown
            }
        }
    }, []);

    // Countdown effect - if there is a countdown, tick it down in state every 1 second
    useEffect(() => {
        if (countdown !== null) {
            const countdownInterval = setInterval(() => {
                setCountdown(prev => {
                    const newValue = prev - 1;
                    if (newValue <= 0) {
                        clearInterval(countdownInterval); // Clear interval when countdown reaches 0
                        clearCountdown(); // Clear local storage and reset countdown
                        return null; // Countdown has ended
                    }
                    return newValue;
                });
            }, 1000);

            return () => clearInterval(countdownInterval); // Clear interval on unmount
        }
    }, [countdown]);

    // Get summoner data from db cache, including match details nested as json
    async function getSummonerData(queryLimit = null, update = false, specificQueue = false, specificChampion = false) {
        setLoading(true)
        const displayedRegion = params.displayRegion
        const mapDisplayedToRegionToVerboseRegion = {
            'na': 'americas',
            'br': 'americas',
            'lan': 'americas',
            'las': 'americas',
            'euw': 'europe',
            'eun': 'europe',
            'tr': 'europe',
            'ru': 'europe',
            'kr': 'asia',
            'jp': 'asia',
            'oc': 'asia', // technically SEA but not an option for the endpoint(s) that will be hit
            'ph': 'asia', // technically SEA but not an option for the endpoint(s) that will be hit
            'sg': 'asia', // technically SEA but not an option for the endpoint(s) that will be hit
            'th': 'asia', // technically SEA but not an option for the endpoint(s) that will be hit
            'tw': 'asia', // technically SEA but not an option for the endpoint(s) that will be hit
            'vn': 'asia', // technically SEA but not an option for the endpoint(s) that will be hit
        }
        const newRegion = mapDisplayedToRegionToVerboseRegion[displayedRegion]

        const mapDisplayedRegionToPlatform = {
            'na': 'na1',
            'br': 'br1',
            'lan': 'la1',
            'las': 'la2',
            'euw': 'euw1',
            'eun': 'eun1',
            'tr': 'tr1',
            'ru': 'ru',
            'kr': 'kr',
            'jp': 'jp1',
            'oc': 'oc1',
            'ph': 'ph2',
            'sg': 'sg2',
            'th': 'th2',
            'tw': 'tw2',
            'vn': 'vn2',
        }
        const newPlatform = mapDisplayedRegionToPlatform[displayedRegion]


        let url = apiEndpointPrefix + `/summoner/?region=${newRegion}&platform=${newPlatform}&gameName=${gameName}&tagLine=${tagLine}`
        url += queryLimit ? `&limit=${queryLimit}` : ''
        url += update ? `&update=${update}` : ''
        url += specificQueue ? `&queueId=${specificQueue}` : ''
        url += specificChampion ? `&championId=${specificChampion}` : ''

        // Config to abort get request on timeout (45s), without this even if I redirect via the loading component the GET request will still process in this component & re-render
        const controller = new AbortController()
        const signal = controller.signal;
        const timeout = setTimeout(() => {
            controller.abort()
        }, 120000)

        try {
            let response = await axios.get(url, { signal })
            clearTimeout(timeout)
            // console.log(response.data)
            setSummonerData(response.data.summoner_data)
            setOrderedChampions(response.data.ordered_champions)
            // console.log(response.data.ordered_champions)
            globallyUpdateDisplayedRegion(newPlatform)
            setLoading(false)
            setError(false)
            if (!specificQueue && response.data.summoner_data.match_history.length < queryLimit) {
                setShowFetchButtonForAllGames(false)
            } else if (specificQueue && response.data.summoner_data.match_history.length < queryLimit) {
                setShowFetchButtonForQueueType(false)
            }

            if (response.status === 206 || response.status === 207) {
                const retryTime = response.data.summoner_data.retry_after; // Extract retry_after from the response
                // ADD RIOT SERVICE OUTAGE LOGIC HERE, in the summoner_data json there will be an errors field where 503 exists in an array
                // ADD RIOT SERVICE OUTAGE LOGIC HERE, in the summoner_data json there will be an errors field where 503 exists in an array
                // ADD RIOT SERVICE OUTAGE LOGIC HERE, in the summoner_data json there will be an errors field where 503 exists in an array
                // Render something so the user knows there are outages outside of my control
                startCountdown(retryTime); // Start countdown with retry_after
            }

        } catch (error) {
            // if (error.response.status === 429) {
            //     return getSummonerData(null, false) // Retry the request but only get internalyl saved data (thus, false)
            // }
            clearTimeout(timeout)
            setLoading(false)
            if (axios.isCancel(error)) {
                // console.log(error)
                setError({ "message": "Request took excessively long. Please try again momentarily", status_code: 408 })
            } else if (error.response) {
                // console.log(error.response.data);
                setError({ "message": error.response.data, status_code: error.response.status })
            } else {
                // console.log(error.message);
                setError({ "message": "Unexpected error occurred. Please try again later.", status_code: error.code || "UNKNOWN" });
            }
        }
    }


    // Upon rendering this page update state at App level using the params
    // This is necessary so a user can enter the app through a direct summoner profile page
    // without setting state in the search form

    // Get latest update for the summoner being viewed /w update Button
    function forceUpdatePage() {
        getSummonerData(null, true)
    }

    // Calculate and display time since last update
    function formatLastUpdateTime(timestamp) {
        const currentTime = new Date();
        const targetTime = new Date(timestamp);
        if (!timestamp || (isNaN(targetTime.getTime()))) return ': Unknown.';

        const difference = currentTime - targetTime;

        // Convert milliseconds to seconds
        const seconds = Math.floor(difference / 1000);

        // Define time units in seconds
        const minute = 60;
        const hour = minute * 60;
        const day = hour * 24;
        const month = day * 30; // Approximate
        const year = day * 365; // Approximate

        if (seconds < minute) {
            return `A Moment Ago`;
        } else if (seconds < hour) {
            const minutes = Math.floor(seconds / minute);
            return `${minutes} Minute${minutes !== 1 ? 's' : ''} Ago`;
        } else if (seconds < day) {
            const hours = Math.floor(seconds / hour);
            return `${hours} Hour${hours !== 1 ? 's' : ''} Ago`;
        } else if (seconds < month) {
            const days = Math.floor(seconds / day);
            return `${days} Day${days !== 1 ? 's' : ''} Ago`;
        } else if (seconds < year) {
            const months = Math.floor(seconds / month);
            return `${months} Month${months !== 1 ? 's' : ''} Ago`;
        } else {
            const years = Math.floor(seconds / year);
            return `${years} Year${years !== 1 ? 's' : ''} Ago`;
        }
    }


    const summonerOverview = summonerData?.overviews?.[0]?.metadata

    const matchHistory = summonerData?.match_history

    const preferredRole = summonerData?.preferred_role

    const championMastery = summonerData?.champion_mastery

    const personalChampionStats = summonerData?.personal_champ_stats


    function updateQueueFilterAndGetRecentMatchDetails(queueId, championId) {
        setQueueFilter(queueId)
        setShowFetchButtonForQueueType(true)
        if (!championId) {
            getSummonerData(3, false, queueId) // IN PROD THIS SHOULD BE SAME AS DEFAULT ALL GAMES AMOUNT 
        } else {
            getSummonerData(3, false, queueId, championId) // IN PROD THIS SHOULD BE SAME AS DEFAULT ALL GAMES AMOUNT 
        }
    }

    // When clicking on "All Queues" filter undo queue filtering and re-run fetch match details
    function undoQueueFilterAndGetRecentMatchDetails(championId) {
        setQueueFilter(0)
        if (!championId) {
            getSummonerData(null, false)
        } else {
            getSummonerData(null, false, false, championId) // IN PROD THIS SHOULD BE SAME AS DEFAULT ALL GAMES AMOUNT 
        }
    }

    function updateChampionFilterAndGetRecentMatchDetails(queueId, championId) {
        setChampionFilter(championId)
        setShowFetchButtonForQueueType(true)
        if (!queueId) {
            getSummonerData(3, false, false, championId) // IN PROD THIS SHOULD BE SAME AS DEFAULT ALL GAMES AMOUNT 
        } else {
            getSummonerData(3, false, queueId, championId) // IN PROD THIS SHOULD BE SAME AS DEFAULT ALL GAMES AMOUNT 
        }
    }

    // When clicking on "All Champions" filter undo champion filtering and re-run fetch match details
    function undoChampionFilterAndGetRecentMatchDetails(queueId) {
        setChampionFilter(0)
        if (!queueId) {
            getSummonerData(null, false, false, false)
        } else {
            getSummonerData(null, false, queueId, false) // IN PROD THIS SHOULD BE SAME AS DEFAULT ALL GAMES AMOUNT 
        }
    }

    function undoAllFilters() {
        setChampionFilter(0)
        setQueueFilter(0)
        getSummonerData()
    }

    function formatRank(singleSummonerOverview) { // i.e. Solo or Flex for a given player
        const capitalizedFirstLetter = singleSummonerOverview?.tier?.charAt(0)?.toUpperCase()
        const remainingLetters = singleSummonerOverview?.tier?.slice(1).toLowerCase()
        // return "CHALLENGER"
        return capitalizedFirstLetter + remainingLetters
    }

    function formatWinRate(singleSummonerOverview) { // i.e. Solo or Flex for a given player

        let wins = singleSummonerOverview?.wins
        let losses = singleSummonerOverview?.losses
        let winrate = Math.round(wins / (wins + losses) * 100)
        let unranked = isNaN(winrate) ? "UNRANKED" : false

        if (winrate) {
            return (
                <div className='text-sm text-zinc-400'>
                    <div>{winrate}% WR</div>
                    {/* <div className='font-bold'>WIN RATE</div> */}
                    {/* <div className=''>{winrate}%</div> */}
                </div>
            )
        }

        if (unranked) {
            return (
                <div className='text-sm text-zinc-400'>
                    <div>N/A</div>
                    {/* <div className='font-bold'>WIN RATE</div>
                    <div>N/A %</div> */}
                </div>
            )
        }

    }

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <SummonerDetailError error={error} countdown={countdown}/>
    }

    function getSoloBadgeStyle(tier) {
        // console.log(tier)
        if (tier === "CHALLENGER" || tier === "GRANDMASTER" || tier === "MASTER" || tier === "DIAMOND" || tier === "EMERALD") {
            return "mr-3"
        } else {
            return "-ml-3 mr-2"
        }
    }

    function getFlexBadgeStyle(tier) {
        // console.log(tier)
        if (tier === "CHALLENGER" || tier === "GRANDMASTER" || tier === "MASTER" || tier === "DIAMOND" || tier === "EMERALD") {
            return "ml-4 mr-3"
        } else {
            return "ml-1 mr-3"
        }
    }

    return (
        <div className="my-3 max-w-screen-lg mx-auto">
            <div className='mx-4 custom-lg:mx-0'>
                <div className='bg-zinc-775 rounded-t-lg border-b border-b-zinc-700 px-4 pt-4 pb-2 text-white'>
                    {/* Smaller Screen "Summoner Detail Header" */}
                    {/* Smaller Screen "Summoner Detail Header" */}
                    {/* Smaller Screen "Summoner Detail Header" */}
                    <div className="block md:hidden">
                        <div className='flex mb-4 gap-x-5'>
                            {/* Left Column */}
                            <div className='w-[100px]'>
                                <div className="flex items-center w-full justify-center relative">
                                    {/* Consider a NGNIX-based pattern for profile icons, they do not really change unlike items or summoner spell icons */}
                                    <ProfileIconWithFallback src={`https://d6n8t0712uu0b.cloudfront.net/profile_icons/${summonerData?.profileIconId}.png`} alt="profile icon" />
                                    <div className="bg-zinc-775 text-sm px-2 rounded-sm outline outline-1 outline-zinc-600 absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
                                        {summonerOverview?.solo?.summonerLevel}
                                    </div>
                                </div>
                                {/* <button className="px-2 py-1 bg-slate-600 font-bold text-white text-sm rounded hover:bg-cyan-700 mt-5 w-full" onClick={() => forceUpdatePage()}>UPDATE</button> */}
                                <div>
                                    {countdown !== null ?
                                        <div className='text-center px-2 py-1 bg-zinc-900 border border-zinc-600 font-bold text-white text-xs rounded mt-5 w-full'>Update in {countdown}s</div>
                                        :
                                        <button className="px-2 py-1 bg-zinc-900 border border-zinc-600 font-bold text-white text-sm rounded hover:bg-cyan-700 mt-5 w-full" onClick={() => forceUpdatePage()}>UPDATE</button>
                                    }
                                </div>
                            </div>
                            {/* Right Column */}
                            <div className=''>
                                <div className='flex'>
                                    <div className='font-bold w-[120px] -mt-0.5'>
                                        <h1 className='font-bold text-xl whitespace-nowrap'>{summonerData?.gameName}</h1>
                                        <div className='text-zinc-400 border-b border-zinc-700 inline-block'>#{summonerData?.tagLine}</div>
                                    </div>
                                </div>
                                {
                                    displaedRank === "solo" ?
                                        <div className='flex gap-5 items-center text-xs'>
                                            <img className="-ml-3" width="90" height="90" alt="ranked icons" src={process.env.PUBLIC_URL + `/assets/ranked_icons/Rank=${formatRank(summonerOverview?.solo)}.png`} />
                                            <div className="-mt-5">
                                                <div className='font-bold border-b border-zinc-600 mb-1'>
                                                    SOLO RANK:
                                                </div>
                                                <div className='text-zinc-400'>
                                                    <div className='font-bold whitespace-nowrap'>{summonerOverview?.solo?.tier} {summonerOverview?.solo?.rank === 'UNRANKED' ? null : summonerOverview?.solo?.rank}</div>
                                                </div>
                                                <div className='font-semibold text-zinc-400'>
                                                    {summonerOverview?.solo?.leaguePoints} LP
                                                </div>
                                                <div className="flex flex-wrap gap-x-1 text-zinc-400">
                                                    <div className="font-semibold">
                                                        W: {summonerOverview?.solo?.wins}
                                                    </div>
                                                    <div className="font-semibold">
                                                        L: {summonerOverview?.solo?.losses}
                                                    </div>
                                                </div>
                                                <div className='font-semibold'>
                                                    {formatWinRate(summonerOverview?.solo)}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='flex gap-5 items-center text-xs'>
                                            <img className="-ml-3" width="90" height="90" alt="ranked icons" src={process.env.PUBLIC_URL + `/assets/ranked_icons/Rank=${formatRank(summonerOverview?.flex)}.png`} />
                                            <div className="-mt-6">
                                                <div className='font-bold border-b border-zinc-600 mb-1'>
                                                    FLEX RANK:
                                                </div>
                                                <div className='text-zinc-400'>
                                                    <div className='font-bold whitespace-nowrap'>{summonerOverview?.flex?.tier} {summonerOverview?.flex?.rank === 'UNRANKED' ? null : summonerOverview?.flex?.rank}</div>
                                                </div>
                                                <div className='text-sm font-semibold text-zinc-400'>
                                                    {summonerOverview?.flex?.leaguePoints} LP
                                                </div>
                                                <div className="flex flex-wrap gap-x-1 text-zinc-400">
                                                    <div className="font-semibold">
                                                        W: {summonerOverview?.flex?.wins}
                                                    </div>
                                                    <div className="font-semibold">
                                                        L: {summonerOverview?.flex?.losses}
                                                    </div>
                                                </div>
                                                <div className='font-semibold'>
                                                    {formatWinRate(summonerOverview?.flex)}
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* Larger Screen "Summoner Detail Header" */}
                    {/* Larger Screen "Summoner Detail Header" */}
                    {/* Larger Screen "Summoner Detail Header" */}
                    <div className="hidden md:block">
                        <div className='flex mb-2'>
                            {/* First Column */}
                            <div className='w-[100px] mr-5'>
                                <div className="flex items-center w-full justify-center relative">
                                    {/* Consider a NGNIX-based pattern for profile icons, they do not really change unlike items or summoner spell icons */}
                                    <ProfileIconWithFallback src={`https://d6n8t0712uu0b.cloudfront.net/profile_icons/${summonerData?.profileIconId}.png`} alt="profile icon" />
                                    <div className="bg-zinc-775 text-sm px-2 rounded-sm outline outline-1 outline-zinc-600 absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
                                        {summonerOverview?.solo?.summonerLevel}
                                    </div>
                                </div>
                                {/* <button className="px-2 py-1 bg-slate-600 font-bold text-white text-sm rounded hover:bg-cyan-700 mt-5 w-full" onClick={() => forceUpdatePage()}>UPDATE</button> */}
                                <div>
                                    {
                                        countdown !== null ?
                                            <div className='text-center px-2 py-1 bg-zinc-900 border border-zinc-600 font-bold text-white text-xs rounded mt-5 w-full'>Update in {countdown}s</div>
                                            :
                                            <button className="px-2 py-1 bg-zinc-900 border border-zinc-600 font-bold text-white text-sm rounded hover:bg-cyan-700 mt-5 w-full" onClick={() => forceUpdatePage()}>UPDATE</button>
                                    }
                                </div>
                            </div>
                            {/* Second Column */}
                            <div className=''>
                                <div>
                                    <h1 className='font-bold text-xl whitespace-nowrap'>{summonerData?.gameName}</h1>
                                </div>
                                <div className='flex'>
                                    <div className='font-bold text-zinc-400 -mt-0.5 border-b border-zinc-600'>#{summonerData?.tagLine}</div>
                                </div>
                                <div className='flex text-md'>
                                    <img className={`${getSoloBadgeStyle(summonerOverview?.solo?.tier)}`} width="100" height="100" alt="ranked icons" src={process.env.PUBLIC_URL + `/assets/ranked_icons/Rank=${formatRank(summonerOverview?.solo)}.png`} />
                                    <div className='flex flex-col'>
                                        <div className='text-sm font-bold border-b border-zinc-600 mb-1'>
                                            SOLO/DUO QUEUE RANK
                                        </div>
                                        <div className='flex gap-2'>
                                            <div>
                                                <div className='text-sm font-semibold whitespace-nowrap text-zinc-400'>{summonerOverview?.solo?.tier} {summonerOverview?.solo?.rank === 'UNRANKED' ? null : summonerOverview?.solo?.rank}</div>
                                                <div className='text-sm font-semibold text-zinc-400'>
                                                    {summonerOverview?.solo?.leaguePoints} LP
                                                </div>
                                                <div className="flex flex-wrap gap-x-1 text-zinc-400">
                                                    <div className="font-semibold text-sm">
                                                        W: {summonerOverview?.solo?.wins}
                                                    </div>
                                                    <div className="font-semibold text-sm">
                                                        L: {summonerOverview?.solo?.losses}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='font-semibold'>
                                                {formatWinRate(summonerOverview?.solo)}
                                            </div>
                                        </div>
                                    </div>
                                    {/* {formatWinRate(summonerOverview?.solo)} */}
                                </div>
                            </div>
                            {/* Third Column */}
                            {summonerOverview?.flex &&
                                <div className='flex text-md mt-[51px]'>
                                    <img className={`${getFlexBadgeStyle(summonerOverview?.flex?.tier)}`} width="100" height="100" alt="ranked icons" src={process.env.PUBLIC_URL + `/assets/ranked_icons/Rank=${formatRank(summonerOverview?.flex)}.png`} />
                                    <div>
                                        <div className='text-sm font-bold border-b border-zinc-600 mb-1'>
                                            FLEX QUEUE RANK
                                        </div>
                                        <div className='flex gap-2'>
                                            <div>
                                                <div className='text-sm font-semibold whitespace-nowrap text-zinc-400'>{summonerOverview?.flex?.tier} {summonerOverview?.flex?.rank === 'UNRANKED' ? null : summonerOverview?.flex?.rank}</div>
                                                <div className='text-sm font-semibold text-zinc-400'>
                                                    {summonerOverview?.flex?.leaguePoints} LP
                                                </div>
                                                <div className="flex flex-wrap gap-x-1 text-zinc-400">
                                                    <div className="font-semibold text-sm">
                                                        W: {summonerOverview?.flex?.wins}
                                                    </div>
                                                    <div className="font-semibold text-sm">
                                                        L: {summonerOverview?.flex?.losses}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='font-semibold'>
                                                {formatWinRate(summonerOverview?.flex)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/* Hide Toggle when screen transitions to the mobile version of match history component */}
                <div className='bg-zinc-775 rounded-b-lg py-1 pl-4 pr-1 flex justify-between'>
                    <div className='flex gap-5'>
                        <div className='mt-1 italic text-zinc-400 text-sm'>Last Updated {formatLastUpdateTime(summonerData?.updated_at)}</div>
                        <div className='hidden lg:block'>
                            <button className={`mr-0.5 px-2 py-0.5 text-zinc-400 bg-zinc-775 border border-zinc-600 whitespace-nowrap font-bold text-xs rounded hover:bg-zinc-600`} onClick={cycleTheme}>Toggle Theme</button>
                        </div>
                    </div>
                    <div className='block md:hidden'>
                        <button className={`mr-3 px-2 py-0.5 text-zinc-400 bg-zinc-775 border border-zinc-600 whitespace-nowrap font-bold text-xs rounded hover:bg-zinc-600`} onClick={() => setDisplayedRank((prevValue) => (prevValue === "solo" ? "flex" : "solo"))}>Toggle Rank</button>
                    </div>
                </div>
                {/* Responsive Layout / variable size */}
                <div className='grid grid-cols-1 md:grid-cols-[320px_1fr] gap-x-3 w-full'>
                    <div>
                        <PreferredRole preferredRole={preferredRole} />
                        <PersonalChampionStats personalChampionStats={personalChampionStats} />
                        <ChampionMastery championMastery={championMastery} />
                    </div>
                    <div className='overflow-hidden'>
                        <div className='block lg:hidden'>
                            {/* SMALL SCREEN BUTTON LAYOUT*/}
                            {/* SMALL SCREEN BUTTON LAYOUT*/}
                            {/* SMALL SCREEN BUTTON LAYOUT*/}
                            {/* SMALL SCREEN BUTTON LAYOUT*/}
                            {/* UNDO ALL FILTERS - SHOW ON MOBILE LAYOUT / SMALL SCREENS ONLY*/}
                            <div className=''>
                                <button className='w-full mt-3 py-1 bg-zinc-775 rounded border border-zinc-600 font-semibold text-sm text-white hover:bg-zinc-600' onClick={() => undoAllFilters()}>UNDO FILTERS</button>
                            </div>
                            {/* QUEUE FILTERS - REFACTOR THIS CODE TO USE MAP so the scrollbar looks the same? or try styling it */}
                            <div className='text-white mt-3 my-1 flex space-x-2 overflow-x-auto pb-1'>
                                <button className={`px-2 py-1 ${queueFilter === 0 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => undoQueueFilterAndGetRecentMatchDetails(championFilter)}>ALL</button>
                                <button className={`px-2 py-1 ${queueFilter === 420 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(420, championFilter)}>RANKED SOLO</button>
                                <button className={`px-2 py-1 ${queueFilter === 400 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(400, championFilter)}>NORMAL</button>
                                <button className={`px-2 py-1 ${queueFilter === 450 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(450, championFilter)}>ARAM</button>
                                <button className={`px-2 py-1 ${queueFilter === 490 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(490, championFilter)}>QUICK PLAY</button>
                                <button className={`px-2 py-1 ${queueFilter === 440 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(440, championFilter)}>FLEX</button>
                                <button className={`px-2 py-1 ${queueFilter === 700 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(700, championFilter)}>CLASH</button>
                                <button className={`px-2 py-1 ${queueFilter === 1400 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(1400, championFilter)}>ULT SPELLBOOK</button>
                                <button className={`px-2 py-1 ${queueFilter === 1700 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(1700, championFilter)}>ARENA</button>
                                <button className={`px-2 py-1 ${queueFilter === 1300 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(1300, championFilter)}>NEXUS BLITZ</button>
                            </div>
                            {/* CHAMPION FILTERS */}
                            <div className='text-white mt-2 mb-2 flex space-x-2 overflow-x-auto pb-1 font-bold text-sm'>
                                <button className={`px-2 py-1 ${!championFilter && "bg-zinc-700"} border border-zinc-600 whitespace-nowrap rounded hover:bg-zinc-700`} onClick={() => undoChampionFilterAndGetRecentMatchDetails(queueFilter)}>ALL</button>
                                {orderedChampions?.map((champ) => {
                                    if (parseInt(champ.championId) === -1 || parseInt(champ.championId) === 0 || parseInt(champ.championId) === 14101 || parseInt(champ.championId) === 14102) { // 14101 Rhaast - 14102 - Shadow Assassin
                                        return null
                                    } else {
                                        return <button key={champ.championId} className={`px-2 py-1 ${championFilter === champ.championId && "bg-zinc-700"} border border-zinc-600 whitespace-nowrap rounded hover:bg-zinc-700`} onClick={() => updateChampionFilterAndGetRecentMatchDetails(queueFilter, champ.championId)}>{champ?.name}</button>
                                    }
                                })}
                            </div>
                        </div>
                        <div className='hidden lg:block'>
                            {/* LARGE SCREEN BUTTON LAYOUT*/}
                            {/* LARGE SCREEN BUTTON LAYOUT*/}
                            {/* LARGE SCREEN BUTTON LAYOUT*/}
                            {/* LARGE SCREEN BUTTON LAYOUT*/}
                            {/* QUEUE FILTERS - REFACTOR THIS CODE TO USE MAP so the scrollbar looks the same? or try styling it */}
                            <div className='text-white mt-3 my-1 flex space-x-2 overflow-x-auto pb-1'>
                                <button className='px-2 py-1 bg-zinc-900 rounded border border-zinc-600 font-semibold whitespace-nowrap text-sm text-white hover:bg-zinc-700' onClick={() => undoAllFilters()}>UNDO FILTERS</button>
                                <button className={`px-2 py-1 ${queueFilter === 0 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => undoQueueFilterAndGetRecentMatchDetails(championFilter)}>ALL</button>
                                <button className={`px-2 py-1 ${queueFilter === 420 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(420, championFilter)}>RANKED SOLO</button>
                                <button className={`px-2 py-1 ${queueFilter === 400 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(400, championFilter)}>NORMAL</button>
                                <button className={`px-2 py-1 ${queueFilter === 450 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(450, championFilter)}>ARAM</button>
                                <button className={`px-2 py-1 ${queueFilter === 490 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(490, championFilter)}>QUICK PLAY</button>
                                <button className={`px-2 py-1 ${queueFilter === 440 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(440, championFilter)}>FLEX</button>
                                <button className={`px-2 py-1 ${queueFilter === 700 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(700, championFilter)}>CLASH</button>
                                <button className={`px-2 py-1 ${queueFilter === 1400 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(1400, championFilter)}>ULT SPELLBOOK</button>
                                <button className={`px-2 py-1 ${queueFilter === 1700 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(1700, championFilter)}>ARENA</button>
                                <button className={`px-2 py-1 ${queueFilter === 1300 ? "bg-zinc-700" : "bg-zinc-900"} border border-zinc-600 whitespace-nowrap font-semibold text-sm rounded hover:bg-zinc-700`} onClick={() => updateQueueFilterAndGetRecentMatchDetails(1300, championFilter)}>NEXUS BLITZ</button>
                            </div>
                            {/* CHAMPION FILTERS */}
                            <div className='text-white mt-2 mb-2 flex space-x-2 overflow-x-auto pb-1 font-bold text-sm'>
                                <button className={`px-2 py-1 ${!championFilter && "bg-zinc-700"} border border-zinc-600 whitespace-nowrap rounded hover:bg-zinc-700`} onClick={() => undoChampionFilterAndGetRecentMatchDetails(queueFilter)}>ALL</button>
                                {orderedChampions?.map((champ) => {
                                    if (parseInt(champ.championId) === -1 || parseInt(champ.championId) === 0) {
                                        return null
                                    } else {
                                        return <button key={champ.championId} className={`px-2 py-1 ${championFilter === champ.championId && "bg-zinc-700"} border border-zinc-600 whitespace-nowrap rounded hover:bg-zinc-700`} onClick={() => updateChampionFilterAndGetRecentMatchDetails(queueFilter, champ.championId)}>{champ?.name}</button>
                                    }
                                })}
                            </div>
                        </div>
                        <div className='block lg:hidden'>
                            <MatchHistory orderedChampions={orderedChampions} matchHistory={matchHistory} setSummonerData={setSummonerData} summonerData={summonerData} />
                        </div>
                        <div className='hidden lg:block'>
                            <MatchHistoryDesktop orderedChampions={orderedChampions} colorToggle={colorToggle} matchHistory={matchHistory} setSummonerData={setSummonerData} summonerData={summonerData} />
                        </div>
                        {/* queue specific button - can hide */}
                        {(queueFilter && showFetchButtonForQueueType) ? (
                            <button className="w-full px-2 py-1 text-zinc-300 text-sm text-center font-bold bg-zinc-900 border border-zinc-600 rounded hover:bg-zinc-700" onClick={() => getSummonerData(matchHistory?.length + 3, false, queueFilter, championFilter)}>
                                SHOW MORE GAMES
                            </button>
                        ) : null}

                        {/* All queues/general button - can hide */}
                        {(!queueFilter && showFetchButtonForAllGames) ? (
                            <button className="w-full px-2 py-1 text-zinc-300 text-sm text-center font-bold bg-zinc-900 border border-zinc-600 rounded hover:bg-zinc-700" onClick={() => getSummonerData(matchHistory?.length + 3, false, false, championFilter)}>
                                SHOW MORE GAMES
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SummonerDetail


// {
//     queueFilter && showFetchButtonForQueueType &&
//     <button className="px-2 py-1 bg-slate-600 font-bold text-white text-sm rounded hover:bg-cyan-700 mt-5 w-full" onClick={() => getSummonerData(matchHistory?.length + 3, false, queueFilter)}>Fetch More Games (queue specific, remove this text)</button> :
//     {/* //<>queue button hiiding (delete this)</>*/ }
// }
// {
//     !queueFilter && showFetchButtonForAllGames ?
//     <button className="px-2 py-1 bg-slate-600 font-bold text-white text-sm rounded hover:bg-cyan-700 mt-5 w-full" onClick={() => getSummonerData(matchHistory?.length + 3, false)}>Fetch More Games (all, remove this text)</button> :
//     {/* //<>all button hiding (delete this)</>*/ }
// }



// {/* Right Column */ }
// <div>
//     <div>
//         <h1 className='font-bold text-xl whitespace-nowrap'>{summonerData?.gameName}</h1>
//     </div>
//     <div className='flex'>
//         <div className='font-bold text-zinc-400 w-[90px]'>#{summonerData?.tagLine}</div>
//     </div>
//     <div className='flex gap-x-2 items-center -mt-2'>
//         <img className="-ml-3" width="90" height="90" alt="ranked icons" src={process.env.PUBLIC_URL + `/assets/ranked_icons/Rank=${formatRank(summonerOverview)}.png`} />
//         <div className="-mt-4">
//             <div className='text-sm font-bold whitespace-nowrap'>{summonerOverview?.tier} {summonerOverview?.rank === 'UNRANKED' ? null : summonerOverview?.rank}</div>
//             <div className='text-sm font-bold'>{summonerOverview?.leaguePoints} LP</div>
//             <div><span className='font-bold'>W:</span> {summonerOverview?.wins} <span className='font-bold'>L</span>:{summonerOverview?.losses}</div>
//             {/* <div><span className='font-bold'>Win Rate:</span> {Math.round(summonerOverview?.wins/(summonerOverview?.wins + summonerOverview?.losses)*100)}%</div> */}
//             {formatWinRate(summonerOverview)}
//         </div>
//     </div>
// </div>
//                 </div >
//             </div >