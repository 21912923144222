import { React, useState } from 'react'
import PersonalChampionsIconWithFallBack from '../utilities/PersonalChampionIcons'
import { PiInfinityBold } from "react-icons/pi";

const PersonalChampionStats = ({ personalChampionStats }) => {

    const [personalChampionFilter, setPersonalChampionFilter] = useState("")

    function handleClick(gameMode) {
        setPersonalChampionFilter(gameMode)
    }

    function consolidateStatsByChampionAndSeason(personalChampionStats) {
        return personalChampionStats?.reduce((accumulator, current) => {
            const existingIndex = accumulator.findIndex(champStat => champStat.championId === current.championId && champStat.season_id === current.season_id);

            if (existingIndex !== -1) {
                accumulator[existingIndex].games += current.games;
                accumulator[existingIndex].wins += current.wins;
                accumulator[existingIndex].losses += current.losses;
                accumulator[existingIndex].kills += current.kills;
                accumulator[existingIndex].deaths += current.deaths;
                accumulator[existingIndex].assists += current.assists;
                accumulator[existingIndex].cs += current.cs;
                accumulator[existingIndex].csm += current.csm;
            } else {
                accumulator.push({ ...current });
            }

            return accumulator
        }, [])
    }


    return (
        <div className="bg-zinc-775 text-zinc-400 text-center rounded-lg px-4 pt-1 pb-4 mb-3 w-full">
            {/* <div className='text-white mt-3 my-3 flex space-x-2 justify-around w-[85%] mx-auto'> */}
            <h2 className="mb-3 italic text-xs mt-2 font-bold">SUMMONER'S RIFT PERFORMANCE</h2>
            <div className='text-white mt-3 my-3 flex space-x-2 overflow-x-auto'>
                {/* <button className={`px-2 py-1 ${personalChampionFilter === "" ? "bg-cyan-700" : "bg-zinc-900"} whitespace-nowrap font-bold text-xs rounded hover:bg-cyan-700`} onClick={() => handleClick("")}>All</button>
                <button className={`px-2 py-1 ${personalChampionFilter === 420 ? "bg-cyan-700" : "bg-zinc-900"} whitespace-nowrap font-bold text-xs rounded hover:bg-cyan-700`} onClick={() => handleClick(420)}>RANKED SOLO</button>
                <button className={`px-2 py-1 ${personalChampionFilter === 440 ? "bg-cyan-700" : "bg-zinc-900"} whitespace-nowrap font-bold text-xs rounded hover:bg-cyan-700`} onClick={() => handleClick(440)}>FLEX</button>
                <button className={`px-2 py-1 ${personalChampionFilter === 400 ? "bg-cyan-700" : "bg-zinc-900"} whitespace-nowrap font-bold text-xs rounded hover:bg-cyan-700`} onClick={() => handleClick(400)}>NORMAL</button> */}
                <button className={`px-2 py-1 ${personalChampionFilter === "" ? "bg-zinc-600 border border-zinc-600" : "bg-zinc-900 border border-zinc-600"} whitespace-nowrap font-bold text-xs rounded hover:bg-zinc-600`} onClick={() => handleClick("")}>All</button>
                <button className={`px-2 py-1 ${personalChampionFilter === 420 ? "bg-zinc-600 border border-zinc-600" : "bg-zinc-900 border border-zinc-600"} whitespace-nowrap font-bold text-xs rounded hover:bg-zinc-600`} onClick={() => handleClick(420)}>RANKED SOLO</button>
                <button className={`px-2 py-1 ${personalChampionFilter === 440 ? "bg-zinc-600 border border-zinc-600" : "bg-zinc-900 border border-zinc-600"} whitespace-nowrap font-bold text-xs rounded hover:bg-zinc-600`} onClick={() => handleClick(440)}>FLEX</button>
                <button className={`px-2 py-1 ${personalChampionFilter === 400 ? "bg-zinc-600 border border-zinc-600" : "bg-zinc-900 border border-zinc-600"} whitespace-nowrap font-bold text-xs rounded hover:bg-zinc-600`} onClick={() => handleClick(400)}>NORMAL</button>
                <button className={`px-2 py-1 ${personalChampionFilter === 490 ? "bg-zinc-600 border border-zinc-600" : "bg-zinc-900 border border-zinc-600"} whitespace-nowrap font-bold text-xs rounded hover:bg-zinc-600`} onClick={() => handleClick(490)}>QUICK PLAY</button>
            </div>
            <div className='flex flex-col'>
                {personalChampionStats?.length === 0 && <div className='text-sm font-normal'>No Informaiton. Try Updating.</div>}
                {(personalChampionFilter === ""
                    ? consolidateStatsByChampionAndSeason(personalChampionStats)
                    : personalChampionStats.filter((individualStat) => individualStat.queueId == personalChampionFilter))
                    ?.map((championStat, index) => {
                        let kda = ((championStat?.kills + championStat?.assists) / championStat?.deaths)
                        let infinity = (kda > 0 && championStat?.deaths === 0 ? <PiInfinityBold size={20} strokeWidth={6} /> : null)

                        return (
                            <div key={index} className='flex text-white h-10 text-left justify-between mb-2 gap-1'>
                                <div className='mr-2'>
                                    <div className='relative overflow-hidden h-10 w-10 rounded-full border border-zinc-600'>
                                        <PersonalChampionsIconWithFallBack src={`https://d6n8t0712uu0b.cloudfront.net/champion_icons/${championStat?.nameId}.png`} alt="most played champion" />
                                    </div>
                                </div>
                                {/* <div className='w-12 flex-1 whitespace-nowrap'> */}
                                <div className='w-[76px] -mt-0'>
                                    <div className='text-xs truncate w-[68px]'>
                                        <div className='border-b font-semibold border-zinc-600 w-fit'>{championStat?.name}</div>
                                    </div>
                                    {infinity ?
                                        <div className="flex gap-1 ext-sm font-bold">
                                            <div className="text-cyan-600">{infinity}</div>
                                            <div className='mt-1 text-[9px] font-bold'>KDA</div>
                                        </div>
                                        :
                                        <p className={`text-sm font-bold`}><span className={`${kda > 5.0 ? "text-cyan-600" : null}`}>{kda.toFixed(1)}</span> <span className='text-[9px] font-bold'>KDA</span></p>
                                    }
                                </div>
                                {/* <div className='text-sm flex-[2] font-normal ml-6 whitespace-nowrap'> */}
                                <div className='text-xs w-[100px] whitespace-nowrap text-x'>
                                    <p>{Math.round(championStat?.cs / championStat?.games)} CS ({(championStat?.csm / championStat?.games).toFixed(1)})</p>
                                    <p>{(championStat?.kills / championStat?.games).toFixed(1)} / {(championStat?.deaths / championStat?.games).toFixed(1)} / {(championStat?.assists / championStat?.games).toFixed(1)}</p>
                                </div>
                                <div className='text-xs w-[70px] text-right whitespace-nowrap'>
                                    <p>{championStat?.games > 0 ? ((championStat?.wins / championStat?.games) * 100).toFixed(1) : 0}% WR</p>
                                    <p>{championStat?.games} game(s)</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PersonalChampionStats