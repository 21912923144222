import React from 'react'

const PersonalChampionsIconWithFallBack = ({src, alt}) => {

    function handleError(e) {
        // e.target.src = process.env.PUBLIC_URL + `/assets/champion/missing_champion.png`
        e.target.src = process.env.PUBLIC_URL + `/assets/missing_asset/missing_icon.png`
    }

    return (
        <img
            // className='rounded-full h-full w-full object-cover border border-zinc-600'
            className='rounded-full h-full w-full transform scale-[1.2] object-cover'
            src={src}
            alt={alt}
            onError={(e) => handleError(e)}
        />
    )
}


export default PersonalChampionsIconWithFallBack