import React from 'react'
import { VscError } from "react-icons/vsc";

const LadderError = ({error, timeout}) => {


    const errorCode = String(error?.status_code);
    let errorDetails = null;

    if (errorCode === "404") {
        errorDetails = (
            <div>Could not find high elo leaderboards in Riot servers.</div>
            //{/* <div>{error?.status?.message}</div> */}
        )
    } else if (errorCode[0] === "5") {
        console.log(error)
        errorDetails = (
            <div>There was an issue retriving for the leaderboards. Please try again later.</div>
        )
    } else if (errorCode === "408") {
        errorDetails = (
            <div>Your request timed out (took unexpectedly long). Please try again momentarily.</div>
        )
    } else if (errorCode === "429") { // 429 Rate limited received
        console.log(error)
        errorDetails = (
            <div>Too many are being equests sent to Riot Servers currently. Retry in 99s...</div>
        )
    } else {
        errorDetails = (
            <div>Unexpected error. Please try again later.</div>
        )
    }

    return (
        <div className='w-[80%] mx-auto text-white mb-[80%]'>
            <div className='flex justify-center items-center'>
                <VscError className='text-rose-800 h-20 w-20 mt-[20%]' />
            </div>
            <div className='font-bold text-4xl text-rose-800 flex justify-center items-center mt-[5%]'>
                Error: {errorCode}
            </div>
            <div className='font-semibold flex justify-center items-center mt-[10%]'>
                {errorDetails}
            </div>
        </div>
        
    )
}


export default LadderError